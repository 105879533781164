export const promiseDebounce = (() => {
  const pending: Record<string, number> = {};

  return <R>(name: string, fn: (...args: any) => Promise<R>, delay: number) =>
    new Promise<R>((resolve) => {
      if (pending[name]) {
        clearTimeout(pending[name]);
      }

      pending[name] = window.setTimeout(() => {
        fn().then(resolve);
        delete pending[name];
      }, delay);
    });
})();

export const nullish = () => null;
