import { PageMemberPreview } from '@mero/api-sdk/dist/pages';
import { UserId } from '@mero/api-sdk/dist/users';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { Worker } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  Icon,
  Line,
  Row,
  SmallBody,
  Spacer,
  Title,
} from '@mero/components';
import { flow } from 'fp-ts/function';
import { indexOf, sortBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';

import StaticFlatList from '../../../../../components/StaticFlatList';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, ProsDashboardStackParamList } from '../../../../../types';
import { nameGenerator } from '../../../../../utils/string';
import AddProMenu from './AddProMenu';
import { styles } from './PageProsSettingsScreen.styles';

const TeamMobile: React.FC = () => {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList & ProsDashboardStackParamList>>();

  const isFocused = useIsFocused();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [showAddProMenu, setShowAddProMenu] = React.useState(false);

  const goToSortingCallback = React.useCallback(() => {
    navigation.navigate('ProsEdit', { screen: 'SortPros' });
  }, []);

  const addProsMenuCallback = React.useCallback(() => {
    setShowAddProMenu(!showAddProMenu);
  }, [showAddProMenu]);

  const addProsCallback = React.useCallback(
    flow(
      () => {
        navigation.navigate('ProsEdit', { screen: 'ProAddNewStack' });
      },
      () => setShowAddProMenu(false),
    ),
    [],
  );

  const inviteProsCallback = React.useCallback(
    flow(
      () => {
        navigation.navigate('ProsEdit', { screen: 'ProInviteStack' });
      },
      () => setShowAddProMenu(false),
    ),
    [],
  );

  const isMobileWeb = isPhone && Platform.OS === 'web';

  type Members = {
    workers: {
      worker: SavedWorker;
      member: PageMemberPreview;
    }[];
    administrative: PageMemberPreview[];
  };
  const members: Members = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      const workersByUserId: Record<UserId, SavedWorker> = pageState.page.workers.reduce(
        (acc: Record<UserId, SavedWorker>, worker) => ({ ...acc, [worker.user._id]: worker }),
        {},
      );

      const workersIds = pageState.page.workers.map((worker) => worker._id);

      const workers = pageState.page.members
        .filter((member) => workersByUserId[member.user._id])
        .map((member) => ({
          worker: workersByUserId[member.user._id],
          member,
        }))
        .filter((member) => member.worker);

      const administrative = pageState.page.members.filter((member) => !workersByUserId[member.user._id]);

      return { workers: sortBy(workers, (item) => indexOf(workersIds, item.worker._id)), administrative };
    }

    return { workers: [], administrative: [] };
  }, [pageState]);

  React.useEffect(() => {
    if (isFocused && pageState.type === 'Loaded') {
      const updatePageState = async () => {
        await reloadAsync();
      };

      updatePageState();
    }
  }, [isFocused]);

  return (
    <>
      <ScrollView style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }} showsVerticalScrollIndicator={false}>
        <H1 style={{ paddingHorizontal: 8 }}>{t('pros')}</H1>
        <Row style={{ paddingTop: 16, alignItems: 'center', justifyContent: 'space-between' }}>
          {/*<TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>*/}
          {/*  <Title style={{ color: colors.DARK_BLUE, fontSize: 17 }}>{t('allLocations')}</Title>*/}
          {/*  <Column style={{ paddingLeft: 12 }}>*/}
          {/*    <Icon type="dropdown" color={colors.DARK_BLUE} />*/}
          {/*  </Column>*/}
          {/*</TouchableOpacity>*/}
          <Column style={{ flex: 1 }} />
          <Column>
            <TouchableOpacity style={styles.addProsButton} onPress={addProsMenuCallback}>
              <Title style={{ color: colors.DARK_BLUE, paddingLeft: 24, paddingRight: 16 }}>{t('add')}</Title>
              <Column style={{ paddingRight: 12 }}>
                <Icon type="dropdown" color={colors.DARK_BLUE} />
              </Column>
            </TouchableOpacity>
            <Column style={{ position: 'relative' }}>
              {showAddProMenu && isMobileWeb && (
                <Column style={styles.webAddProsActions}>
                  <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={addProsCallback}>
                    <Title>{t('addPro')}</Title>
                  </TouchableOpacity>
                  <Line />
                  <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={inviteProsCallback}>
                    <Title>{t('invitePro')}</Title>
                    <Spacer size={2} />
                    <SmallBody>{t('inviteProSubtitle')}</SmallBody>
                  </TouchableOpacity>
                </Column>
              )}
            </Column>
          </Column>
        </Row>
        <Spacer size={24} />
        <FormCard rounded paddings="none" style={{ zIndex: -1 }} dropShaddow>
          <Spacer size={16} />
          <Row style={{ alignItems: 'center', paddingHorizontal: 16 }}>
            <H3s style={{ flex: 1 }}>{t('acceptsAppointments')}</H3s>
            {members.workers.length > 1 && (
              <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  size="small"
                  onClick={goToSortingCallback}
                  expand={false}
                  text={t('sort')}
                  backgroundColor={colors.WHITE}
                  color={colors.DARK_BLUE}
                />
              </Column>
            )}
          </Row>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <StaticFlatList
            data={members.workers}
            renderItem={({ item }) => {
              const role = item.member.roles[0].name;

              const isInvited =
                pageState.type === 'Loaded' && item.worker
                  ? Worker.isInvited(item.worker, pageState.page.details)
                  : false;

              return (
                <TouchableOpacity
                  style={{ flexDirection: 'row', paddingHorizontal: 16 }}
                  onPress={() =>
                    navigation.navigate('ProsEdit', {
                      screen: 'ProDetails',
                      params: { userId: item.member.user._id },
                    })
                  }
                >
                  <Avatar
                    firstname={item.member.user.profile.firstname ?? ''}
                    lastname={item.member.user.profile.lastname ?? ''}
                    source={item.member.user.profile.photo?.thumbnail}
                    size={48}
                  />
                  <Column style={{ paddingLeft: 12, flex: 1 }}>
                    <Title style={{ color: '#06115E' }}>{nameGenerator(item.member.user.profile, t('noName'))}</Title>
                    <Spacer size={2} />
                    <SmallBody style={{ color: '#06115E' }}>
                      {role} {isInvited ? `(${t('invited')})` : ''}
                    </SmallBody>
                    <Spacer size={4} />
                    <SmallBody style={{ color: colors.COMET }}>
                      {!item.member.user.phone || item.member.user.phone.includes('null')
                        ? t('noPhone')
                        : item.member.user.phone}
                    </SmallBody>
                  </Column>
                  <Column>
                    <Icon type="arrow-right" />
                  </Column>
                </TouchableOpacity>
              );
            }}
            keyExtractor={(item) => item.member.user._id}
            ItemSeparatorComponent={() => (
              <Column style={{ paddingHorizontal: 16 }}>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
              </Column>
            )}
          />
          <Spacer size={16} />
        </FormCard>
        <Spacer size={24} />
        {members.administrative.length > 0 && (
          <FormCard rounded paddings="none" style={{ zIndex: -1, marginBottom: 16 }} dropShaddow>
            <Spacer size={16} />
            <H3s style={{ paddingHorizontal: 16 }}>{t('doesntAcceptAppointments')}</H3s>
            <Spacer size={16} />
            <Line />
            <Spacer size={16} />
            <StaticFlatList
              data={members.administrative}
              renderItem={({ item }) => {
                const role = item.roles[0].name;

                return (
                  <TouchableOpacity
                    style={{ flexDirection: 'row', paddingHorizontal: 16 }}
                    onPress={() =>
                      navigation.navigate('ProsEdit', {
                        screen: 'ProDetails',
                        params: { userId: item.user._id },
                      })
                    }
                  >
                    <Avatar
                      firstname={item.user.profile.firstname ?? ''}
                      lastname={item.user.profile.lastname ?? ''}
                      source={item.user.profile.photo?.thumbnail}
                      size={48}
                    />
                    <Column style={{ paddingLeft: 12, flex: 1 }}>
                      <Title style={{ color: '#06115E' }}>
                        {item.user.profile.firstname} {item.user.profile.lastname}
                      </Title>
                      <Spacer size={2} />
                      <SmallBody style={{ color: '#06115E' }}>{role}</SmallBody>
                      <Spacer size={4} />
                      <SmallBody style={{ color: colors.COMET }}>
                        {item.user.phone?.includes('null') ? t('noPhone') : item.user.phone}
                      </SmallBody>
                    </Column>
                    <Column>
                      <Icon type="arrow-right" />
                    </Column>
                  </TouchableOpacity>
                );
              }}
              keyExtractor={(item) => item.user._id}
              ItemSeparatorComponent={() => (
                <Column style={{ paddingHorizontal: 16 }}>
                  <Spacer size={16} />
                  <Line />
                  <Spacer size={16} />
                </Column>
              )}
            />
            <Spacer size={16} />
          </FormCard>
        )}
        <Spacer size={48} />
      </ScrollView>
      {showAddProMenu && !isMobileWeb && (
        <AddProMenu
          onDismiss={() => setShowAddProMenu(false)}
          onAddPro={addProsCallback}
          onInvitePro={inviteProsCallback}
        />
      )}
    </>
  );
};

export default TeamMobile;
