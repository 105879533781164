import { BusinessHours, DateString, EditWorkingSchedule, Hour, Minute, WeekDaysSchedule } from '@mero/api-sdk';
import { WorkInterval } from '@mero/api-sdk/src/business/workingHours';
import { WorkingScheduleDay } from '@mero/api-sdk/src/pro/workingSchedule/workingScheduleDay';
import { TFunction } from 'i18next';
import { DateTime, Info } from 'luxon';

import { DEFAULT_TIMEZONE } from './time';

const fromWorkIntervalToWorkingScheduleDay = (workInterval: WorkInterval): WorkingScheduleDay => {
  return workInterval.active
    ? workInterval.wholeDay
      ? {
          active: true,
          intervals: [
            {
              from: {
                hour: 0 as Hour,
                minute: 0 as Minute,
              },
              to: {
                hour: 23 as Hour,
                minute: 59 as Minute,
              },
            },
          ],
        }
      : {
          active: true,
          intervals: [
            {
              from: workInterval.from,
              to: workInterval.to,
            },
          ],
        }
    : {
        active: false,
      };
};

export const convertBusinessHoursToSchedule = (businessHours: BusinessHours): EditWorkingSchedule.Weekly => {
  return {
    type: 'Weekly',
    start: DateString.fromDate(new Date(), DEFAULT_TIMEZONE),
    end: undefined,
    weekSchedules: [
      {
        monday: fromWorkIntervalToWorkingScheduleDay(businessHours[0]),
        tuesday: fromWorkIntervalToWorkingScheduleDay(businessHours[1]),
        wednesday: fromWorkIntervalToWorkingScheduleDay(businessHours[2]),
        thursday: fromWorkIntervalToWorkingScheduleDay(businessHours[3]),
        friday: fromWorkIntervalToWorkingScheduleDay(businessHours[4]),
        saturday: fromWorkIntervalToWorkingScheduleDay(businessHours[5]),
        sunday: fromWorkIntervalToWorkingScheduleDay(businessHours[6]),
      },
    ],
  };
};

export const EMPTY_WEEK_SCHEDULE: WeekDaysSchedule = {
  monday: { active: false },
  tuesday: { active: false },
  wednesday: { active: false },
  thursday: { active: false },
  friday: { active: false },
  saturday: { active: false },
  sunday: { active: false },
};

export const formatSchedule = (payload: {
  start: DateTime;
  end: DateTime | undefined;
  schedule: EditWorkingSchedule.Any;
  index: number;
  repeat: 'none' | 'weekly' | '2weeks';
  startDateSetByUser: boolean;
}): EditWorkingSchedule.Any => {
  const { start, end, schedule, index, repeat, startDateSetByUser } = payload;
  const date = start.toFormat('yyyy-MM-dd') as DateString;
  const endDate = end ? (end?.toFormat('yyyy-MM-dd') as DateString) : undefined;

  if (schedule.type === 'SpecificDate' && repeat === 'none') {
    return {
      ...schedule,
      date,
    };
  }

  if (schedule.type === 'Weekly' && repeat === 'weekly') {
    return {
      ...schedule,
      start: date,
      end: endDate,
      weekSchedules: [schedule.weekSchedules[index]],
    };
  }

  if (schedule.type === 'Weekly' && repeat === '2weeks') {
    if (schedule.weekSchedules.length > 1) {
      if (startDateSetByUser) {
        const remainingSchedules = schedule.weekSchedules.filter((_, idx) => idx !== index);

        return {
          ...schedule,
          start: date,
          weekSchedules: [schedule.weekSchedules[index], ...remainingSchedules],
        };
      } else {
        const startDay = start.startOf('week').minus({ weeks: index }).toFormat('yyyy-MM-dd') as DateString;

        return {
          ...schedule,
          start: startDay,
          end: endDate,
          weekSchedules: schedule.weekSchedules,
        };
      }
    }

    return {
      ...schedule,
      start: date,
      end: endDate,
      weekSchedules: [schedule.weekSchedules[index], EMPTY_WEEK_SCHEDULE],
    };
  }

  return schedule;
};

export const getActiveDays = (daySchedule: WeekDaysSchedule) => {
  const days = [];

  if (daySchedule.monday.active) {
    days.push('Monday');
  }

  if (daySchedule.tuesday.active) {
    days.push('Tuesday');
  }

  if (daySchedule.wednesday.active) {
    days.push('Wednesday');
  }

  if (daySchedule.thursday.active) {
    days.push('Thursday');
  }

  if (daySchedule.friday.active) {
    days.push('Friday');
  }

  if (daySchedule.saturday.active) {
    days.push('Saturday');
  }

  if (daySchedule.sunday.active) {
    days.push('Sunday');
  }

  return days;
};

export const generateDeleteRepeatText = (
  schedule: EditWorkingSchedule.Any,
  index: number,
  t: TFunction<'pros', undefined>,
): string => {
  if (schedule.type === 'SpecificDate') {
    return t('confirmDeleteScheduleDay', {
      date: DateString.toDateTime(schedule.date, DEFAULT_TIMEZONE).toFormat('dd.MM.yyyy'),
    });
  }

  const activeSchedule = schedule.weekSchedules[index];
  const days = getActiveDays(activeSchedule)
    .map((v) => t(v))
    .join(', ');

  if (schedule.type === 'Weekly' && schedule.weekSchedules.length > 1) {
    if (schedule.end) {
      return t('confirmDeleteSchedule2WeekWithEnd', {
        days,
        endDate: DateString.toDateTime(schedule.end, DEFAULT_TIMEZONE).toFormat('dd.MM.yyyy'),
      });
    }

    return t('confirmDeleteSchedule2Week', { days });
  }

  if (schedule.end) {
    return t('confirmDeleteScheduleWeekWithEnd', {
      days,
      endDate: DateString.toDateTime(schedule.end, DEFAULT_TIMEZONE).toFormat('dd.MM.yyyy'),
    });
  }

  return t('confirmDeleteScheduleWeek', { days });
};

export const generateRepeatText = (
  schedule: EditWorkingSchedule.Any,
  index: number,
  t: TFunction<'pros', undefined>,
): string => {
  if (schedule.type === 'SpecificDate') {
    return t('scheduleDay', {
      date: DateString.toDateTime(schedule.date, DEFAULT_TIMEZONE).toFormat('dd.MM.yyyy'),
    });
  }

  const activeSchedule = schedule.weekSchedules[index];
  const days = getActiveDays(activeSchedule)
    .map((v) => t(v))
    .join(', ');

  if (schedule.type === 'Weekly' && schedule.weekSchedules.length > 1) {
    if (schedule.end) {
      return t('schedule2WeekWithEnd', {
        days,
        endDate: DateString.toDateTime(schedule.end, DEFAULT_TIMEZONE).toFormat('dd.MM.yyyy'),
      });
    }

    return t('schedule2Week', { days });
  }

  if (schedule.end) {
    return t('scheduleWeekWithEnd', {
      days,
      endDate: DateString.toDateTime(schedule.end, DEFAULT_TIMEZONE).toFormat('dd.MM.yyyy'),
    });
  }

  return t('scheduleWeek', { days });
};

export type WeekDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const getWeekDays = () => {
  const defaultWeekStart = Info.weekdays('long', { locale: DateTime.now().locale })[0];

  const usWeek = Info.weekdays('long', { locale: 'en-US' });
  const gbWeek = Info.weekdays('long', { locale: 'en-GB' });

  if (usWeek[0] === defaultWeekStart) {
    return usWeek;
  }

  if (gbWeek[0] === defaultWeekStart) {
    return gbWeek;
  }

  return usWeek;
};

export const convertNumberToDay = (day: number) => {
  const weekDays = getWeekDays();

  return (weekDays[day].toLowerCase() as WeekDay) ?? 'monday';
};

export const getDayName = (day: DateTime): WeekDay => {
  return day.toFormat('cccc', { locale: 'en-US' }).toLowerCase() as WeekDay;
};
