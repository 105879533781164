import { Icon, colors } from '@mero/components';
import * as common from '@mero/shared-sdk';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { LocaleConfig } from 'react-native-calendars';

import { LocalDateObject } from '../../contexts/CalendarContext';
import log from '../../utils/log';

LocaleConfig.locales['ro'] = {
  monthNames: [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie',
  ],
  monthNamesShort: ['Ian.', 'Feb.', 'Mar.', 'Apr.', 'Mai', 'Iun.', 'Iul.', 'Aug.', 'Sep.', 'Oct.', 'Noi.', 'Dec.'],
  dayNames: ['Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă'],
  dayNamesShort: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
};
LocaleConfig.defaultLocale = 'ro';

const Arrow = (direction: 'left' | 'right') =>
  direction === 'left' ? (
    <View style={{ marginLeft: -8 }}>
      <Icon type="arrow-left" size={32} />
    </View>
  ) : (
    <View style={{ marginRight: -8 }}>
      <Icon type="arrow-right" size={32} />
    </View>
  );

export type Props = {
  readonly style?: StyleProp<ViewStyle>;
  /**
   * Selected date
   * TODO: refactor to use date objects ({ year, month, day })
   */
  readonly selected?: common.DateString;

  readonly appointedDays?: common.DateString[];

  readonly minDate?: LocalDateObject;

  readonly maxDate?: LocalDateObject;
  /**
   * Called when new date is selected
   */
  readonly onDateSelected?: (selected: common.DateString) => void;
};

const Theme = {
  arrowColor: colors.DARK_BLUE,
  todayTextColor: colors.DARK_BLUE,
  // Week days header
  textDayHeaderFontSize: 12,
  textDayHeaderFontFamily: 'open-sans-bold',
  textSectionTitleColor: '#757575',
  // Month title section
  textMonthFontSize: 19,
  textMonthFontFamily: 'open-sans',
  monthTextColor: colors.BLACK,
  // calendar days
  dayTextColor: '#393939',
  textDisabledColor: '#C3C3C3',
  selectedDayTextColor: colors.WHITE,
  selectedDayBackgroundColor: colors.DARK_BLUE,
};

const MeroCalendar: React.FC<Props> = ({
  style,
  selected,
  appointedDays = [],
  minDate,
  maxDate,
  onDateSelected,
}: Props) => {
  const markedDates = React.useMemo(
    () =>
      selected !== undefined
        ? {
            ...appointedDays?.reduce(
              (acc, date) => ({ ...acc, [date]: { marked: true, dotColor: '#52577F', activeOpacity: 0 } }),
              {},
            ),
            [selected]: { selected: true },
          }
        : {},
    [selected, appointedDays],
  );

  const dayPressCallback = React.useCallback(
    ({ dateString }: { dateString: string }) => {
      if (onDateSelected) {
        pipe(
          dateString,
          common.DateString.JSON.decode,
          E.fold(() => {
            log.error(`Failed to decode DateString from`, dateString);
          }, onDateSelected),
        );
      }
    },
    [selected, onDateSelected],
  );

  return (
    <Calendar
      style={style}
      // Initially visible month. Default = Date()
      minDate={minDate ? LocalDateObject.format(minDate) : undefined}
      maxDate={maxDate ? LocalDateObject.format(maxDate) : undefined}
      current={selected}
      key={selected}
      theme={Theme}
      onDayPress={dayPressCallback}
      markedDates={markedDates}
      monthFormat={'MMMM yyyy'}
      firstDay={1}
      // Enable the option to swipe between months. Default = false
      enableSwipeMonths={true}
      renderArrow={Arrow}
    />
  );
};

export default MeroCalendar;
