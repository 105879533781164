import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  listContentContainer: { marginVertical: 16 },
  card: {
    backgroundColor: colors.WHITE,
    paddingVertical: 24,
    paddingHorizontal: 16,
    borderRadius: 6,
    borderWidth: 1,
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
  cardHeader: { paddingTop: 8 },
  cardTitle: { paddingLeft: 12, flex: 1 },
  inactivePermission: {
    color: '#A8AABE',
    textDecorationLine: 'line-through',
  },
  permissionRow: {
    flex: 1,
    gap: sizes[8],
  },
  permission: {
    flex: 1,
  },
  roleWrapper: {
    marginTop: 15,
  },
  permissionTitle: {
    marginBottom: sizes[6],
  },
  permissionsWrapper: {
    gap: sizes[8],
  },
  line: { paddingVertical: sizes[16] },
});

export default styles;
