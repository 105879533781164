import { DateString, dateStringUtils, PageId, WorkerSchedule } from '@mero/api-sdk';
import { WorkerScheduleId } from '@mero/api-sdk/dist/pro/workingSchedule/workingScheduleId';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';

import log, { logCatch } from '../../utils/log';
import { EMPTY_WEEK_SCHEDULE } from '../../utils/schedule';
import { DEFAULT_TIMEZONE } from '../../utils/time';
import { meroApi } from '../AuthContext';

type StateGeneral = {
  selectedExtraPros: WorkerId[];
};

type StateNew = {
  type: 'New';
};

type StateInitializing = {
  type: 'Initializing';
};

type Schedule =
  | (Omit<WorkerSchedule.Weekly, '_id'> & { _id?: WorkerScheduleId })
  | (Omit<WorkerSchedule.SpecificDate, '_id'> & { _id?: WorkerScheduleId });

export type StateLoaded = {
  type: 'Loaded';
  schedule: Schedule;
  weekIndex: number;
  scheduleType: 'new' | 'edit';
};

export type StateFailed = {
  type: 'Failed';
  error: unknown;
};

type State = (StateNew | StateInitializing | StateLoaded | StateFailed) & StateGeneral;

const defaultState = (): State => ({
  type: 'New',
  selectedExtraPros: [],
});

export const ScheduleContext = createModelContext(
  defaultState(),
  {
    trySetInitializing(s) {
      if (s.type === 'New') {
        return {
          type: 'Initializing',
          selectedExtraPros: s.selectedExtraPros,
        };
      }

      return s;
    },
    setLoaded(
      _,
      params: {
        schedule: Schedule;
        weekIndex: number;
        scheduleType: 'new' | 'edit';
      },
    ) {
      return {
        type: 'Loaded',
        schedule: params.schedule,
        weekIndex: params.weekIndex,
        scheduleType: params.scheduleType,
        selectedExtraPros: [],
      };
    },
    updateSchedule(state, params: { schedule: Schedule }) {
      return {
        ...state,
        schedule: params.schedule,
      };
    },
    setFailed(state, params: { error: unknown }) {
      return {
        ...state,
        type: 'Failed',
        error: params.error,
      };
    },
    addExtraPro(s, payload: { selectedExtraPros: WorkerId[] }) {
      return {
        ...s,
        selectedExtraPros: payload.selectedExtraPros,
      };
    },
    reset: defaultState,
    run: (s, f: (s: State) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    return {
      init: (payload: { pageId: PageId; workerId: WorkerId; date: DateString }): void => {
        dispatch.run(async (s) => {
          if (s.type === 'New') {
            dispatch.trySetInitializing();
            try {
              const schedule = await meroApi.pro.workingSchedule
                .findWorkingScheduleByDate(payload)
                .catch(logCatch('findWorkingScheduleByDate'));
              if (!schedule) {
                dispatch.setLoaded({
                  schedule: {
                    type: 'Weekly',
                    workerId: payload.workerId,
                    start: DateString.fromDate(DateTime.now().toJSDate(), DEFAULT_TIMEZONE),
                    end: undefined,
                    weekSchedules: [EMPTY_WEEK_SCHEDULE],
                  },
                  weekIndex: 0,
                  scheduleType: 'new',
                });

                return;
              }
              const weekIndex =
                schedule.type === 'Weekly'
                  ? WorkerSchedule.Weekly.getWeekScheduleIndexForDate({
                      date: payload.date,
                      schedule,
                    })
                  : 0;
              dispatch.setLoaded({
                schedule,
                weekIndex,
                scheduleType: 'edit',
              });
            } catch (error) {
              dispatch.setFailed({ error });
              log.error('failed to initialize checkout information', error);
            }
          }
        });
      },
      initFromSchedule: (schedule: Schedule, weekIndex: number, scheduleType: 'new' | 'edit') => {
        dispatch.setLoaded({
          schedule,
          weekIndex,
          scheduleType,
        });
      },
      addExtraPro: (selectedExtraPros: WorkerId[]) => dispatch.addExtraPro({ selectedExtraPros }),
      updateSchedule: (schedule: Schedule) => dispatch.updateSchedule({ schedule }),

      reset: dispatch.reset,
    };
  },
);

export const withScheduleContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithCheckoutsContextProvider(props: P) {
    return (
      <ScheduleContext.Provider>
        <Content {...props} />
      </ScheduleContext.Provider>
    );
  };
};
