import { DateString, DayTime, WorkScheduleInterval } from '@mero/api-sdk';
import { DateTime } from 'luxon';

import { WorkerSchedule } from '../components/Calendar/BigCalendar/types';

import { LocalDateObject } from '../contexts/CalendarContext';

export const DEFAULT_TIMEZONE = 'Europe/Bucharest';

export const preZero = (num: number) => (num < 10 ? `0${num}` : num);

type Minute = {
  key: string;
  value: number;
  label: string;
  type: 'string';
};

export const DURATION_MIN = 1;

const insertCustomMinute = (list: Minute[], customMinute: number) => {
  const position = list.findIndex((m) => customMinute < m.value);
  const newItem = {
    key: `minute-${position}`,
    value: customMinute,
    label: `${preZero(customMinute)}m`,
    type: 'string' as const,
  };
  return position === -1 ? [...list, newItem] : [...list.slice(0, position), newItem, ...list.slice(position)];
};

export const getMinutes = (step = 5, customMinutes?: number[]) => {
  const list = [...Array(60 / step).keys()].map((i) => {
    const min = i * step;
    return {
      key: `minute-${i}`,
      value: min,
      label: `${preZero(min)}m`,
      type: 'string' as const,
    };
  });

  if (customMinutes) {
    return customMinutes.reduce(insertCustomMinute, list);
  }

  return list;
};

/**
 * Formats given duration in minutes
 */
export const formatDurationInMinutes = (minutes: number, short = false): string => {
  const mUnit = short ? 'm' : ' min';
  const hUnit = short ? 'h' : ' h';
  if (minutes < 60) {
    return `${minutes}${mUnit}`;
  } else {
    const h = Math.floor(minutes / 60);
    const m = minutes - h * 60;

    return `${h}${hUnit}${m > 0 ? ` ${m}${mUnit}` : ''}`;
  }
};

export const dayTimeToMinutes = (dayTime: DayTime) => dayTime.hour * 60 + dayTime.minute;

export const intervalDiff = (interval: WorkScheduleInterval): DayTime => {
  const to = dayTimeToMinutes(
    interval.to.hour === 0 && interval.to.minute === 0 ? ({ hour: 24, minute: 0 } as DayTime) : interval.to,
  );
  const from = dayTimeToMinutes(interval.from);

  const totalDiffMinutes = to >= from ? to - from : 24 * 60 - (from - to);

  const hour = Math.floor(totalDiffMinutes / 60) as DayTime['hour'];
  const minute = (totalDiffMinutes % 60) as DayTime['minute'];

  return { hour, minute };
};

export const dayTimeSum = (time1: DayTime, time2: DayTime): DayTime => {
  const value1 = dayTimeToMinutes(time1);
  const value2 = dayTimeToMinutes(time2);

  const totalSumMinutes = value1 + value2;

  const hour = Math.floor(totalSumMinutes / 60) as DayTime['hour'];
  const minute = (totalSumMinutes % 60) as DayTime['minute'];

  return { hour, minute };
};

export const isSameDay = (day1: DateTime, day2: DateTime) => {
  return day1.hasSame(day2, 'day') && day1.hasSame(day2, 'month') && day1.hasSame(day2, 'year');
};

export const intervalToString = (interval: WorkScheduleInterval) => {
  return `${preZero(interval.from.hour)}:${preZero(interval.from.minute)} - ${preZero(interval.to.hour)}:${preZero(
    interval.to.minute,
  )}`;
};

const toMinutes = (time: DayTime): number => time.hour * 60 + time.minute;

const toInterval = (minutes: number): DayTime =>
  ({
    hour: Math.floor(minutes / 60),
    minute: minutes % 60,
  } as DayTime);

const clamp = (value: number, min: number, max: number): number => Math.max(min, Math.min(value, max));

export const adjustIntervals = (payload: {
  from?: DayTime;
  to?: DayTime;
  priority: keyof WorkScheduleInterval;
  minInterval?: DayTime;
  maxInterval?: DayTime;
  desiredInterval?: DayTime; // Desired interval between from and to
}): WorkScheduleInterval => {
  const {
    from,
    to,
    priority,
    minInterval = { hour: 0, minute: 0 } as DayTime,
    maxInterval = { hour: 24, minute: 0 } as DayTime,
    desiredInterval = { hour: 1, minute: 0 } as DayTime,
  } = payload;

  const minMinutes = toMinutes(minInterval);
  const maxMinutes = toMinutes(maxInterval);
  const desiredMinutes = desiredInterval ? toMinutes(desiredInterval) : null;

  let startMinutes = from ? toMinutes(from) : null;

  // Handle `to` being `0:0` and convert to `24:0`
  let endMinutes = to ? toMinutes(to) : null;
  if (to && to.hour === 0 && to.minute === 0) {
    endMinutes = maxMinutes; // Consider `0:0` as `24:0`
  }

  // If only `from` is set, calculate `to`
  if (startMinutes !== null && endMinutes === null) {
    endMinutes = clamp(startMinutes + (desiredMinutes || minMinutes), startMinutes + minMinutes, maxMinutes);
  }

  // If only `to` is set, calculate `from`
  if (endMinutes !== null && startMinutes === null) {
    startMinutes = clamp(endMinutes - (desiredMinutes || minMinutes), 0, endMinutes - minMinutes);
  }

  // Ensure `from` and `to` are not equal
  if (startMinutes !== null && endMinutes !== null && startMinutes === endMinutes) {
    if (priority === 'from') {
      endMinutes = clamp(startMinutes + (desiredMinutes || minMinutes), startMinutes + minMinutes, maxMinutes);
    } else {
      startMinutes = clamp(endMinutes - (desiredMinutes || minMinutes), 0, endMinutes - minMinutes);
    }
  }

  // Validate intervals if both are non-null
  if (
    startMinutes !== null &&
    endMinutes !== null &&
    ((priority === 'from' && endMinutes >= startMinutes + minMinutes) ||
      (priority === 'to' && startMinutes <= endMinutes - minMinutes))
  ) {
    return {
      from: toInterval(startMinutes),
      to:
        endMinutes === maxMinutes
          ? toInterval(0) // Convert `24:0` back to `0:0` for output
          : toInterval(endMinutes),
    };
  }

  // Adjust intervals if validation fails
  let adjustedStart: number;
  let adjustedEnd: number;

  if (priority === 'to') {
    adjustedEnd = clamp(endMinutes || maxMinutes, minMinutes, maxMinutes);
    adjustedStart = desiredMinutes
      ? clamp(adjustedEnd - desiredMinutes, 0, maxMinutes - minMinutes)
      : clamp(adjustedEnd - minMinutes, 0, maxMinutes - minMinutes);
  } else {
    adjustedStart = clamp(startMinutes || 0, 0, maxMinutes - minMinutes);
    adjustedEnd = desiredMinutes
      ? clamp(adjustedStart + desiredMinutes, adjustedStart + minMinutes, maxMinutes)
      : clamp(adjustedStart + minMinutes, adjustedStart, maxMinutes);
  }

  const fromIntervalFinal = toInterval(adjustedStart);
  const toIntervalFinal = toInterval(adjustedEnd);

  return {
    from: fromIntervalFinal,
    to:
      toIntervalFinal.hour === 24 && toIntervalFinal.minute === 0
        ? ({ hour: 0, minute: 0 } as DayTime) // Convert `24:0` back to `0:0` for output
        : toIntervalFinal,
  };
};

export const getIntervals = (start: DayTime, end: DayTime, step = 15): { label: string; value: string }[] => {
  const formatTime = (hours: number, minutes: number, isLabel: boolean) =>
    isLabel
      ? hours === 24
        ? '00:00 (următoarea zi)'
        : `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
      : hours === 24
      ? '0:0'
      : `${hours}:${minutes}`;

  const startTime = toMinutes(start);
  const endTime = toMinutes(end);

  return Array.from({ length: Math.ceil((endTime - startTime) / step) + 1 }, (_, i) => {
    const { hour, minute } = toInterval(startTime + i * step);
    const currentMinutes = startTime + i * step;
    if (currentMinutes > endTime) return null; // Avoid extra intervals
    return {
      label: formatTime(hour, minute, true),
      value: formatTime(hour, minute, false),
    };
  }).filter((interval) => interval !== null) as { label: string; value: string }[];
};

export const dateObjectToDateString = (date: LocalDateObject): DateString => {
  return DateString.fromDate(DateTime.fromObject(date, { zone: DEFAULT_TIMEZONE }).toJSDate(), DEFAULT_TIMEZONE);
};

export const isInInterval = (time: { hour: number; minute: number }, interval: WorkScheduleInterval) => {
  const timeMinutes = time.hour * 60 + time.minute;
  const fromMinutes = interval.from.hour * 60 + interval.from.minute;
  const toMinutes = interval.to.hour * 60 + interval.to.minute;

  return timeMinutes >= fromMinutes && timeMinutes < toMinutes;
};

export const checkIfIsAllowedInterval = (activeHours: WorkScheduleInterval[], hour: number, minute: number) => {
  return activeHours.some((interval) => isInInterval({ hour, minute }, interval));
};

export const getWorkIntervals = (schedule: WorkerSchedule = {}, date: DateString): WorkScheduleInterval[] =>
  schedule[date] ?? [];

export const getOverlaps = (intervals: Partial<WorkScheduleInterval>[]): boolean[] => {
  return intervals.map((current, currentIndex) => {
    if (!WorkScheduleInterval.JSON.is(current)) {
      return false;
    }
    const currentStart = current.from.hour * 60 + current.from.minute;
    const currentEnd = current.to.hour * 60 + current.to.minute;

    return intervals.slice(0, currentIndex).some((prev) => {
      if (!WorkScheduleInterval.JSON.is(prev)) {
        return false;
      }
      const prevStart = prev.from.hour * 60 + prev.from.minute;
      const prevEnd = prev.to.hour * 60 + prev.to.minute;

      return currentStart < prevEnd && currentEnd > prevStart;
    });
  });
};
