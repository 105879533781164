import {
  Body,
  colors,
  Column,
  HSpacer,
  Icon,
  Line,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity, View } from 'react-native';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { useClickOutsideWeb } from '../../../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AuthorizedStackParamList, HomeTabsParamsList, ProsDashboardStackParamList } from '../../../../../types';
import { styles } from './ProsWebScreen.styles';

type Props = {
  showOrderAction: boolean;
};

const TeamWebFilters: React.FC<Props> = ({ showOrderAction }) => {
  const { t } = useTranslation('pros');
  const { isDesktop } = useMediaQueries();

  const addMenuRef = React.useRef<View>(null);
  const [showAddMenu, setShowAddMenu] = React.useState(false);

  useClickOutsideWeb({
    ref: addMenuRef,
    isVisible: showAddMenu,
    onClickOutside() {
      setShowAddMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  const navigation =
    useNavigation<NavigationProp<AuthorizedStackParamList & HomeTabsParamsList & ProsDashboardStackParamList>>();

  const navigateSortingCallback = React.useCallback(() => {
    navigation.navigate('ProsEdit', { screen: 'SortPros' });
  }, [navigation]);

  const addProsCallback = React.useCallback(() => {
    navigation.navigate('ProsEdit', {
      screen: 'ProAddNewStack',
    });
    setShowAddMenu(false);
  }, [navigation]);

  const inviteProsCallback = React.useCallback(() => {
    navigation.navigate('ProsEdit', {
      screen: 'ProInviteStack',
    });
    setShowAddMenu(false);
  }, [navigation]);

  return (
    <Row>
      {showOrderAction && (
        <>
          <TouchableOpacity style={{ flexDirection: 'row' }} onPress={navigateSortingCallback}>
            <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('sort')}</Body>
          </TouchableOpacity>
          <HSpacer left={32} />
        </>
      )}
      <View ref={addMenuRef} style={{ flexDirection: 'column', display: 'flex' }}>
        <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setShowAddMenu((prev) => !prev)}>
          <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('add')}</Body>
          <View
            style={{
              paddingLeft: 8,
              justifyContent: 'center',
            }}
          >
            <Icon type="dropdown" color={colors.DARK_BLUE} />
          </View>
        </TouchableOpacity>

        <View style={{ position: 'relative' }}>
          {showAddMenu && (
            <Column style={styles.webAddProsActions}>
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={addProsCallback}>
                <Title>{t('addPro')}</Title>
              </TouchableOpacity>
              <Line />
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={inviteProsCallback}>
                <Title>{t('invitePro')}</Title>
                <Spacer size={2} />
                <SmallBody>{t('inviteProSubtitle')}</SmallBody>
              </TouchableOpacity>
            </Column>
          )}
        </View>
      </View>
    </Row>
  );
};

export default TeamWebFilters;
