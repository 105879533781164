import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import AddExtraProScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/Schedule/AddExtraProScreen';
import SetScheduleScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/Schedule/SetScheduleScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withScheduleContextProvider } from '../contexts/ScheduleContext';
import { ScheduleStackParamList } from '../types';
import { styles } from './styles';

const ScheduleStack = createStackNavigator<ScheduleStackParamList>();

function ScheduleStackNavigator() {
  const { t } = useTranslation();

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen
        name="SetSchedule"
        component={SetScheduleScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('pros:setSchedule'),
        }}
      />
      <ScheduleStack.Screen
        name="AddExtraPro"
        component={AddExtraProScreen}
        options={{
          ...(isPhone ? TransitionPresets.SlideFromRightIOS : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('pros:selectPros'),
        }}
      />
    </ScheduleStack.Navigator>
  );
}

export default pipe(ScheduleStackNavigator, withScheduleContextProvider);
