import { Positive, ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';

export const convertScale = (value: ScaledNumber, scale: number): ScaledNumber => {
  if (value.scale === scale) {
    return value;
  }

  return {
    value: value.value * Math.pow(10, scale - (value.scale ?? 0)),
    scale,
  };
};

export const PositiveScaledNumber = (() => {
  return {
    ...ScaledNumber.Positive,
    fromNumber: (value: number, decimals: number) => {
      const positive = ScaledNumber.Positive.from(ScaledNumber.fromNumber(value, decimals));
      return positive.isOk ? positive.value : (ScaledNumber.fromNumber(1, decimals) as Positive<ScaledNumber>);
    },
  };
})();

export const positiveScaledNumber = (value: number, decimals: number, defaultValue = 1) => {
  return (
    pipe(ScaledNumber.fromNumber(value, decimals)),
    ScaledNumber.Positive.JSON.decode,
    E.getOrElse(() => ScaledNumber.Positive.from(ScaledNumber.fromNumber(defaultValue, decimals)))
  );
};

export const scaledToString = (value: ScaledNumber, locale?: string): string => {
  if (value.scale === 0 || value.scale === undefined) {
    return value.value.toLocaleString(locale);
  } else {
    const div = Math.pow(10, value.scale);
    const absValue = Math.abs(value.value);
    const sign = value.value < 0 ? '-' : '';
    const intStr = Math.trunc(absValue / div).toLocaleString(locale);
    const decimalStr = (absValue % div).toString();
    const padding = '0'.repeat(Math.max(0, Math.min(value.scale, 2) - decimalStr.length));
    const decimalPoint = (1.1).toLocaleString(locale).substring(1, 2);

    //Use a maximum of 2 decimals
    return `${sign}${intStr}${decimalPoint}${padding}${decimalStr.substring(0, Math.min(2, value.scale))}`;
  }
};

const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const identifySeparatorsForLocale = (locale?: string) => {
  const sampleNumber = 1000.1;

  const formatted = sampleNumber.toLocaleString(locale);

  const decimalSeparator = formatted.replace(/[0-9]/g, '').slice(-1);

  const potentialThousandSeparator = formatted.replace(new RegExp(`[0-9]|${escapeRegExp(decimalSeparator)}`, 'g'), '');
  const thousandSeparator = potentialThousandSeparator ? potentialThousandSeparator[0] : undefined;

  return {
    thousand: thousandSeparator,
    decimal: decimalSeparator,
  };
};

export const stripLocalThousandsSeparators = (value: string, locale?: string) => {
  const { thousand } = identifySeparatorsForLocale(locale);

  return value.replace(new RegExp(`\\${thousand}`, 'g'), '');
};

export const localeStringToNumber = (value: string, locale?: string) => {
  const { thousand, decimal } = identifySeparatorsForLocale(locale);

  const standardized = value
    .replace(thousand ? new RegExp(escapeRegExp(thousand), 'g') : '', '')
    .replace(new RegExp(escapeRegExp(decimal), 'g'), '.');

  return parseFloat(standardized);
};

export const replaceDecimalSeparator = (input: string, locale?: string) => {
  const { decimal } = identifySeparatorsForLocale(locale);
  const otherSeparator = decimal === '.' ? ',' : '.';

  return input.replace(new RegExp(escapeRegExp(otherSeparator), 'g'), decimal);
};

export const extractNumberOfDecimals = (value: string, locale?: string) => {
  const { decimal } = identifySeparatorsForLocale(locale);
  const [, decimals] = value.split(decimal);

  return decimals?.length ?? 0;
};

export const localeNumberValidator = (value: string, locale?: string): value is string => {
  const { decimal } = identifySeparatorsForLocale(locale);

  if (value === decimal) {
    return false;
  }

  // Check for multiple decimal separators
  if ((value.match(new RegExp(`\\${decimal}`, 'g')) || []).length > 1) {
    return false;
  }

  const pattern = new RegExp(`^\\d*\\${decimal}?\\d*$`);
  if (!pattern.test(value)) {
    return false;
  }

  // Check if there are any characters that are not numbers or the decimal separator
  const nonNumericPattern = new RegExp(`[^\\d\\${decimal}]`);
  return !nonNumericPattern.test(value);
};

export const multiplyScaled = (a: ScaledNumber, b: number): ScaledNumber =>
  ScaledNumber.mul(a, ScaledNumber.fromNumber(b, a.scale ?? 0));

export const divideScaled = (a: ScaledNumber, b: number): ScaledNumber =>
  ScaledNumber.div(a, ScaledNumber.fromNumber(b, 0), a.scale ?? 2);
