import {
  CheckoutCashRegistryTransactionType,
  CheckoutCashRegistryTransactionId,
  ScaledNumber,
  CheckoutCashRegistryTransactionOrigin,
  CheckoutTransactionId,
  DateString,
  CashRegistryExportFormatType,
} from '@mero/api-sdk';
import {
  Body,
  SmallBody,
  Row,
  styles as meroStyles,
  Button,
  colors,
  Icon,
  Spacer,
  FormCard,
  Line,
  Column,
  H3s,
  useShowError,
} from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as types from 'io-ts';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DimensionValue, FlatList, TouchableOpacity, View, ScrollView, Dimensions, Linking } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { useClickOutsideWeb } from '../../../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';
import { scaledToString } from '../../../../../utils/scaled';

type Props = {
  navigateInitialBalance: () => void;
  navigateOptionsMenu: () => void;
  navigateTransaction: (transactionId?: CheckoutCashRegistryTransactionId) => void;
  navigateCheckoutTransaction: (checkoutTransactionId: CheckoutTransactionId) => void;
};

export enum RowType {
  SEPARATOR = 'SEPARATOR',
  TRANSACTION = 'TRANSACTION',
  SOLD = 'SOLD',
  INITIAL_BALANCE = 'INITIAL_BALANCE',
}

export type TransactionRow = {
  type: RowType.TRANSACTION;
  _id: CheckoutCashRegistryTransactionId;
  date: Date;
  text: string;
  lastUpdate: string;
  details: string;
  documentNo: string;
  debit: string;
  credit: string;
  sold: string;
  origin: string;
  checkoutTransactionId?: string;
  isDeleted?: boolean;
};

export type SoldRow = {
  type: RowType.SOLD;
  sold: string;
  text: string;
};

export type InitialBalanceRow = {
  type: RowType.INITIAL_BALANCE;
  sold: string;
  text: string;
};

export type SeparatorRow = {
  type: RowType.SEPARATOR;
};

export type Row = TransactionRow | SoldRow | InitialBalanceRow | SeparatorRow;

export type Section = {
  date: string;
  startDayBalance: string;
  endDayBalance: string;
  transactions: Row[];
};

type Column<Row extends Record<string, string | Date | number | boolean>> = {
  field: keyof Row;
  headerName: string;
  width: DimensionValue;
  style?: {
    header?: React.ComponentProps<typeof SmallBody>['style'];
    cell?: React.ComponentProps<typeof SmallBody>['style'];
    fixedCell?: React.ComponentProps<typeof SmallBody>['style'];
  };
};

const NextIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 7242">
      <Path d="m9.407 17.743-1.385-1.385 4.569-4.489-4.569-4.485L9.407 6l5.954 5.869Z" fill="#080de0" />
      <Path data-name="Rectangle 2" fill="none" d="M0 0h24v24H0z" />
    </G>
  </Svg>
);

const TransactionListScreen: React.FC<Props> = ({
  navigateInitialBalance,
  navigateOptionsMenu,
  navigateTransaction,
  navigateCheckoutTransaction,
}) => {
  const { t } = useTranslation('cashRegistry');
  const { isPhone, isDesktop } = useMediaQueries();
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const maxWidth = (screenWidth - 48) * 0.2;
  const marginTop = (screenHeight - 212) / 2;
  const scrollViewRef = React.useRef<ScrollView>(null);
  const showError = useShowError();

  const [cashRegistryState, { update }] = CashRegistryContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [columns, setColumns] = React.useState<Column<Row>[] | null>(null);
  const [data, setData] = React.useState<Row[] | null>(null);

  function formatDateToDDMMYYYY(date: Date | string): string {
    if (typeof date === 'string') {
      const dateParts = date.split('-');
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    } else if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${day}-${month}-${year}`;
    }
    return '';
  }

  React.useEffect(() => {
    if (cashRegistryState.type !== 'Loaded' || !cashRegistryState.cashRegistry) {
      return;
    }

    let data: Row[] = [];

    const startDate = cashRegistryState.activeInterval.value.start.toJSDate();
    const endDate = cashRegistryState.activeInterval.value.end.toJSDate();
    const initialBalance = cashRegistryState.cashRegistry.initialBalance;
    const sections: Section[] = [];

    cashRegistryState.transactionList?.dailyBreakdown.forEach((day, i) => {
      sections.push({
        date: formatDateToDDMMYYYY(day.date),
        startDayBalance: scaledToString(day.startDayBalance.amount),
        endDayBalance: scaledToString(day.endDayBalance.amount),
        transactions: day.transactions.map((entry, j) => ({
          type: RowType.TRANSACTION,
          origin: entry.origin,
          _id: entry._id,
          date: entry.transactionDate,
          transactionType: entry.type,
          text: t(entry.type).toUpperCase(),
          lastUpdate: `${entry.updated.user.firstname} ${entry.updated.user.lastname}`,
          details: entry.details,
          documentNo: entry.docNo,
          debit: entry.type === CheckoutCashRegistryTransactionType.DEBIT ? scaledToString(entry.amount.amount) : '',
          credit: entry.type === CheckoutCashRegistryTransactionType.CREDIT ? scaledToString(entry.amount.amount) : '',
          sold: scaledToString(entry.intermediaryBalance.amount),
          checkoutTransactionId: entry.checkoutTransactionId,
          dayIndex: i,
          rowIndex: j,
          isDeleted: entry.checkoutTransaction?.isDeleted,
        })),
      });
    });

    if (startDate <= initialBalance.date && initialBalance.date <= endDate) {
      const initialBalanceSold = scaledToString(initialBalance.amount.amount);
      const initialBalanceDate = formatDateToDDMMYYYY(initialBalance.date);
      const row: Row = {
        type: RowType.INITIAL_BALANCE,
        text: t('firstInitialBalance'),
        sold: initialBalanceSold,
      };

      const newEntry: Section = {
        date: initialBalanceDate,
        startDayBalance: '0.00',
        endDayBalance: initialBalanceSold,
        transactions: [row],
      };

      const index = sections.findIndex((section) => section.date === initialBalanceDate);
      if (index !== -1) {
        const foundDay = sections[index];
        const newTransactions = foundDay.transactions ? [row, ...foundDay.transactions] : [row];

        sections[index] = {
          ...foundDay,
          transactions: newTransactions,
        };
      } else {
        sections.unshift(newEntry);
      }
    }

    let index = 0;
    sections.forEach((section) => {
      if (index !== 0) {
        data.push({
          type: RowType.SEPARATOR,
        });
      }
      const { startDayBalance, endDayBalance, date, transactions } = section;
      const initialSoldRow: Row = {
        type: RowType.SOLD,
        text: `${t('initialSold')} ${date}`,
        sold: startDayBalance,
      };
      const finalSoldRow: Row = {
        type: RowType.SOLD,
        text: `${t('finalSold')} ${date}`,
        sold: endDayBalance,
      };
      data = [...data, initialSoldRow, ...transactions, finalSoldRow];
      index++;
    });
    const columns: Column<Row>[] = [
      {
        field: 'text' as keyof Row,
        headerName: t('transactionType'),
        width: '15%',
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'lastUpdate' as keyof Row,
        headerName: t('lastUpdate'),
        width: '15%',
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'details' as keyof Row,
        headerName: t('details'),
        width: '20%',
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'documentNo' as keyof Row,
        headerName: t('documentNo'),
        width: '15%',
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'debit' as keyof Row,
        headerName: t('debits'),
        width: '10%',
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'credit' as keyof Row,
        headerName: t('credits'),
        width: '10%',
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'sold' as keyof Row,
        headerName: t('sold'),
        width: '10%',
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
    ];

    setColumns(columns);
    setData(data);
  }, [cashRegistryState]);

  const renderHeader = () => {
    if (!columns || isPhone) {
      return <></>;
    }

    return (
      <>
        <Row style={[{ paddingVertical: 12, paddingLeft: 16, justifyContent: 'space-between' }]}>
          {columns.map((column, index) => (
            <View
              key={column.field.toString()}
              style={{
                width: column.width,
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: maxWidth,
                paddingRight: 16,
                justifyContent: index >= columns.length - 3 ? 'flex-end' : 'flex-start',
              }}
            >
              <SmallBody style={[{ fontFamily: 'open-sans-semibold', fontSize: 12 }, column.style?.header]}>
                {column.headerName}
              </SmallBody>
            </View>
          ))}
          <Row style={{ width: 50, paddingRight: 16 }}></Row>
        </Row>
        <Line color={colors.ATHENS_GRAY} />
      </>
    );
  };

  const getStyle = (row: Row, field: string): { color: string; fontWeight: 'bold' | 'normal' } => {
    if (row.type !== RowType.TRANSACTION || field !== 'text') {
      return {
        color: colors.BLACK,
        fontWeight: 'normal',
      };
    }
    if (row.text === t('debit').toUpperCase()) {
      return {
        color: colors.RADICAL_RED,
        fontWeight: 'bold',
      };
    }
    if (row.text === t('credit').toUpperCase()) {
      return {
        color: colors.SHAMROCK,
        fontWeight: 'bold',
      };
    }
    return {
      color: colors.BLACK,
      fontWeight: 'normal',
    };
  };

  const onClickTransactionRow = (row: Row) => {
    if (row.type !== RowType.TRANSACTION || cashRegistryState.type !== 'Loaded') {
      return;
    }

    if (row.origin === CheckoutCashRegistryTransactionOrigin.AUTOMATIC && row.checkoutTransactionId && !row.isDeleted) {
      return navigateCheckoutTransaction(row.checkoutTransactionId as CheckoutTransactionId);
    }

    return navigateTransaction(row._id);
  };

  const renderItem = ({ item }: { item: Row }) => {
    if (item.type === RowType.SEPARATOR) {
      return renderSeparatorItem();
    }
    if (item.type === RowType.INITIAL_BALANCE) {
      return renderInitialBalanceItem(item);
    }
    if (item.type === RowType.TRANSACTION) {
      return renderTransactionItem(item);
    }
    if (item.type === RowType.SOLD) {
      return renderSoldItem(item);
    }
    return <></>;
  };

  const renderSeparatorItem = () => {
    return <Row style={{ paddingVertical: 8, flexGrow: 1, backgroundColor: '#FAFAFA', borderWidth: 0 }} />;
  };

  const renderInitialBalanceItem = (row: Row) => {
    if (!columns || row.type !== RowType.INITIAL_BALANCE) {
      return <></>;
    }

    return isPhone ? (
      <Row style={{ paddingVertical: 16, paddingHorizontal: 24, flexGrow: 1, justifyContent: 'space-between' }}>
        <SmallBody>{row.text}</SmallBody>
        <Body>{row.sold}</Body>
      </Row>
    ) : (
      <Row style={{ paddingVertical: 24, paddingLeft: 16, flexGrow: 1, justifyContent: 'space-between' }}>
        {columns.map((column, index) => (
          <Row
            key={column.field.toString()}
            style={{
              width: column.width,
              paddingRight: 16,
              justifyContent: index >= columns.length - 3 ? 'flex-end' : 'flex-start',
            }}
          >
            <SmallBody>{row[column.field]?.toString()}</SmallBody>
          </Row>
        ))}
        <Row style={{ width: 50, paddingRight: 16 }}></Row>
      </Row>
    );
  };

  const renderSoldItem = (row: Row) => {
    if (!columns || row.type !== RowType.SOLD) {
      return <></>;
    }
    return isPhone ? (
      <Row style={{ paddingVertical: 16, paddingHorizontal: 24, flexGrow: 1, justifyContent: 'space-between' }}>
        <Body style={[meroStyles.text.semibold]}>{row.text}</Body>
        <Body>{row.sold}</Body>
      </Row>
    ) : (
      <Row style={{ paddingVertical: 24, paddingLeft: 16, flexGrow: 1, justifyContent: 'space-between' }}>
        {columns.map((column, index) => (
          <Row
            key={column.field.toString()}
            style={{
              width: column.width,
              paddingRight: 16,
              justifyContent: index >= columns.length - 3 ? 'flex-end' : 'flex-start',
            }}
          >
            <SmallBody style={[meroStyles.text.semibold]}>{row[column.field]?.toString()}</SmallBody>
          </Row>
        ))}
        <Row style={{ width: 50, paddingRight: 16 }}></Row>
      </Row>
    );
  };

  const renderTransactionItem = (row: Row) => {
    if (!columns || row.type !== RowType.TRANSACTION) {
      return <></>;
    }

    return (
      <TouchableOpacity onPress={() => onClickTransactionRow(row)}>
        {isPhone ? (
          <Row style={{ paddingVertical: 16, paddingHorizontal: 24, flexGrow: 1, justifyContent: 'space-between' }}>
            <Column style={{ maxWidth: screenWidth / 2 }}>
              <SmallBody style={[getStyle(row, 'text'), { fontSize: 12 }]}>{row.text}</SmallBody>
              <Spacer size={8} />
              <Body style={meroStyles.text.semibold}>{row.details}</Body>
              <SmallBody>{row.lastUpdate}</SmallBody>
              <Spacer size={4} />
              <SmallBody style={{ color: colors.COMET }}>Sold: {row.sold}</SmallBody>
            </Column>

            <Row style={{ alignItems: 'center' }}>
              <Body>{row.text === t('credit').toUpperCase() ? row.credit : row.debit}</Body>
              <View style={{ marginLeft: 8 }}>
                <NextIcon />
              </View>
            </Row>
          </Row>
        ) : (
          <Row style={{ paddingVertical: 24, paddingLeft: 16, flex: 1, flexGrow: 1, justifyContent: 'space-between' }}>
            {columns.map((column, index) => (
              <Row
                key={column.field.toString()}
                style={{
                  width: column.width,
                  maxWidth: maxWidth,
                  paddingRight: 16,
                  justifyContent: index >= columns.length - 3 ? 'flex-end' : 'flex-start',
                }}
              >
                <SmallBody style={getStyle(row, column.field)}>{row[column.field].toString()}</SmallBody>
              </Row>
            ))}
            <Row style={{ width: 50, justifyContent: 'flex-end', paddingRight: 16 }}>
              <NextIcon />
            </Row>
          </Row>
        )}
      </TouchableOpacity>
    );
  };

  if (cashRegistryState.type !== 'Loaded' || !cashRegistryState.cashRegistry) {
    return <></>;
  }

  const optionsSelectRef = React.useRef<View>(null);

  useClickOutsideWeb({
    ref: optionsSelectRef,
    isVisible: cashRegistryState.isOptionsMenuOpen,
    onClickOutside() {
      update({ isOptionsMenuOpen: false });
    },
  });

  const onOptionsClick = () => {
    update({ isOptionsMenuOpen: true });
    if (isPhone) {
      navigateOptionsMenu();
    }
  };

  const toDateString = (date: DateTime) => {
    return pipe(
      date.toFormat('yyyy-MM-dd'),
      DateString.JSON.decode,
      E.fold(() => {
        throw new Error(`Failed parse DateString`);
      }, types.identity),
    );
  };

  const downloadTransactions = async (format: CashRegistryExportFormatType) => {
    if (
      cashRegistryState.type !== 'Loaded' ||
      pageState.type !== 'Loaded' ||
      !cashRegistryState.cashRegistry ||
      !cashRegistryState.selectedCompany
    ) {
      return;
    }

    try {
      const { downloadUrl } = await meroApi.checkout.exportCashRegistryTransactions({
        pageId: pageState.page.details._id,
        companyId: cashRegistryState.selectedCompany._id,
        cashRegistryId: cashRegistryState.cashRegistry._id,
        fromDate: toDateString(cashRegistryState.activeInterval.value.start),
        toDate: toDateString(cashRegistryState.activeInterval.value.end),
        format: format,
      });

      Linking.openURL(downloadUrl).catch((error) => {
        showError(error.message);
      });
    } catch (error) {
      log.error('Failed to download report', error);
      showError(t('failedToDownloadReport'));
    }
  };

  return (
    <ScrollView
      ref={scrollViewRef}
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: colors.ALABASTER, flex: 1, paddingHorizontal: isPhone ? 0 : 24 }}
    >
      {isDesktop && (
        <Row style={{ alignItems: 'center', justifyContent: 'space-between', paddingVertical: 24 }}>
          <Row>
            <Body style={meroStyles.text.semibold}>{t('currentBalance')} </Body>
            <Body>
              {scaledToString(cashRegistryState.transactionList?.currentBalance.amount ?? ScaledNumber.zero())}{' '}
            </Body>
          </Row>

          <Row style={{ alignItems: 'center', position: 'relative' }}>
            <TouchableOpacity style={{ justifyContent: 'center', marginRight: 24 }} onPress={onOptionsClick}>
              <View style={[{ flexDirection: 'row', alignItems: 'center' }]}>
                <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('options')}</SmallBody>
                <View style={[{ paddingLeft: 10 }]}>
                  <Icon type="dropdown" rotate={cashRegistryState.isOptionsMenuOpen} color={colors.DARK_BLUE} />
                </View>
              </View>
            </TouchableOpacity>
            <Button text={t('addTransaction')} size="small" padding={24} onClick={navigateTransaction} />
            {cashRegistryState.isOptionsMenuOpen && isDesktop && (
              <View
                ref={optionsSelectRef}
                style={{
                  position: 'absolute',
                  top: 36,
                  right: 193,
                  paddingHorizontal: 16,
                  backgroundColor: '#ffffff',
                  borderRadius: 16,
                  minWidth: 300,
                  shadowColor: '#000000',
                  shadowOffset: { width: 1, height: 1 },
                  shadowOpacity: 0.16,
                  shadowRadius: 16,
                  elevation: 16,
                  zIndex: 2,
                }}
              >
                <Spacer size="16" />
                <TouchableOpacity
                  style={{ flex: 2, flexDirection: 'row' }}
                  onPress={() => downloadTransactions('xlsx')}
                >
                  <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('exportExcel')}</Body>
                </TouchableOpacity>
                <Spacer size="16" />
                <Line />

                <Spacer size="16" />
                <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={() => downloadTransactions('pdf')}>
                  <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('exportPDF')}</Body>
                </TouchableOpacity>
                <Spacer size="16" />
                <Line />

                <Spacer size="16" />
                <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={navigateInitialBalance}>
                  <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('setInitialBalance')}</Body>
                </TouchableOpacity>
                <Spacer size="16" />
              </View>
            )}
          </Row>
        </Row>
      )}

      {columns && data?.length === 0 ? (
        <H3s
          style={{
            marginTop: marginTop,
            alignSelf: 'center',
            justifyContent: 'center',
            maxWidth: 300,
            textAlign: 'center',
          }}
        >
          {t('noTransactions')}
        </H3s>
      ) : (
        <>
          <FormCard rounded paddings="none" style={{ paddingTop: 4, zIndex: -1 }} dropShaddow>
            <FlatList
              data={data}
              ListHeaderComponent={renderHeader}
              keyExtractor={(_, index) => index.toString()}
              renderItem={renderItem}
              extraData={data}
              ItemSeparatorComponent={() => <Line color={colors.ATHENS_GRAY} />}
              onEndReachedThreshold={0.5}
            />
          </FormCard>
          <Spacer size={144} />
        </>
      )}
    </ScrollView>
  );
};

export default TransactionListScreen;
