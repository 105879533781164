import {
  Barcode,
  DefinedTrimedString,
  MeroUnits,
  PortionPercentScaledNumber,
  Product,
  ProductAvailability,
  ProductBrandId,
  ProductCategoryId,
  ProductId,
  ProductStatus,
  ProductMeasure,
  UpdateProductPayload,
  CreateProductPayload,
} from '@mero/api-sdk';
import {
  Body,
  FormCard,
  H1,
  InputWithLabel,
  MeroHeader,
  Spacer,
  TypeSafeTextInput,
  colors,
  styles as meroStyles,
  Select,
  Button,
  Line,
  useKeyboardIsOpen,
  SafeAreaView,
  Row,
  useShowError,
  useToast,
  SmallBody,
  HSpacer,
} from '@mero/components';
import { Positive, ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/lib/Either';
import { flow, pipe } from 'fp-ts/lib/function';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, Platform, View, ActivityIndicator } from 'react-native';

import MenuItem from '../../../components/MenuItem';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductContext } from '../../../contexts/SelectedProductContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  ProductEditStackParamList,
  RootStackParamList,
} from '../../../types';
import { PositiveNumberFromString, numberValidator } from '../../../utils/number';
import {
  PositiveScaledNumber,
  localeStringToNumber,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import ProductPriceCard from './ProductPriceCard';
import { TextToolItem } from './ProductsMenuScreen';

type Props = {
  route: RouteProp<ProductEditStackParamList, 'ProductScreen'>;
  navigation: CompositeNavigationProp<
    StackNavigationProp<ProductEditStackParamList, 'ProductScreen'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'ProductsTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

const INPUT_POSITIONS = {
  name: 50,
  measureUnit: 400,
  measureValue: 500,
  initialPrice: 600,
} as const;

const ProductScreen: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();
  const scrollRef = React.useRef<ScrollView>(null);
  const isKeyboardOpen = useKeyboardIsOpen();
  const showSubmitButton = !(isKeyboardOpen && Platform.OS === 'android');
  const { isPhone } = useMediaQueries();
  const showError = useShowError();
  const toast = useToast();

  const [, { updateProductDetails }] = SelectedProductContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [state] = ProductsContext.useContext();
  const [showErrors, setShowErrors] = React.useState(false);
  const [, { reload }] = SearchProductsContext.useContext();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = React.useState<Product | undefined>();
  const runOnce = React.useRef(false);

  const [name, setName] = React.useState({
    input: '',
    decoded: DefinedTrimedString.decode(''),
  });
  const isNameValid = E.isRight(name.decoded);

  const [barcode, setBarcode] = React.useState({
    input: '',
    decoded: Barcode.BarcodeValue.JSON.decode(''),
  });
  const isBarcodeValid = barcode.input === '' || Barcode.BarcodeValue.JSON.is(barcode.input);

  const [availability, setAvailability] = React.useState<ProductAvailability.Any>(
    ProductAvailability.OfflineRetail.value,
  );

  const [categoryId, setCategoryId] = React.useState<ProductCategoryId | undefined>();

  const [brandId, setBrandId] = React.useState<ProductBrandId | undefined>();

  const [measureUnit, setMeasureUnit] = React.useState<string | undefined>();

  const [measureValue, setMeasureValue] = React.useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });
  const isMeasureValueValid = E.isRight(measureValue.decoded);

  /** Price fields */
  const [showDiscountPrice, setShowDiscountPrice] = useState<boolean>(false);

  const [initialPrice, setInitialPrice] = useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });

  const [finalPrice, setFinalPrice] = useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });

  const [discountPrice, setDiscountPrice] = useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });
  const isDiscountLessThanFinalPrice =
    E.isRight(discountPrice.decoded) &&
    E.isRight(finalPrice.decoded) &&
    discountPrice.decoded.right < finalPrice.decoded.right;

  const [vatRate, setVatRate] = useState<number>(19);

  const categories = React.useMemo(() => {
    if (state.type !== 'Loaded') {
      return;
    }

    let categoriesList: { value: ProductCategoryId | undefined; label: string }[] = [
      {
        label: t('noCategory'),
        value: undefined,
      },
    ];

    categoriesList = categoriesList.concat(
      state.categories?.map((category) => ({
        value: category._id,
        label: category.name,
      })),
    );

    return categoriesList;
  }, [state]);

  const brands = React.useMemo(() => {
    if (state.type !== 'Loaded') {
      return;
    }

    let brandsList: { value: ProductBrandId | undefined; label: string }[] = [
      {
        label: t('noBrand'),
        value: undefined,
      },
    ];

    brandsList = brandsList.concat(
      state.brands.map((brand) => ({
        value: brand._id,
        label: brand.name,
      })),
    );

    return brandsList;
  }, [state]);

  const unitsOfMeasurement = React.useMemo(() => {
    if (state.type !== 'Loaded') {
      return;
    }

    return state.unitsOfMeasurement?.map((measure) => ({
      value: measure.unit,
      label: `${measure.name} (${measure.unit})`,
    }));
  }, [state]);

  const scrollTo = (): void => {
    const scrollToY = !isNameValid
      ? INPUT_POSITIONS.name
      : !measureUnit
      ? INPUT_POSITIONS.measureUnit
      : !isMeasureValueValid
      ? INPUT_POSITIONS.measureValue
      : E.isLeft(initialPrice.decoded)
      ? INPUT_POSITIONS.initialPrice
      : 0;
    scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
  };

  React.useEffect(() => {
    const init = async () => {
      if (runOnce.current) {
        return;
      }
      if (state.type === 'Loaded' && pageState.type === 'Loaded' && route.params?.productId) {
        const product = await meroApi.pro.products.getProductById({
          pageId: pageState.page.details._id,
          productId: route.params.productId,
        });
        setProduct(product);

        const { availability, name, barcode, category, brandId, price, measure } = product;

        setAvailability(availability);
        setName({ input: name, decoded: DefinedTrimedString.decode(name) });

        if (barcode) {
          setBarcode({ input: barcode.value, decoded: Barcode.BarcodeValue.JSON.decode(barcode.value) });
        }

        if (category?.categoryId) {
          setCategoryId(category.categoryId);
        }

        if (brandId) {
          setBrandId(brandId);
        }

        setMeasureUnit(measure.unit);

        setVatRate(parseFloat(scaledToString(price.vatRate)));

        setMeasureValue({
          input: ScaledNumber.toStr(measure.value),
          decoded: PositiveNumberFromString.decode(ScaledNumber.toStr(measure.value)),
        });

        runOnce.current = true;
      } else {
        if (state.selectedCategory?.type === 'custom') {
          setCategoryId(state.selectedCategory._id);
        }
        if (state.selectedBrand) {
          setBrandId(state.selectedBrand._id);
        }
      }
    };
    init();
  }, [state.type]);

  React.useEffect(() => {
    if (state.type === 'Loaded' && state.selectedCategory?.type === 'custom') {
      setCategoryId(state.selectedCategory._id);
    }
  }, [state.type === 'Loaded' && state.selectedCategory?.type === 'custom' && state.selectedCategory._id]);

  React.useEffect(() => {
    if (state.type === 'Loaded' && state.selectedBrand?._id) {
      setBrandId(state.selectedBrand._id);
    }
  }, [state.type === 'Loaded' && state.selectedBrand?._id]);

  const updateProduct = async () => {
    if (pageState.type !== 'Loaded' || state.type !== 'Loaded' || !product) {
      return;
    }

    if (!measureUnit || !availability || E.isLeft(name.decoded)) {
      scrollTo();
      return setShowErrors(true);
    }

    const unit = ProductMeasure.Unit.unsafeFrom(measureUnit);
    const unitSpec = state.unitsOfMeasurement.find((u) => u.unit === unit);

    if (!unitSpec) {
      scrollTo();
      return setShowErrors(true);
    }

    const value =
      measureValue.input === '' && unitSpec.implicitValue
        ? PositiveScaledNumber.fromNumber(unitSpec.implicitValue, unitSpec.exponent)
        : E.isRight(measureValue.decoded)
        ? pipe(
            ScaledNumber.fromNumber(localeStringToNumber(measureValue.input), unitSpec.exponent),
            PositiveScaledNumber.JSON.decode,
            E.getOrElseW(() => undefined),
          )
        : undefined;

    if (!ScaledNumber.Positive.JSON.is(value)) {
      return setShowErrors(true);
    }

    let finalBarcode: Barcode.Any | undefined;
    if (E.isRight(barcode.decoded)) {
      if (Barcode.EANString.JSON.is(barcode.decoded.right))
        finalBarcode = {
          type: 'EAN',
          value: barcode.decoded.right,
        };
      else if (Barcode.UPCString.JSON.is(barcode.decoded.right)) {
        finalBarcode = {
          type: 'UPC',
          value: barcode.decoded.right,
        };
      } else if (Barcode.Code39String.JSON.is(barcode.decoded.right)) {
        finalBarcode = {
          type: 'Code39',
          value: barcode.decoded.right,
        };
      } else if (Barcode.Code128String.JSON.is(barcode.decoded.right)) {
        finalBarcode = {
          type: 'Code128',
          value: barcode.decoded.right,
        };
      }
    }

    try {
      const newMeasure: ProductMeasure.Any = {
        unit,
        value,
        exponent: unitSpec.exponent,
      };

      const newBarcode =
        barcode.input !== ''
          ? {
              barcode: finalBarcode,
            }
          : {};

      const newName = name.decoded.right;
      const newStatus =
        product.status === 'Active' || product.status === 'Inactive' ? product.status : ProductStatus.Active.code;
      const newDescription = state.productDescription ? { description: state.productDescription } : {};

      const productData: UpdateProductPayload = {
        ...product,
        name: newName,
        status: newStatus,
        availability: availability,
        categoryId: categoryId,
        brandId: brandId,
        ...newBarcode,
        ...newDescription,
      };
      setIsLoading(true);

      await meroApi.pro.products.updateProduct({
        pageId: pageState.page.details._id,
        product: productData,
        productId: product._id,
      });

      updateProductDetails({
        name: newName,
        measure: newMeasure,
        ...newBarcode,
      });

      goBack();
      reload();
      toast.show({ text: t(!product ? 'addProductSuccess' : 'modifyProductSuccess'), type: 'success' });
    } catch (error) {
      showError(error);
    }
    setIsLoading(false);
  };

  const createProduct = async () => {
    if (pageState.type !== 'Loaded' || state.type !== 'Loaded') {
      return;
    }

    if (
      !measureUnit ||
      !availability ||
      E.isLeft(name.decoded) ||
      E.isLeft(finalPrice.decoded) ||
      E.isLeft(initialPrice.decoded)
    ) {
      scrollTo();
      return setShowErrors(true);
    }

    const unit = ProductMeasure.Unit.unsafeFrom(measureUnit);
    const unitSpec = state.unitsOfMeasurement.find((u) => u.unit === unit);

    if (!unitSpec) {
      return setShowErrors(true);
    }

    const value =
      measureValue.input === '' && unitSpec.implicitValue
        ? PositiveScaledNumber.fromNumber(unitSpec.implicitValue, unitSpec.exponent)
        : E.isRight(measureValue.decoded)
        ? pipe(
            ScaledNumber.fromNumber(localeStringToNumber(measureValue.input), unitSpec.exponent),
            PositiveScaledNumber.JSON.decode,
            E.getOrElseW(() => undefined),
          )
        : undefined;

    if (!ScaledNumber.Positive.JSON.is(value)) {
      return setShowErrors(true);
    }

    let finalBarcode: Barcode.Any | undefined;
    if (E.isRight(barcode.decoded)) {
      if (Barcode.EANString.JSON.is(barcode.decoded.right))
        finalBarcode = {
          type: 'EAN',
          value: barcode.decoded.right,
        };
      else if (Barcode.UPCString.JSON.is(barcode.decoded.right)) {
        finalBarcode = {
          type: 'UPC',
          value: barcode.decoded.right,
        };
      } else if (Barcode.Code39String.JSON.is(barcode.decoded.right)) {
        finalBarcode = {
          type: 'Code39',
          value: barcode.decoded.right,
        };
      } else if (Barcode.Code128String.JSON.is(barcode.decoded.right)) {
        finalBarcode = {
          type: 'Code128',
          value: barcode.decoded.right,
        };
      }
    }

    const scaledFinalPrice = ScaledNumber.fromStr(localeStringToNumber(finalPrice.input).toString());

    if (E.isLeft(barcode.decoded) || !finalBarcode || E.isLeft(scaledFinalPrice)) {
      return setShowErrors(true);
    }

    if (showDiscountPrice && (E.isLeft(discountPrice.decoded) || !isDiscountLessThanFinalPrice)) {
      return setShowErrors(true);
    }

    const discount = ScaledNumber.fromStr(localeStringToNumber(discountPrice.input).toString());

    try {
      const newMeasure: ProductMeasure.Any = {
        unit,
        value,
        exponent: unitSpec.exponent,
      };

      const newBarcode =
        barcode.input !== ''
          ? {
              barcode: finalBarcode,
            }
          : {};

      const newName = name.decoded.right;
      const newStatus = ProductStatus.Active.code;
      const newDescription = state.productDescription ? { description: state.productDescription } : {};
      const newPrice = {
        retailPrice: {
          amount: scaledFinalPrice.right,
          unit: MeroUnits.RON.code,
        },
        discountedPrice: {
          amount: E.isRight(discount) ? discount.right : scaledFinalPrice.right,
          unit: MeroUnits.RON.code,
        },
        vatRate: PortionPercentScaledNumber.unsafeFromNumber(vatRate, 2),
      };

      const productData: CreateProductPayload = {
        name: newName,
        status: newStatus,
        availability: availability,
        measure: newMeasure,
        categoryId: categoryId,
        brandId: brandId,
        price: newPrice,
        ...newBarcode,
        ...newDescription,
      };

      setIsLoading(true);

      await meroApi.pro.products.createProduct({
        pageId: pageState.page.details._id,
        product: productData,
      });

      goBack();
      reload();
      toast.show({ text: t(!product ? 'addProductSuccess' : 'modifyProductSuccess'), type: 'success' });
    } catch (error) {
      showError(error);
    }
    setIsLoading(false);
  };

  const navigateProductDescriptionCallback = React.useCallback(
    (productId?: ProductId) => {
      navigation.navigate('ProductDescriptionScreen', { productId });
    },
    [navigation],
  );

  const navigateProductGalleryCallback = React.useCallback(
    (productId: ProductId) => {
      navigation.navigate('ProductEdit', { screen: 'ProductGalleryScreen', params: { productId } });
    },
    [navigation],
  );

  const navigateBrandCallback = React.useCallback(() => {
    navigation.navigate('ProductBrandScreen', {});
  }, [navigation]);

  const navigateCategoryCallback = React.useCallback(() => {
    navigation.navigate('ProductCategoryScreen', {});
  }, [navigation]);

  if (state.type !== 'Loaded') {
    return;
  }

  return (
    <ModalScreenContainer edges={['top', 'bottom']} style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader
        title={product ? t('editProduct') : t('addProduct')}
        canGoBack={true}
        onBack={goBack}
        canClose={false}
      />

      <ScrollView ref={scrollRef} contentContainerStyle={{ paddingHorizontal: 16 }}>
        <Spacer size="16" />
        <H1 style={{ paddingHorizontal: 8 }}>{product ? t('editProduct') : t('addProduct')}</H1>

        <Spacer size="32" />
        <FormCard dropShaddow rounded paddings="inputs">
          <InputWithLabel
            label={t('productName')}
            isError={showErrors && !isNameValid}
            errorText={t('productNameError')}
          >
            <TypeSafeTextInput
              value={name.input}
              codec={DefinedTrimedString}
              onChange={setName}
              editable={true}
              showError={showErrors && !isNameValid}
              placeholder={t('productNamePlaceholder')}
            />
          </InputWithLabel>

          {/* Uncomment when consumption is available */}
          {/* <Spacer size="24" /> */}
          {/* <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={() => setAvailability(ProductAvailability.OfflineRetail.value)}
          >
            <Checkbox
              value={
                JSON.stringify(availability) === JSON.stringify(ProductAvailability.OfflineRetail.value) ||
                JSON.stringify(availability) === JSON.stringify(ProductAvailability.OnlineRetail.value)
              }
              onValueChange={() => setAvailability(ProductAvailability.OfflineRetail.value)}
            />
            <Column style={{ paddingLeft: 18 }}>
              <Body style={[meroStyles.text.semibold, { fontSize: 16 }]}>{t('consumable')}</Body>
              <Body style={[meroStyles.text.hint]}>
                {t('consumableExplanation')}
              </Body>
            </Column>
          </TouchableOpacity>

          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={() => setAvailability(ProductAvailability.Consumable.value)}
          >
            <Checkbox
              value={JSON.stringify(availability) === JSON.stringify(ProductAvailability.Consumable.value)}
              onValueChange={() => setAvailability(ProductAvailability.Consumable.value)}
            />
            <Column style={{ paddingLeft: 18 }}>
              <Body style={[meroStyles.text.semibold, { fontSize: 17 }]}>{t('retailOffline')}</Body>
              <Body style={[meroStyles.text.hint]}>{t('retailOfflineExplanation')}</Body>
            </Column>
          </TouchableOpacity> */}

          {showErrors && !availability && (
            <SmallBody style={{ color: colors.RADICAL_RED, marginTop: 8 }}>
              {t('chooseProductAvailabilityError')}
            </SmallBody>
          )}

          <Spacer size="24" />
          <InputWithLabel
            label={t('productBarcode')}
            isError={showErrors && !isBarcodeValid}
            errorText={t('productBarcodeError')}
          >
            <TypeSafeTextInput
              value={barcode.input}
              codec={Barcode.BarcodeValue.JSON}
              onChange={setBarcode}
              editable={true}
              showError={showErrors && !isBarcodeValid}
              placeholder={t('productBarcodePlaceholder')}
            />
          </InputWithLabel>

          <Spacer size={24} />
          <Row style={{ justifyContent: 'space-between' }}>
            <Body style={[meroStyles.text.semibold]}>{t('productCategory')}</Body>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={navigateCategoryCallback}>
              <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('addCategory')}</Body>
              <View
                style={{
                  paddingLeft: 8,
                  justifyContent: 'center',
                }}
              ></View>
            </TouchableOpacity>
          </Row>
          <Spacer size={8} />
          <Select
            placeholder={t('productCategoryPlaceholder')}
            items={categories ?? []}
            value={categoryId}
            onChange={(v) => setCategoryId(v)}
          />

          <Spacer size={24} />
          <Row style={{ justifyContent: 'space-between' }}>
            <Body style={[meroStyles.text.semibold]}>{t('productBrand')}</Body>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={navigateBrandCallback}>
              <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('addBrand')}</Body>
              <View
                style={{
                  paddingLeft: 8,
                  justifyContent: 'center',
                }}
              ></View>
            </TouchableOpacity>
          </Row>
          <Spacer size={8} />
          <Select
            placeholder={t('productBrandPlaceholder')}
            items={brands ?? []}
            value={brandId}
            onChange={(v) => setBrandId(v)}
          />
        </FormCard>

        <Spacer size="32" />
        <FormCard dropShaddow rounded paddings="inputs">
          <InputWithLabel
            label={t('measureUnit')}
            isError={showErrors && !measureUnit}
            errorText={t('chooseMeasureUnitError')}
          >
            <Select
              placeholder={t('measureUnitPlaceholder')}
              items={unitsOfMeasurement ?? []}
              value={measureUnit}
              isError={showErrors && !measureUnit}
              onChange={(v) => setMeasureUnit(v)}
              editable={!route.params?.productId}
            />
          </InputWithLabel>

          {measureUnit !== 'buc' && (
            <>
              <Spacer size={24} />
              <InputWithLabel
                label={t('measureValue')}
                isError={showErrors && !isMeasureValueValid}
                errorText={t('priceError')}
              >
                <TypeSafeTextInput
                  value={measureValue.input}
                  codec={PositiveNumberFromString}
                  onChange={flow(numberValidator(measureValue.input), setMeasureValue)}
                  placeholder="Ex: 100"
                  keyboardType="numeric"
                  showError={showErrors && !isMeasureValueValid}
                  onFocus={() => {
                    setMeasureValue({
                      input: stripLocalThousandsSeparators(measureValue.input),
                      decoded: PositiveNumberFromString.decode(stripLocalThousandsSeparators(measureValue.input)),
                    });
                  }}
                  onBlur={() => {
                    setMeasureValue({
                      input: measureValue.input
                        ? scaledToString(ScaledNumber.fromNumber(localeStringToNumber(measureValue.input), 2))
                        : '',
                      decoded: PositiveNumberFromString.decode(stripLocalThousandsSeparators(measureValue.input)),
                    });
                  }}
                  editable={!route.params?.productId}
                />
              </InputWithLabel>
            </>
          )}
        </FormCard>

        <Spacer />
        {!product && (
          <ProductPriceCard
            finalPrice={finalPrice}
            setFinalPrice={setFinalPrice}
            initialPrice={initialPrice}
            setInitialPrice={setInitialPrice}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            showDiscountPrice={showDiscountPrice}
            setShowDiscountPrice={setShowDiscountPrice}
            discountPrice={discountPrice}
            setDiscountPrice={setDiscountPrice}
            vatRate={vatRate}
            setVatRate={setVatRate}
          />
        )}
        <Spacer size={24} />
        <FormCard dropShaddow paddings="none" rounded style={{ paddingHorizontal: 16 }}>
          <MenuItem
            title={t('productDescription')}
            icon={<TextToolItem />}
            onPress={() => navigateProductDescriptionCallback(route.params?.productId)}
          />
          <Line />
          {product && (
            <MenuItem
              title={t('productGallery')}
              icon="pro-menu-photo-gallery"
              onPress={() => navigateProductGalleryCallback(product._id)}
            />
          )}
        </FormCard>
        <Spacer size={163} />
      </ScrollView>

      {showSubmitButton && (
        <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }]}>
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button
                text={t('saveChanges')}
                onPress={product ? updateProduct : createProduct}
                disabled={isLoading}
                RightComponent={() =>
                  isLoading ? (
                    <ActivityIndicator color={colors.COMET} style={{ marginHorizontal: 8 }} />
                  ) : (
                    <HSpacer left={32} />
                  )
                }
              />
            ) : (
              <Button
                text={t('saveChanges')}
                onPress={product ? updateProduct : createProduct}
                disabled={isLoading}
                RightComponent={() =>
                  isLoading ? (
                    <ActivityIndicator color={colors.COMET} style={{ marginHorizontal: 8 }} />
                  ) : (
                    <HSpacer left={32} />
                  )
                }
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
              />
            )}
          </SafeAreaView>
        </FormCard>
      )}
    </ModalScreenContainer>
  );
};

export default ProductScreen;
