import { HasId } from '@mero/api-sdk';
import { CalendarId } from '@mero/api-sdk/dist/calendar';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ViewStyle } from 'react-native';

import { LocalDateObject } from '../../../contexts/CalendarContext';
import { NormalizedEvent } from '../NormalizedEvent';
import { AddEventIntentParams } from '../index';
import CalendarBody from './CalendarBody';
import CalendarBodySplitView from './CalendarBodySplitView';
import { CalendarWorkerPreview } from './CalendarBodySplitView/calendarWorkerPreview';
import { MIN_HEIGHT } from './styles';
import { ActiveHours, HorizontalDirection, Mode } from './types';
import { getDatesInNextOneDay, getDatesInWeek, modeToNum } from './utils';

interface CalendarProps {
  readonly events: Record<CalendarId, Record<string, NormalizedEvent[] | undefined>>;
  readonly height: number;
  readonly ampm?: boolean;
  readonly selectedDate: LocalDateObject;
  readonly currentDate: LocalDateObject;
  readonly selectedWorkers: CalendarWorkerPreview[];
  readonly selectedTimezone: string;
  readonly locale?: string;
  readonly hideNowIndicator?: boolean;
  readonly mode?: Mode;
  readonly scrollOffsetMinutes?: number;
  readonly showTime?: boolean;
  readonly style?: ViewStyle;
  readonly swipeEnabled?: boolean;
  readonly weekStartsOn?: 0 | 1;
  readonly isRTL?: boolean;
  readonly onScroll?: () => void;
  readonly onChangeDate?: ([start, end]: [LocalDateObject, LocalDateObject]) => void;
  readonly onPressCell?: (date: Date, worker: HasId<WorkerId> | undefined) => void;
  readonly onPressEvent?: (event: NormalizedEvent) => void;
  readonly activeHours: ActiveHours;
  readonly addEventIntent?: AddEventIntentParams;
  readonly dayHours: number[];
  readonly onAddBooking?: () => void;
  readonly onAddBlockedTime?: () => void;
  readonly onAddCheckout?: () => void;
}

const BigCalendar: React.FC<CalendarProps> = ({
  events,
  height,
  ampm = false,
  selectedDate,
  currentDate,
  selectedWorkers,
  selectedTimezone,
  hideNowIndicator = false,
  mode = 'week',
  scrollOffsetMinutes = 0,
  showTime = true,
  style = {},
  swipeEnabled = true,
  isRTL = false,
  onScroll,
  onChangeDate,
  onPressCell,
  onPressEvent,
  activeHours,
  addEventIntent,
  dayHours,
  onAddBooking,
  onAddBlockedTime,
  onAddCheckout,
}) => {
  type EventsMap = Record<CalendarId, Record<string, NormalizedEvent[] | undefined>>;

  const daytimeEvents: EventsMap = React.useMemo(
    () =>
      selectedWorkers.reduce((acc1: EventsMap, selectedWorker) => {
        acc1[selectedWorker.calendar._id] = events[selectedWorker.calendar._id] ?? {};
        return acc1;
      }, {}),
    [selectedWorkers, events],
  );

  const dateRange = React.useMemo((): LocalDateObject[] => {
    switch (mode) {
      case 'week':
        return getDatesInWeek({ date: selectedDate });
      case 'day':
        return getDatesInNextOneDay(selectedDate);
      default:
        throw new Error('undefined mode');
    }
  }, [mode, selectedDate]);

  const changeDate = (date: DateTime) => {
    if (onChangeDate) {
      onChangeDate([
        LocalDateObject.unsafeFromPartial(date.toObject()),
        LocalDateObject.unsafeFromPartial(date.toObject()),
      ]);
    }
  };

  const calculateInterval = React.useMemo(() => (dayHours.at(-1) ?? 24) - (dayHours.at(0) ?? 0), [dayHours]);
  const hourHeight = React.useMemo(() => Math.max(height - 30, MIN_HEIGHT) / calculateInterval, [height]);

  const onSwipeHorizontal = React.useCallback(
    (direction: HorizontalDirection) => {
      if (!swipeEnabled) {
        return;
      }
      if ((direction === 'LEFT' && !isRTL) || (direction === 'RIGHT' && isRTL)) {
        changeDate(DateTime.fromObject(selectedDate, { zone: 'UTC' }).plus({ days: modeToNum(mode) }));
      } else {
        changeDate(DateTime.fromObject(selectedDate, { zone: 'UTC' }).minus({ days: modeToNum(mode) }));
      }
    },
    [swipeEnabled, selectedDate],
  );

  const commonProps = {
    currentDate,
    hourHeight,
    dateRange,
    style,
    isRTL,
    activeHours,
  };

  return (
    <>
      {mode === 'day' ? (
        <CalendarBodySplitView
          {...commonProps}
          selectedDate={selectedDate}
          selectedWorkers={selectedWorkers}
          selectedTimezone={selectedTimezone}
          containerHeight={height}
          normalizedEvents={daytimeEvents}
          hideNowIndicator={hideNowIndicator}
          scrollOffsetMinutes={scrollOffsetMinutes}
          onScroll={onScroll}
          ampm={ampm}
          showTime={showTime}
          onPressCell={onPressCell}
          onPressEvent={onPressEvent}
          onSwipeHorizontal={onSwipeHorizontal}
          addEventIntent={addEventIntent}
          dayHours={dayHours}
          onAddBooking={onAddBooking}
          onAddBlockedTime={onAddBlockedTime}
          onAddCheckout={onAddCheckout}
        />
      ) : (
        <CalendarBody
          {...commonProps}
          containerHeight={height}
          normalizedEvents={daytimeEvents}
          hideNowIndicator={hideNowIndicator}
          scrollOffsetMinutes={scrollOffsetMinutes}
          onScroll={onScroll}
          ampm={ampm}
          showTime={showTime}
          onPressCell={onPressCell}
          onPressEvent={onPressEvent}
          onSwipeHorizontal={onSwipeHorizontal}
          addEventIntent={addEventIntent}
          dayHours={dayHours}
          onAddBooking={onAddBooking}
          onAddBlockedTime={onAddBlockedTime}
          onAddCheckout={onAddCheckout}
        />
      )}
    </>
  );
};

export default BigCalendar;
