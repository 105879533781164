import { AvoidKeyboard, colors, H1, Row, SafeAreaView, styles as meroStyles, Switch, Title } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import ActiveIntervalView, { getMonthIntervals } from '../MenuScreen/screens/PageReportsScreen/ActiveIntervalView';
import { Interval } from '../MenuScreen/screens/PageReportsScreen/PageReportsScreen';

import Column from '@mero/components/lib/components/Layout/Column';
import Spacer from '@mero/components/lib/components/Spacer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CheckoutDraftsCountContext } from '../../../contexts/CheckoutDraftsCountContext';
import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import AddProceedMenu from './AddProceedMenu';
import CheckoutTabMobile from './CheckoutTabMobile';
import CheckoutTabWeb from './CheckoutTabWeb';
import MembershipsTabMobile from './MembershipsTabMobile';
import MembershipsTabWeb from './MembershipsTabWeb';
import ProductsTabMobile from './ProductsTabMobile';
import ProductsTabWeb from './ProductsTabWeb';

export const ProceedIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6972">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2671" />
    </G>
    <G fill="none" data-name="Group 7500">
      <G
        stroke={props.color ?? '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.3}
      >
        <Path d="M7.813 12.748h4.789m-4.789 2.395h3.59m3.593-2.4h1.2m-1.2 2.4h1.2M4.826 4.5v15.3l1.79-1.193 1.815 1.2 1.789-1.2 1.8 1.193 1.789-1.193 1.8 1.193 1.79-1.193 1.795 1.193V4.5m-3.585 15.3h0Zm-3.585 0h0ZM3.75 4.5h16.5" />
        <Path d="m10.3 8.762 1.226 1.226 2.18-2.179" data-name="layer1" />
      </G>
      <Path d="M3 3h18v18H3z" data-name="Rectangle 2701" />
    </G>
  </Svg>
);

const TabsOptions = [
  {
    label: 'checkout',
    value: 'checkout',
  },
  {
    label: 'memberships',
    value: 'memberships',
  },
  {
    label: 'products',
    value: 'products',
  },
] as const;

type TabsOptionsType = (typeof TabsOptions)[number]['value'];

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutTabStackParamList & AuthorizedStackParamList, 'CheckoutTabScreen'>;

const CheckoutScreen: React.FC<Props> = ({ navigation, page, route }) => {
  const { t } = useTranslation('checkout');

  const tab = route.params?.tab;

  const [checkoutsState, { reload, updateInterval, loadMoreFinished }] = CheckoutsContext.useContext();
  const [, { reload: reloadCount }] = CheckoutDraftsCountContext.useContext();

  const isFocused = useIsFocused();
  const { isPhone } = useMediaQueries();

  const [selectedTab, setSelectedTab] = React.useState<TabsOptionsType>(tab ?? TabsOptions[0].value);

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);
  const [activeInterval, setActiveInterval] = React.useState<Interval>(getMonthIntervals()[0]);

  React.useEffect(() => {
    if (isFocused) {
      reload(page.details._id);
      reloadCount(page.details._id);
    }
  }, [isFocused]);

  React.useEffect(() => {
    if (activeInterval) {
      updateInterval(
        {
          from: activeInterval.value.start.toJSDate(),
          to: activeInterval.value.end.toJSDate(),
        },
        page.details._id,
      );
    }
  }, [activeInterval]);

  const getActiveTabContent = React.useCallback((tab: TabsOptionsType) => {
    if (checkoutsState.type !== 'Loaded') {
      return null;
    }
    if (isPhone) {
      switch (tab) {
        case 'checkout':
          return <CheckoutTabMobile interval={checkoutsState.interval} />;
        case 'memberships':
          return <MembershipsTabMobile interval={checkoutsState.interval} />;
        case 'products':
          return <ProductsTabMobile interval={checkoutsState.interval} />;
      }
    }
    switch (tab) {
      case 'checkout':
        return <CheckoutTabWeb interval={checkoutsState.interval} />;
      case 'memberships':
        return <MembershipsTabWeb interval={checkoutsState.interval} />;
      case 'products':
        return <ProductsTabWeb interval={checkoutsState.interval} />;
    }
  }, []);

  const navigateCashRegistryCallback = React.useCallback(() => {
    navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: {
        screen: 'CashRegistryScreen',
      },
    });
  }, [navigation]);

  if (checkoutsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      {isPhone ? (
        <>
          <AvoidKeyboard style={{ flex: 1, backgroundColor: colors.WHITE }}>
            <Column
              style={{
                backgroundColor: colors.WHITE,
                shadowColor: colors.BLACK,
                shadowOffset: {
                  width: 0,
                  height: 5,
                },
                shadowOpacity: 0.08,
                shadowRadius: 16,
                elevation: 5,
              }}
            >
              <SafeAreaView />
              {(Platform.OS === 'web' || Platform.OS === 'android') && <Spacer size={16} />}
              <Column style={[{ paddingHorizontal: 16 }]}>
                <Switch
                  height={32}
                  textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                  buttons={TabsOptions.map((o) => ({ label: t(o.label), value: o.value }))}
                  defaultValue={selectedTab}
                  onChange={(v) => {
                    navigation.setParams({
                      tab: v as TabsOptionsType,
                    });
                    setSelectedTab(v as TabsOptionsType);
                  }}
                />
              </Column>
              <Spacer size={16} />
              <Row style={{ paddingHorizontal: 16 }}>
                <ActiveIntervalView
                  withFullCurrentMonth
                  activeInterval={activeInterval}
                  setActiveInterval={setActiveInterval}
                  showFutureDates={true}
                  fullWidth
                />
              </Row>
              <Spacer size={16} />
            </Column>
            {getActiveTabContent(selectedTab)}
          </AvoidKeyboard>
        </>
      ) : (
        <>
          <Row
            style={{
              height: 91,
              backgroundColor: colors.WHITE,
              shadowColor: colors.BLACK,
              shadowOffset: { width: 1, height: 1 },
              shadowOpacity: 0.16,
              shadowRadius: 16,
              elevation: 16,
              zIndex: 1,
            }}
            alignItems="center"
            justifyContent="space-between"
          >
            <H1 style={{ paddingHorizontal: 24 }}>{t('sales')}</H1>
            <Row style={{ paddingRight: 24 }}>
              <ActiveIntervalView
                withFullCurrentMonth
                activeInterval={activeInterval}
                setActiveInterval={setActiveInterval}
                showFutureDates={true}
              />
            </Row>
          </Row>
          <Row
            alignItems="center"
            justifyContent="space-between"
            style={{ paddingTop: 24, paddingHorizontal: 24, paddingBottom: 16 }}
          >
            <Column style={{ flex: 1, maxWidth: 380 }}>
              <Switch
                height={32}
                textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                buttons={TabsOptions.map((o) => ({ label: t(o.label), value: o.value }))}
                defaultValue={selectedTab}
                onChange={(v) => {
                  navigation.setParams({
                    tab: v as TabsOptionsType,
                  });
                  setSelectedTab(v as TabsOptionsType);
                }}
              />
            </Column>
            {page.details.isCashRegistryEnabled && (
              <TouchableOpacity onPress={navigateCashRegistryCallback}>
                <Title style={{ color: colors.DARK_BLUE, paddingLeft: 24 }} numberOfLines={1}>
                  {t('cashRegistry:title')}
                </Title>
              </TouchableOpacity>
            )}
          </Row>
          {getActiveTabContent(selectedTab)}
        </>
      )}
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) =>
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type } },
              }),
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default pipe(CheckoutScreen, CurrentBusiness, Authorized);
