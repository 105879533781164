import { Portal } from '@gorhom/portal';
import { WorkingScheduleDay, WorkScheduleInterval } from '@mero/api-sdk';
import {
  Button,
  Checkbox,
  colors,
  HSpacer,
  Label,
  Row,
  Select,
  styles as meroStyles,
  Title,
  Line,
  SmallBody,
  Column,
} from '@mero/components';
import { DayTime, Hour, Minute } from '@mero/shared-sdk';
import * as Nea from 'fp-ts/NonEmptyArray';
import { flow } from 'fp-ts/lib/function';
import { cloneDeep, isUndefined } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

import Spacer from '@mero/components/lib/components/Spacer';

import { adjustIntervals, dayTimeSum, getIntervals, getOverlaps, intervalDiff } from '../../../../../../utils/time';
import CopyDayModal from './CopyDayModal';
import { DEFAULT_DAY_TIME } from './ScheduleWeb';

const DeleteIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="Group 8855" {...props}>
    <Path fill="none" d="M0 0h32v32H0z" />
    <Path
      fill="#454f63"
      d="m8.5 22.24 6.16-6.158-6.163-6.163a1 1 0 0 1 0-1.415l.007-.007a1 1 0 0 1 1.415 0l6.163 6.163 6.162-6.163a1.005 1.005 0 0 1 1.421 1.422l-6.162 6.162 6.163 6.163a1 1 0 0 1 0 1.414l-.008.008a1 1 0 0 1-1.414 0l-6.163-6.163-6.159 6.159a1.005 1.005 0 1 1-1.421-1.421Z"
    />
  </Svg>
);

const startIntervals = getIntervals({ hour: 0, minute: 0 } as DayTime, { hour: 23, minute: 55 } as DayTime);
const endIntervals = getIntervals({ hour: 0, minute: 15 } as DayTime, { hour: 24, minute: 0 } as DayTime);

type PartialWorkingScheduleDay = Pick<WorkingScheduleDay, 'active'> & { intervals: Partial<WorkScheduleInterval>[] };

type Props = {
  days: PartialWorkingScheduleDay[];
  updateDays: (days: WorkingScheduleDay[]) => void;
  disabled?: boolean;
};

export const DayLabels = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'];

const WeekView: React.FC<Props> = ({ days, updateDays, disabled = false }) => {
  const { t } = useTranslation('pros');

  const [daysSchedule, setDaysSchedule] = React.useState(days);
  const [copyDayIndex, setCopyDayIndex] = React.useState<number>();
  const [showCopyModal, setShowCopyModal] = React.useState(false);

  const setTime = (dayIndex: number, intervalIndex: number, type: 'from' | 'to') => (value: string) => {
    const [hour, minute] = value.split(':').map(Number) as [Hour, Minute];
    const newIntervals = daysSchedule.map((day, i) => {
      if (i === dayIndex) {
        return {
          ...day,
          intervals: day.intervals.map((interval, j) => {
            if (j === intervalIndex) {
              return adjustIntervals({
                from: type === 'from' ? { hour, minute } : interval.from,
                to: type === 'to' ? { hour, minute } : interval.to,
                priority: type,
              });
            }
            return interval;
          }),
        };
      }
      return day;
    });

    setDaysSchedule(newIntervals);

    return newIntervals;
  };

  const removeInterval = (dayIndex: number, intervalIndex: number) => () => {
    const newIntervals = daysSchedule.map((day, i) => {
      if (i === dayIndex) {
        return {
          ...day,
          intervals: day.intervals.filter((_, j) => j !== intervalIndex),
        };
      }
      return day;
    });

    setDaysSchedule(newIntervals);

    return newIntervals;
  };

  const toggleDay = (dayIndex: number) => () => {
    const newIntervals = daysSchedule.map((day, i) => {
      if (i === dayIndex) {
        return {
          ...day,
          active: !day.active,
        };
      }
      return day;
    });

    setDaysSchedule(newIntervals);

    return newIntervals;
  };

  const addNewInterval = (dayIndex: number) => () => {
    const newIntervals = daysSchedule.map((day, i) => {
      if (i === dayIndex) {
        return {
          ...day,
          intervals: [...day.intervals, {}],
        };
      }
      return day;
    });

    setDaysSchedule(newIntervals);

    return newIntervals;
  };

  const showCopyDay = (dayIndex: number) => () => {
    setCopyDayIndex(dayIndex);
    setShowCopyModal(true);
  };

  const hideShowCopy = () => {
    setShowCopyModal(false);
    setCopyDayIndex(undefined);
  };

  const copyDay = (dayIndex: number) => {
    return (selectedDays: Record<number, boolean>) => {
      const newIntervals = daysSchedule.map((day, i) => {
        if (selectedDays[i]) {
          return cloneDeep(daysSchedule[dayIndex]);
        }
        return day;
      });

      setDaysSchedule(newIntervals);

      return newIntervals;
    };
  };

  const update = (days: PartialWorkingScheduleDay[]) => {
    updateDays(
      days.map((day) => {
        if (day.active) {
          const intervals = day.intervals.filter(WorkScheduleInterval.JSON.is);
          if (intervals.length) {
            return {
              active: true,
              intervals: intervals as Nea.NonEmptyArray<WorkScheduleInterval>,
            };
          }

          return {
            active: false,
          };
        }

        return {
          active: false,
        };
      }),
    );
  };

  return (
    <>
      <Spacer size={8} />
      {daysSchedule.map((day, dayIndex) => {
        const total = day.active
          ? day.intervals
              .filter((v) => WorkScheduleInterval.JSON.is(v))
              .reduce((acc, interval) => dayTimeSum(acc, intervalDiff(interval)), DEFAULT_DAY_TIME)
          : DEFAULT_DAY_TIME;

        const overlaps = React.useMemo(
          () => (day.active ? getOverlaps(day.intervals) : []),
          [day.active && day.intervals],
        );

        return (
          <React.Fragment key={dayIndex}>
            <Row alignItems="center" flex={1}>
              <TouchableOpacity
                style={{ flexDirection: 'row', flex: 1 }}
                onPress={flow(toggleDay(dayIndex), update)}
                disabled={disabled}
              >
                <Checkbox value={day.active} onValueChange={flow(toggleDay(dayIndex), update)} disabled={disabled} />
                <Title style={{ paddingLeft: 12 }}>
                  {DayLabels[dayIndex]}{' '}
                  {day.active
                    ? ` (${t(total.minute ? 'dayTimeWithMin' : 'dayTime', {
                        hour: total.hour,
                        minute: total.minute,
                      })})`
                    : ''}
                </Title>
              </TouchableOpacity>
              {day.active && !disabled ? (
                <Column>
                  <Button
                    expand={false}
                    size="small"
                    text={t('copyDay')}
                    color={colors.DARK_BLUE}
                    backgroundColor={colors.WHITE}
                    onPress={flow(showCopyDay(dayIndex))}
                  />
                </Column>
              ) : (
                <Label style={[meroStyles.text.semibold, { color: colors.COMET }]}>{t('noSchedule')}</Label>
              )}
            </Row>
            {day.active && (
              <React.Fragment>
                <Spacer size={8} />
                {day.intervals.map((interval, intervalIndex) => {
                  return (
                    <Column key={intervalIndex}>
                      <Row key={intervalIndex} style={{ paddingTop: 8, alignItems: 'center' }}>
                        <Select
                          items={startIntervals}
                          style={{ flex: 2 }}
                          value={interval.from ? `${interval.from.hour}:${interval.from.minute}` : undefined}
                          placeholder={t('intervalStart')}
                          onChange={flow(setTime(dayIndex, intervalIndex, 'from'), update)}
                          editable={!disabled}
                        />
                        <HSpacer left={8} />
                        <Select
                          items={endIntervals}
                          style={{ flex: 2 }}
                          value={interval.to ? `${interval.to.hour}:${interval.to.minute}` : undefined}
                          placeholder={t('intervalEnd')}
                          onChange={flow(setTime(dayIndex, intervalIndex, 'to'), update)}
                          editable={!disabled}
                        />
                        {day.intervals.length > 1 && !disabled && (
                          <>
                            <HSpacer left={8} />
                            <TouchableOpacity onPress={flow(removeInterval(dayIndex, intervalIndex), update)}>
                              <DeleteIcon />
                            </TouchableOpacity>
                          </>
                        )}
                      </Row>
                      {overlaps[intervalIndex] && (
                        <SmallBody style={{ color: colors.RADICAL_RED }}>{t('overlappingInterval')}</SmallBody>
                      )}
                    </Column>
                  );
                })}
                {!disabled && (
                  <>
                    <Spacer size={16} />
                    <Button
                      size="medium"
                      text={t('addNewIntervalButton')}
                      color={colors.DARK_BLUE}
                      backgroundColor={colors.SKY_BLUE}
                      onPress={flow(addNewInterval(dayIndex), update)}
                    />
                  </>
                )}
              </React.Fragment>
            )}
            <Spacer size={16} />
            {dayIndex < daysSchedule.length - 1 && (
              <>
                <Line />
                <Spacer size={16} />
              </>
            )}
          </React.Fragment>
        );
      })}
      {showCopyModal && !isUndefined(copyDayIndex) && (
        <Portal>
          <CopyDayModal
            day={copyDayIndex}
            onDismiss={hideShowCopy}
            onConfirm={flow(copyDay(copyDayIndex), update, hideShowCopy)}
          />
        </Portal>
      )}
    </>
  );
};

export default WeekView;
