import { PageMemberPreview, SavedWorker, UserId, Worker } from '@mero/api-sdk';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { Avatar, Body, Checkbox, colors, H1, MeroHeader, SmallBody, Title, Line, Button } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import { indexOf, sortBy } from 'lodash';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../../components/ModalScreenContainer';
import FormCard from '@mero/components/lib/components/FormCard';
import Column from '@mero/components/lib/components/Layout/Column';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Spacer from '@mero/components/lib/components/Spacer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../../hooks/useGoBack';

import { CurrentBusiness, CurrentBusinessProps } from '../../../../../../contexts/CurrentBusiness';
import { ScheduleContext } from '../../../../../../contexts/ScheduleContext';
import { ScheduleStackParamList } from '../../../../../../types';
import { nameGenerator } from '../../../../../../utils/string';

export type WorkerAndMember = {
  worker: SavedWorker;
  member: PageMemberPreview;
};

type Props = StackScreenProps<ScheduleStackParamList, 'AddExtraPro'> & CurrentBusinessProps;

const AddExtraProScreen: React.FC<Props> = ({ page, route }) => {
  const { t } = useTranslation('pros');

  const goBack = useGoBack();

  const [scheduleState, { addExtraPro }] = ScheduleContext.useContext();

  const { workerId } = route.params;

  const allPros: WorkerAndMember[] = React.useMemo(() => {
    const workersByUserId: Record<UserId, SavedWorker> = page.workers.reduce(
      (acc: Record<UserId, SavedWorker>, worker) => ({ ...acc, [worker.user._id]: worker }),
      {},
    );

    const workersIds = page.workers.map((worker) => worker._id);

    const workers = page.members
      .filter((member) => workersByUserId[member.user._id])
      .map((member) => ({
        worker: workersByUserId[member.user._id],
        member,
      }))
      .filter((member) => member.worker);

    return sortBy(workers, (item) => indexOf(workersIds, item.worker._id)).filter(
      (w) => !Worker.isInvited(w.worker, page.details),
    );
  }, [page.details._id, page.members, page.workers]);

  const pros = React.useMemo(() => allPros.filter((w) => w.worker._id !== workerId), [allPros, workerId]);

  const togglePro = (proId: WorkerId) => {
    const isSelected = scheduleState.selectedExtraPros.includes(proId);

    if (isSelected) {
      addExtraPro(scheduleState.selectedExtraPros.filter((id) => id !== proId));
    } else {
      addExtraPro([...scheduleState.selectedExtraPros, proId]);
    }
  };

  const toggleAll = () => {
    if (scheduleState.selectedExtraPros.length === pros.length) {
      addExtraPro([]);
    } else {
      addExtraPro(pros.filter((pro) => pro.worker._id !== workerId).map((pro) => pro.worker._id));
    }
  };

  const selectedPro = React.useMemo(() => allPros.find((w) => w.worker._id === workerId), [pros, workerId]);

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']}>
      <MeroHeader title={t('selectPros')} canGoBack onBack={goBack} />
      <ScrollView style={{ paddingHorizontal: 24 }}>
        <Spacer size={16} />
        <H1>{t('selectPros')}</H1>
        <Spacer size={8} />
        <Body>
          <Trans
            ns={'pros'}
            t={t}
            i18nKey="scheduleExtraPros"
            values={{ name: nameGenerator(selectedPro?.member.user.profile ?? {}, t('noName')) }}
          >
            0<Title style={{ fontFamily: 'open-sans-semibold' }}>1</Title>2
          </Trans>
        </Body>
        <Spacer size={24} />
        <FormCard rounded dropShaddow paddings="none" style={{ paddingHorizontal: 16, paddingVertical: 24 }}>
          {pros.length > 1 && (
            <>
              <TouchableOpacity onPress={toggleAll} style={{ flexDirection: 'row' }}>
                <Checkbox value={scheduleState.selectedExtraPros.length === pros.length} onValueChange={toggleAll} />
                <Column style={{ paddingLeft: 12 }}>
                  <Title>{t('selectAll')}</Title>
                  <SmallBody style={{ color: colors.COMET }}>{t('withoutInvited')}</SmallBody>
                </Column>
              </TouchableOpacity>
              <Spacer size={16} />
              <Line />
              <Spacer size={16} />
            </>
          )}
          {pros.map((pro, index) => {
            const isDefault = pro.worker._id === workerId;
            return (
              <React.Fragment key={pro.worker._id}>
                <Spacer size={8} />
                <TouchableOpacity
                  onPress={() => togglePro(pro.worker._id)}
                  style={{ flexDirection: 'row' }}
                  disabled={isDefault}
                >
                  <Checkbox
                    value={isDefault || scheduleState.selectedExtraPros.includes(pro.worker._id)}
                    onValueChange={() => togglePro(pro.worker._id)}
                    disabled={isDefault}
                  />
                  <Column style={{ paddingLeft: 12, flex: 1 }}>
                    <Title>{nameGenerator(pro.member.user.profile, t('noName'))}</Title>
                    <SmallBody style={{ color: colors.COMET }}>{pro.member.roles[0]?.name}</SmallBody>
                  </Column>
                  <Avatar
                    firstname={pro.member.user.profile.firstname ?? ''}
                    lastname={pro.member.user.profile.lastname ?? ''}
                    source={pro.member.user.profile.photo?.thumbnail}
                  />
                </TouchableOpacity>
                {index < pros.length - 1 && (
                  <>
                    <Spacer size={16} />
                    <Line />
                    <Spacer size={16} />
                  </>
                )}
              </React.Fragment>
            );
          })}
        </FormCard>
        <Spacer size={96 + 32} />
      </ScrollView>
      <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }]}>
        <SafeAreaView edges={['bottom']}>
          <Button text={t('done')} onPress={goBack} />
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(AddExtraProScreen, CurrentBusiness);
