import { capitalize, dateStringUtils, Worker } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  colors,
  H3s,
  Icon,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
  Line,
  Label,
} from '@mero/components';
import { DateString } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import Column from '@mero/components/lib/components/Layout/Column';

import { AppContext } from '../../../../../../contexts/AppContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../../contexts/CurrentBusiness';
import { nameGenerator } from '../../../../../../utils/string';
import { dayTimeSum, DEFAULT_TIMEZONE, intervalDiff, intervalToString } from '../../../../../../utils/time';
import AddNew from './AddNew';
import ScheduleButton from './ScheduleButton';
import { DEFAULT_DAY_TIME, ScheduleRecord, WorkerScheduleWithTotal } from './ScheduleMobile';

type Schedule = Extract<ScheduleRecord, { type: 'Schedule' }>;

const DownIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 4275">
      <Path fill="#080de0" d="m6.257 9.407 1.385-1.385 4.489 4.569 4.485-4.569L18 9.407l-5.869 5.954Z" />
      <Path fill="none" d="M24 0v24H0V0z" data-name="Rectangle 2" />
    </G>
  </Svg>
);
type Props = {
  workerSchedule: WorkerScheduleWithTotal;
  refresh: () => void;
  scheduledWeek: DateTime[];
  onAddNewInterval: (payload: { workerId: WorkerId; day: DateString }) => void;
  onEditInterval: (payload: { workerId: WorkerId; day: DateString }) => void;
  onAddNewSchedule: (payload: { workerId: WorkerId; day: DateString }) => void;
  onAddBlockTime: (payload: { workerId: WorkerId; day: DateString }) => void;
  onEditSchedule: (payload: { workerId: WorkerId; day: DateString }) => void;
  onEditBlockTime: (payload: { day: DateString; appointmentId: AppointmentId; occurrenceIndex: number }) => void;
} & CurrentBusinessProps;

const WorkerSchedule: React.FC<Props> = ({
  workerSchedule,
  scheduledWeek,
  onEditSchedule,
  onEditBlockTime,
  onAddBlockTime,
  onAddNewSchedule,
  onEditInterval,
  onAddNewInterval,
  page,
  refresh,
}) => {
  const { t } = useTranslation('pros');

  const [appState] = AppContext.useContext();

  const changePastWorkingSchedule = React.useMemo(
    () => appState.type === 'Loaded' && appState.featureFlags.changePastWorkingSchedule?.enabled,
    [appState],
  );

  const [isExpanded, setIsExpanded] = React.useState(false);
  const { pro, schedule, total } = workerSchedule;

  const now = React.useMemo(() => DateTime.now().setZone(DEFAULT_TIMEZONE).startOf('day'), []);

  const { worker } = pro;

  const isInvited = React.useMemo(() => Worker.isInvited(worker, page.details), [worker, page.details]);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const weekDaysString = React.useMemo(
    () => scheduledWeek.map((date) => dateStringUtils.fromDate(date.toJSDate())),
    [scheduledWeek],
  );

  return (
    <Column>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleExpand}>
        <Avatar
          firstname={worker.user.firstname ?? ''}
          lastname={worker.user.lastname ?? ''}
          source={worker.profilePhoto?.thumbnail}
        />
        <Column style={{ flex: 1, paddingLeft: 16 }}>
          {isInvited && <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{t('invited')}</Label>}
          <H3s>{nameGenerator(worker.user, t('noName'))}</H3s>
          {/*<SmallBody style={{ fontSize: 12 }}>{member.roles[0]?.name}</SmallBody>*/}
          <SmallBody>
            {t(
              total.minute !== 0 || total.hour !== 0
                ? total.minute !== 0
                  ? 'dayTimeWithMin'
                  : 'dayTime'
                : 'noSchedule',
              {
                hour: total.hour,
                minute: total.minute,
              },
            )}
          </SmallBody>
        </Column>
        <Column>{isExpanded ? <Icon type="arrow-up" color={colors.DARK_BLUE} /> : <DownIcon />}</Column>
      </TouchableOpacity>
      {isExpanded && (
        <Column>
          <Spacer size={16} />
          {weekDaysString.map((date, index) => {
            const records = schedule[date] ?? [];
            const hasSchedule = records.some(
              (r) => (r.type === 'Schedule' && r.schedule.active) || r.type === 'BlockedTime',
            );

            const isPast = DateString.toDateTime(date, DEFAULT_TIMEZONE).startOf('day') < now;

            const total = records
              .filter((r): r is Schedule => r.type === 'Schedule' && r.schedule.active)
              .reduce((acc1, record) => {
                if (record.schedule.active) {
                  return dayTimeSum(
                    acc1,
                    record.schedule.intervals.reduce((acc2, interval) => {
                      return dayTimeSum(acc2, intervalDiff(interval));
                    }, DEFAULT_DAY_TIME),
                  );
                }
                return acc1;
              }, DEFAULT_DAY_TIME);

            return (
              <Column key={date}>
                <Row>
                  <Column style={{ flex: 1 }}>
                    <Title>{capitalize(scheduledWeek[index].toFormat('ccc dd MMM', { locale: 'ro' }))}</Title>
                    <SmallBody style={{ color: colors.COMET }}>
                      {hasSchedule
                        ? t(total.minute ? 'dayTimeWithMin' : 'dayTime', {
                            hour: total.hour,
                            minute: total.minute,
                          })
                        : t('noSchedule')}
                    </SmallBody>
                  </Column>
                  <Column style={{ minWidth: 106 }}>
                    {records.map((record, recordIndex) => {
                      switch (record.type) {
                        case 'Schedule':
                          return record.schedule.active
                            ? record.schedule.intervals.map((interval, index) => (
                                <React.Fragment key={`${interval.from.hour}_${index}`}>
                                  {record.schedule.active && index > 0 && <Spacer size={4} />}
                                  <ScheduleButton
                                    page={page.details}
                                    day={date}
                                    pro={pro}
                                    isPast={isPast}
                                    schedule={record}
                                    interval={interval}
                                    onEditInterval={() => {
                                      onEditInterval({ workerId: pro.worker._id, day: date });
                                    }}
                                    onEditSchedule={() =>
                                      onEditSchedule({
                                        workerId: pro.worker._id,
                                        day: date,
                                      })
                                    }
                                    onDeleteSchedule={refresh}
                                  />
                                </React.Fragment>
                              ))
                            : null;
                        case 'BlockedTime':
                          return (
                            <React.Fragment key={record.blockedTime._id}>
                              {recordIndex > 0 && <Spacer size={4} />}
                              <TouchableOpacity
                                key={record.blockedTime._id}
                                style={[styles.blockedTime]}
                                onPress={() =>
                                  onEditBlockTime({
                                    day: date,
                                    appointmentId: record.blockedTime._id,
                                    occurrenceIndex: record.blockedTime.occurrenceIndex ?? 0,
                                  })
                                }
                              >
                                <SmallBody style={[styles.blockedTimeText]}>
                                  {intervalToString(record.blockedTime.interval)}
                                </SmallBody>
                              </TouchableOpacity>
                            </React.Fragment>
                          );
                      }
                    })}
                    {hasSchedule && <Spacer size={4} />}
                    {!isPast || changePastWorkingSchedule ? (
                      <AddNew
                        isHovered={true}
                        isInvited={isInvited}
                        onAddNewInterval={() => onAddNewInterval({ workerId: worker._id, day: date })}
                        onAddNewSchedule={() => onAddNewSchedule({ workerId: worker._id, day: date })}
                        onBlockTime={() => onAddBlockTime({ workerId: worker._id, day: date })}
                      />
                    ) : null}
                  </Column>
                </Row>
                {index < weekDaysString.length - 1 && (
                  <>
                    <Spacer size={16} />
                    <Line />
                    <Spacer size={16} />
                  </>
                )}
              </Column>
            );
          })}
        </Column>
      )}
    </Column>
  );
};

const styles = StyleSheet.create({
  schedule: {
    backgroundColor: colors.SKY_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: '100%',
    borderRadius: 6,
  },
  scheduleText: {
    color: colors.DARK_BLUE,
    fontSize: 14,
    marginHorizontal: 8,
    marginVertical: 4,
    ...meroStyles.text.semibold,
  },
  blockedTime: {
    backgroundColor: '#F8DFD1',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: '100%',
    borderRadius: 6,
  },
  blockedTimeText: {
    color: colors.BLACK,
    fontSize: 14,
    marginHorizontal: 8,
    marginVertical: 4,
    ...meroStyles.text.semibold,
  },
  space: {
    marginTop: 8,
  },
});

export default pipe(WorkerSchedule, CurrentBusiness);
