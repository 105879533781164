import { SavedWorker, UserId, Worker, WorkerId } from '@mero/api-sdk';
import { Body, ConfirmBox, H1, ModalOverlay, Spacer, styles as meroStyles } from '@mero/components';
import { pipe } from 'fp-ts/function';
import { indexOf, sortBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CurrentBusiness, CurrentBusinessProps } from '../../../../../../contexts/CurrentBusiness';
import { ScheduleContext } from '../../../../../../contexts/ScheduleContext';
import { nameGenerator } from '../../../../../../utils/string';
import { WorkerAndMember } from './AddExtraProScreen';

type Props = {
  readonly workerId: WorkerId;
  readonly onConfirm: () => void;
  readonly onDismiss: () => void;
} & CurrentBusinessProps;

const ConfirmOverlapModal: React.FC<Props> = ({ page, workerId, onConfirm, onDismiss }) => {
  const { t } = useTranslation('pros');

  const [scheduleState] = ScheduleContext.useContext();

  const pros: WorkerAndMember[] = React.useMemo(() => {
    const workersByUserId: Record<UserId, SavedWorker> = page.workers.reduce(
      (acc: Record<UserId, SavedWorker>, worker) => ({ ...acc, [worker.user._id]: worker }),
      {},
    );

    const workersIds = page.workers.map((worker) => worker._id);

    const workers = page.members
      .filter((member) => workersByUserId[member.user._id])
      .map((member) => ({
        worker: workersByUserId[member.user._id],
        member,
      }))
      .filter((member) => member.worker);

    return sortBy(workers, (item) => indexOf(workersIds, item.worker._id)).filter(
      (w) => w.worker._id === workerId || scheduleState.selectedExtraPros.includes(w.worker._id),
    );
  }, [page.details._id, page.members, page.workers, scheduleState.selectedExtraPros]);

  const leftAction = {
    text: t('cancelButton'),
    onPress: onDismiss,
    flex: 10,
  };

  const rightAction = {
    text: t('confirmDeleteButton'),
    onPress: onConfirm,
    flex: 15,
  };

  const description = React.useMemo(() => {
    if (pros.length === 1) {
      return <Body>{t('confirmOverlapDescription')}</Body>;
    }

    const firstPros = pros.slice(0, pros.length - 1);
    const lastPro = pros.slice(pros.length - 1);

    const prosElement = firstPros.map((pro, index) => [
      <Body key={pro.worker._id} style={[meroStyles.text.semibold]}>
        {nameGenerator(pro.member.user.profile, t('noName'))}
      </Body>,
      index < firstPros.length - 1 ? ', ' : ' ',
    ]);

    const lastProElement = lastPro.map((pro) => [
      t('and'),
      ' ',
      <Body key={pro.worker._id} style={[meroStyles.text.semibold]}>
        {nameGenerator(pro.member.user.profile, t('noName'))}
      </Body>,
    ]);

    return (
      <Body>
        {prosElement}
        {lastProElement}
        {t('confirmOverlapWithProsDescription')}
      </Body>
    );
  }, [pros]);

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="warn"
        icon="info"
        headerTitle={t('importantAction')}
        onClose={onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>{t('confirmOverlapTitle')}</H1>
        <Spacer size="8" />
        {description}
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default pipe(ConfirmOverlapModal, CurrentBusiness);
