import { Body, colors, Column, MeroHeader, styles as meroStyles, Switch } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, HomeTabsParamsList, ProsDashboardStackParamList } from '../../../../../types';
import ScheduleMobile from './Schedule/ScheduleMobile';
import TeamMobile from './TeamMobile';

const TabsOptions = [
  {
    label: 'team',
    value: 'team',
  },
  {
    label: 'schedule',
    value: 'schedule',
  },
] as const;

type TabsOptionsType = (typeof TabsOptions)[number]['value'];

type Props = StackScreenProps<
  AuthorizedStackParamList & HomeTabsParamsList & ProsDashboardStackParamList,
  'ProsMobile'
> &
  CurrentBusinessProps;

const ProsMobileScreen: React.FC<Props> = ({ page, navigation, route }) => {
  const { t } = useTranslation('pros');

  const goBack = useGoBack();

  const defaultTab = React.useMemo(() => route.params?.tab ?? TabsOptions[0].value, [route.params?.tab]);
  const [selectedTab, setSelectedTab] = React.useState<TabsOptionsType>(defaultTab);

  const navigateMenu = React.useCallback(() => {
    goBack();
    navigation.navigate('MenuTab', { screen: 'MenuScreen' });
    setSelectedTab(defaultTab);
  }, [navigation, defaultTab]);

  const changeTab = React.useCallback((tab: TabsOptionsType) => {
    setSelectedTab(tab);
    navigation.setParams({ tab });
  }, []);

  const renderContent = React.useCallback(() => {
    switch (selectedTab) {
      case 'team':
        return <TeamMobile />;
      case 'schedule':
        return <ScheduleMobile />;
    }
  }, [selectedTab]);

  React.useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }} edges={['top']}>
      <MeroHeader
        canGoBack
        onBack={navigateMenu}
        TitleComponent={() => <Body>{t('pros')}</Body>}
        titleComponentStyle={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        // RightComponent={<Column style={{ width: 48 }} />}
      />
      {page.workers.length > 1 && (
        <Column style={{ paddingTop: 12, paddingBottom: 16, paddingHorizontal: 24 }}>
          <Switch
            height={32}
            textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
            buttons={TabsOptions.map((o) => ({ label: t(o.label), value: o.value }))}
            defaultValue={selectedTab}
            onChange={changeTab}
          />
        </Column>
      )}
      {renderContent()}
    </ModalScreenContainer>
  );
};

export default pipe(ProsMobileScreen, CurrentBusiness);
