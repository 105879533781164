import { Portal } from '@gorhom/portal';
import { DateString, Lastname } from '@mero/api-sdk';
import { WaitingListDetails } from '@mero/api-sdk/dist/calendar/waitingListDetails';
import { PageId } from '@mero/api-sdk/dist/pages';
import {
  colors,
  FormCard,
  SmallBody,
  styles as meroStyles,
  Spacer,
  Label,
  H2s,
  Title,
  Body,
  Line,
  Column,
  Row,
} from '@mero/components';
import { capitalize, formatPhoneNumber } from '@mero/shared-components';
import { Firstname } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { getPrice } from '../MenuScreen/screens/ProsDashboardScreen/ProsSortScreen';

import ClientViewCommissionModal from '../ClientDetailsScreen/components/ClientViewCommissionModal';
import Button from '@mero/components/lib/components/Button';
import HSpacer from '@mero/components/lib/components/HSpacer';

import log from '../../../utils/log';
import { nameGenerator } from '../../../utils/string';
import { DEFAULT_TIMEZONE } from '../../../utils/time';
import CancelPendingItem from './CancelRequest';

const CloseIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8343" />
    <Path
      fill={props.color}
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12Z"
      data-name="Path 8344"
    />
  </Svg>
);

type Props = {
  pageId: PageId;
  details: WaitingListDetails;
  onBooking: () => void;
  onCancel: () => Promise<void>;
  showPhone: boolean;
  reload: () => void;
};
const PendingCard: React.FC<Props> = ({ pageId, details, onBooking, onCancel, reload, showPhone }) => {
  const { t } = useTranslation('booking');

  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const [showViewCommissionModal, setShowViewCommissionModal] = React.useState(false);

  const toggleShowCommissionModal = () => {
    setShowViewCommissionModal(!showViewCommissionModal);
  };

  const formattedDate = React.useMemo(
    () =>
      capitalize(
        DateTime.fromJSDate(DateString.toDate(details.date, DEFAULT_TIMEZONE))
          //@FIXME: This is a hack to fix the week day issue on Luxon when using ro locale (https://meropunctro.atlassian.net/browse/MER-1466)
          .plus({ hour: 8 })
          .setLocale('ro')
          .toFormat('ccc dd MMM'),
      ),
    [details],
  );

  const callClient = (phone: WaitingListDetails['user']['phone']) => () => {
    const url = `tel:${phone}`;

    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          log.error(`Can't handle url: ${url}`);
        } else {
          return Linking.openURL(url);
        }
      })
      .catch(log.error);
  };

  const showDialog = () => {
    setShowCancelDialog(true);
  };

  const hideDialog = () => {
    setShowCancelDialog(false);
  };

  const handleCancel = async () => {
    await onCancel();
    hideDialog();
  };

  const services = details.services ?? [];

  const isBoost = details.boostStatus.isBoost;

  return (
    <>
      <FormCard paddings="none" rounded dropShaddow>
        <Row
          style={{
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            paddingLeft: 16,
            paddingRight: 8,
            paddingVertical: 8,
            backgroundColor: isBoost ? colors.PORTAGE : colors.ATHENS_GRAY,
            alignItems: 'center',
          }}
        >
          <SmallBody style={{ flex: 1, color: isBoost ? colors.WHITE : colors.BLACK }}>
            <SmallBody style={[meroStyles.text.semibold, { color: isBoost ? colors.WHITE : colors.BLACK }]}>
              {nameGenerator(details.user, t('unknownUser'))}
            </SmallBody>
            {showPhone
              ? ` - ${
                  details.boostStatus.isBoost && details.boostStatus.hideBoostDetails
                    ? t('boostClient').toUpperCase()
                    : formatPhoneNumber(details.user.phone)
                }`
              : ''}
          </SmallBody>
          <TouchableOpacity onPress={showDialog}>
            <CloseIcon color={isBoost ? colors.WHITE : '#52577f'} />
          </TouchableOpacity>
        </Row>
        <Spacer size={16} />
        <Column style={{ paddingHorizontal: 16 }}>
          <Label style={[{ color: colors.OUTRAGEOUS_ORANGE }, meroStyles.text.semibold]}>{t('statusPending')}</Label>
          <Spacer size={4} />
          <H2s>{formattedDate}</H2s>
          <Spacer size={8} />
          {services.length > 0 &&
            services.map((service) => (
              <React.Fragment key={service._id}>
                <Row>
                  <Column style={{ flex: 1 }}>
                    <Title>{service.name}</Title>
                    <Body>{nameGenerator(details.forWorker.user, t('unknownUser'))}</Body>
                  </Column>
                  <Title>{getPrice(service.price)}</Title>
                </Row>
                <Spacer size={8} />
              </React.Fragment>
            ))}
          {services.length === 0 && (
            <React.Fragment>
              <Row>
                <Column style={{ flex: 1 }}>
                  <Body>{nameGenerator(details.forWorker.user, t('unknownUser'))}</Body>
                </Column>
              </Row>
              <Spacer size={8} />
            </React.Fragment>
          )}
        </Column>
        <Spacer size={16} />
        <Line />
        <Spacer size={16} />
        <Row style={{ paddingHorizontal: 16, borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
          {isBoost && details.boostStatus.hideBoostDetails ? (
            <Column style={{ flex: 1 }}>
              <Button
                onPress={toggleShowCommissionModal}
                size="small"
                backgroundColor={colors.SKY_BLUE}
                color={colors.DARK_BLUE}
                text={t('viewCommission')}
              />
            </Column>
          ) : (
            <Column style={{ flex: 1 }}>
              <Button
                onPress={callClient(details.user.phone)}
                size="small"
                backgroundColor={colors.SKY_BLUE}
                color={colors.DARK_BLUE}
                text={t('callClient')}
              />
            </Column>
          )}
          <HSpacer left={8} />
          <Column style={{ flex: 1 }}>
            <Button onPress={onBooking} size="small" text={t('newAppointment')} />
          </Column>
        </Row>
        <Spacer size={16} />
      </FormCard>
      {showCancelDialog && (
        <Portal>
          <CancelPendingItem onSuccess={handleCancel} onCancel={hideDialog} />
        </Portal>
      )}
      {showViewCommissionModal && isBoost && (
        <Portal>
          <ClientViewCommissionModal
            client={{
              user: {
                _id: details.user._id,
                firstname: details.user.firstname as Firstname,
                lastname: details.user.lastname as Lastname,
                photo: details.user.profilePhoto,
              },
              pageId: pageId,
              boostStatus: details.boostStatus,
            }}
            onDismiss={toggleShowCommissionModal}
            onConfirm={() => {
              reload();
              toggleShowCommissionModal();
            }}
          />
        </Portal>
      )}
    </>
  );
};

export default PendingCard;
