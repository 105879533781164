import { colors, Row, Icon, HSpacer, H1, styles as meroStyles, Column, Switch } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { HomeTabsParamsList, ProsDashboardStackParamList } from '../../../../../types';
import ActiveIntervalView, { getWeekIntervals, Interval } from '../PageReportsScreen/ActiveIntervalView';
import { styles } from './ProsWebScreen.styles';
import NowButton from './Schedule/NowButton';
import ScheduleWeb from './Schedule/ScheduleWeb';
import Team from './TeamWeb';
import TeamWebFilters from './TeamWebFilters';

const TabsOptions = [
  {
    label: 'team',
    value: 'team',
  },
  {
    label: 'schedule',
    value: 'schedule',
  },
] as const;

type TabsOptionsType = (typeof TabsOptions)[number]['value'];

type Props = StackScreenProps<HomeTabsParamsList & ProsDashboardStackParamList, 'ProsWeb'> & CurrentBusinessProps;
const ProsWebScreen: React.FC<Props> = ({ navigation, page, route }) => {
  const { t } = useTranslation('pros');

  const goBack = useGoBack();

  const defaultTab = React.useMemo(() => route.params?.tab ?? TabsOptions[0].value, [route.params?.tab]);

  const defaultInterval = React.useMemo(() => getWeekIntervals()[0], []);

  const [selectedTab, setSelectedTab] = React.useState<TabsOptionsType>(defaultTab);
  const [activeInterval, setActiveInterval] = React.useState<Interval>(defaultInterval);

  const navigateMenu = React.useCallback(() => {
    goBack();
    navigation.navigate('MenuTab', { screen: 'MenuScreen' });
    setSelectedTab(defaultTab);
  }, [navigation]);

  const renderHeader = React.useCallback(() => {
    switch (selectedTab) {
      case 'team':
        return null;
      case 'schedule':
        return (
          <Row>
            <NowButton onPress={() => setActiveInterval(defaultInterval)} activeInterval={activeInterval} />
            <HSpacer left={16} />
            <ActiveIntervalView
              withFullCurrentMonth
              activeInterval={activeInterval}
              setActiveInterval={setActiveInterval}
              showFutureDates={true}
              type="week"
              headerTitle={t('selectPeriod')}
              title={t('selectPeriod')}
              customTitle={t('selectWeek')}
            />
          </Row>
        );
    }
  }, [selectedTab, activeInterval]);

  const renderFilters = React.useCallback(() => {
    switch (selectedTab) {
      case 'team':
        return <TeamWebFilters showOrderAction={page.workers.length > 1} />;
      case 'schedule':
        return null;
    }
  }, [selectedTab]);

  const renderContent = React.useCallback(() => {
    switch (selectedTab) {
      case 'team':
        return <Team />;
      case 'schedule':
        return <ScheduleWeb day={activeInterval.value.start} />;
    }
  }, [selectedTab, activeInterval]);

  const changeTab = React.useCallback((tab: TabsOptionsType) => {
    setSelectedTab(tab);
    navigation.setParams({ tab });
  }, []);

  React.useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  return (
    <Column style={{ backgroundColor: colors.ALABASTER, height: '100%' }}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        style={[styles.headerWrapperWithShadow, { paddingHorizontal: 24, paddingVertical: 30 }]}
      >
        <Row>
          <TouchableOpacity onPress={navigateMenu}>
            <Icon type="back" disabled={true} size={32} color={colors.BLACK} />
          </TouchableOpacity>
          <HSpacer left={16} />
          <H1>{t('pros')}</H1>
        </Row>
        {renderHeader()}
      </Row>
      <View style={{ padding: 24 }}>
        <Row alignItems="center" justifyContent="space-between">
          <Column style={{ flex: 1, maxWidth: 320 }}>
            {page.workers.length > 1 && (
              <Switch
                height={32}
                textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                buttons={TabsOptions.map((o) => ({ label: t(o.label), value: o.value }))}
                defaultValue={defaultTab}
                onChange={changeTab}
              />
            )}
          </Column>
          {renderFilters()}
        </Row>
      </View>
      {renderContent()}
    </Column>
  );
};

export default pipe(ProsWebScreen, CurrentBusiness);
