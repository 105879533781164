import * as MeroApi from '@mero/api-sdk';
import { ApiError, PageGiftCardSettings, PageDetails, PageId, PageRoleOverview2 } from '@mero/api-sdk';
import { SubscriptionInfo } from '@mero/api-sdk/dist/payments';
import { SavedService } from '@mero/api-sdk/dist/services';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import SplashScreen from '../../screens/SplashScreen';

import { AppStorage } from '../../app-storage';
import { meroApi } from '../../contexts/AuthContext';
import log, { logCatch } from '../../utils/log';

export type CurrentPageInfo = {
  readonly details: MeroApi.pages.PageDetails;
  readonly workers: MeroApi.workers.SavedWorker[];
  readonly members: MeroApi.pages.PageMemberPreview[];
  readonly subscription: MeroApi.payments.SubscriptionInfo | undefined;
  readonly expiryNotifiedAt: Date | undefined;
  readonly trialStartedNotifiedAt: Date | undefined;
  readonly permissions: MeroApi.access.MeroPermissionsWrapper;
  readonly roles: PageRoleOverview2[];
};

type CurrentBusinessState =
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Loading';
    }
  | {
      readonly type: 'Loaded';
      readonly api: MeroApi.NovabookerApi;
      readonly page: CurrentPageInfo;
      readonly allPages: MeroApi.pages.PageDetails[];
    }
  | {
      readonly type: 'NoPages';
    }
  | {
      readonly type: 'NoAccess';
      readonly page: MeroApi.pages.PageDetails;
      readonly allPages: MeroApi.pages.PageDetails[];
    };

const defaultState = (): CurrentBusinessState => ({
  type: 'New',
});

const trySaveSelectedPage = async (pageId: MeroApi.pages.PageId): Promise<void> => {
  try {
    await AppStorage.setCurrentUserPageId(pageId);
  } catch (e) {
    log.error('Failed to save current page', e);
  }
};

export const CurrentBusinessContext = createModelContext(
  defaultState(),
  {
    setLoaded: (
      _,
      payload: {
        page: MeroApi.pages.PageDetails;
        workers: MeroApi.workers.SavedWorker[];
        members: MeroApi.pages.PageMemberPreview[];
        subscription: MeroApi.payments.SubscriptionInfo | undefined; // Not using optional field to make sure this is always set
        expiryNotifiedAt: Date | undefined; // Not using optional field to make sure this is always set
        trialStartedNotifiedAt: Date | undefined;
        allPages: MeroApi.pages.PageDetails[];
        permissions: MeroApi.access.MeroPermissionsWrapper;
        roles: PageRoleOverview2[];
      },
    ) => {
      return {
        type: 'Loaded',
        api: meroApi,
        page: {
          details: payload.page,
          workers: payload.workers,
          members: payload.members,
          subscription: payload.subscription,
          expiryNotifiedAt: payload.expiryNotifiedAt,
          trialStartedNotifiedAt: payload.trialStartedNotifiedAt,
          permissions: payload.permissions,
          roles: payload.roles,
        },
        subscription: payload.subscription,
        allPages: payload.allPages,
      };
    },
    setWorkersAndServices: (state, payload: { workers: SavedWorker[]; services: SavedService[] }) => {
      if (state.type !== 'Loaded') {
        return state;
      }

      return {
        ...state,
        page: {
          ...state.page,
          workers: payload.workers,
          details: {
            ...state.page.details,
            services: payload.services,
          },
        },
      };
    },

    setWorkers: (state, payload: { workers: SavedWorker[] }) => {
      if (state.type !== 'Loaded') {
        return state;
      }

      return {
        ...state,
        page: {
          ...state.page,
          workers: payload.workers,
        },
      };
    },

    setPageMembers: (state, payload: { members: MeroApi.pages.PageMemberPreview[] }) => {
      if (state.type !== 'Loaded') {
        return state;
      }

      return {
        ...state,
        page: {
          ...state.page,
          members: payload.members,
        },
      };
    },

    setGiftCardSettings: (state, payload: PageGiftCardSettings) => {
      if (state.type !== 'Loaded') {
        return state;
      }

      return {
        ...state,
        page: {
          ...state.page,
          details: {
            ...state.page.details,
            giftCardSettings: payload,
          },
        },
      };
    },
    setSubscription: (state, payload: MeroApi.payments.SubscriptionInfo) => {
      if (state.type !== 'Loaded') {
        return state;
      }

      return {
        ...state,
        page: {
          ...state.page,
          subscription: payload,
        },
      };
    },
    setPageDetails: (state, payload: MeroApi.pages.PageDetails) => {
      if (state.type !== 'Loaded') {
        return state;
      }

      return {
        ...state,
        page: {
          ...state.page,
          details: payload,
        },
      };
    },
    setNoPages: () => {
      return {
        type: 'NoPages',
      };
    },
    setNoAccess: (state, payload: { page: MeroApi.pages.PageDetails; allPages: MeroApi.pages.PageDetails[] }) => {
      return {
        type: 'NoAccess',
        ...payload,
      };
    },
    mutate: (s, fn: (s: CurrentBusinessState) => CurrentBusinessState): CurrentBusinessState => fn(s),
  },
  (dispatch) => {
    const tryLoadSubscriptionInfo = async (pageId: PageId): Promise<SubscriptionInfo | undefined> => {
      try {
        const subscription = await meroApi.payments.getCalendarAccessSubscription(pageId);
        return subscription;
      } catch (e) {
        log.exception(e);
        return undefined;
      }
    };

    /**
     * Reload user managed pages and try select new page
     * @param pageId new selected page ID
     */
    const reload = async (pageId: PageId | undefined): Promise<void> => {
      const reloadAsync = async (): Promise<void> => {
        try {
          const [allPages, currentPageId] = await Promise.all([
            meroApi.pages.getManagedPages().catch((e) => {
              log.error('Failed to get managed pages', JSON.stringify(e));
              return [];
            }),
            AppStorage.getCurrentUserPageId(),
          ]);

          /**
           * Try to find new selected page
           */
          const newSelectedPage = pageId !== undefined ? allPages.find((page) => page._id === pageId) : undefined;
          /**
           * Last selected page, to fall back if newSelectedPage not found
           */
          const lastSelectedPage = allPages.find((page) => page._id === currentPageId);
          /**
           * First page, if no other pages found
           */
          const page: PageDetails | undefined = newSelectedPage ?? lastSelectedPage ?? allPages[0];

          if (page) {
            if (page !== lastSelectedPage) {
              trySaveSelectedPage(page._id);
            }

            try {
              const [subscription, workers, expiryNotifiedAt, trialStartedNotifiedAt, access] = await Promise.all([
                tryLoadSubscriptionInfo(page._id).catch(logCatch('tryLoadSubscriptionInfo')),
                meroApi.pages.getPageWorkers(page._id).catch(logCatch('getPageWorkers')),
                AppStorage.getSubscriptionExpireNotifiedAt(page._id).catch(logCatch('getSubscriptionExpireNotifiedAt')),
                AppStorage.getTrialStartedNotifiedAt(page._id).catch(logCatch('getTrialStartedNotifiedAt')),
                meroApi.pages.getPageAccess(page._id).catch(logCatch('getPageAccess')),
              ]);

              log.debug(`CurrentBusiness loaded: ${page._id}, expires: ${subscription?.expires}`);

              const [members, roles] = await Promise.all([
                access.permissions.proProfiles.canListAllProfiles()
                  ? meroApi.pages.getPageMembers(page._id)
                  : meroApi.pages
                      .getCurrentUserMember(page._id)
                      .then((member) => [member])
                      .catch(logCatch('getCurrentUserMember')),
                access.permissions.proProfiles.canManageAllProfiles() ? meroApi.pages.getPageRoles(page._id) : [],
              ]);

              dispatch.setLoaded({
                page,
                workers,
                members,
                allPages,
                subscription,
                expiryNotifiedAt,
                trialStartedNotifiedAt,
                permissions: access.permissions,
                roles,
              });
            } catch (e) {
              const NO_ACCESS_ERROR_CODE = 8;
              if (e instanceof ApiError && e.code === NO_ACCESS_ERROR_CODE) {
                dispatch.setNoAccess({
                  allPages,
                  page,
                });
              } else {
                log.exception(e);
              }
            }
          } else {
            dispatch.setNoPages();
          }
        } catch (e) {
          log.exception(e);
          // FIXME: add failed state, with reload button
          // not setting NoPage state as it will redirect user to onboarding, let it stuck, should fix the issue
        }
      };

      await reloadAsync().catch(log.exception);
    };

    return {
      init: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            reload(undefined);

            return {
              type: 'Loading',
            };
          } else {
            return state;
          }
        });
      },
      setCurrentPage: (pageId: MeroApi.pages.PageId): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' || state.type === 'NoAccess') {
            const setCurrentPageAsync = async (): Promise<void> => {
              const { allPages } = state;
              try {
                const page = allPages.find((p) => p._id === pageId);
                if (page) {
                  await trySaveSelectedPage(page._id);

                  try {
                    const [subscription, workers, expiryNotifiedAt, trialStartedNotifiedAt, access] = await Promise.all(
                      [
                        tryLoadSubscriptionInfo(page._id),
                        meroApi.pages.getPageWorkers(page._id),
                        AppStorage.getSubscriptionExpireNotifiedAt(page._id),
                        AppStorage.getTrialStartedNotifiedAt(page._id),
                        meroApi.pages.getPageAccess(page._id),
                      ],
                    );

                    log.debug(`CurrentBusiness: set current page with pageId=${page._id}`);

                    const [members, roles] = await Promise.all([
                      access.permissions.proProfiles.canListAllProfiles()
                        ? meroApi.pages.getPageMembers(page._id)
                        : meroApi.pages
                            .getCurrentUserMember(page._id)
                            .then((member) => [member])
                            .catch(logCatch('getCurrentUserMember')),
                      access.permissions.proProfiles.canManageAllProfiles() ? meroApi.pages.getPageRoles(page._id) : [],
                    ]);

                    dispatch.setLoaded({
                      page,
                      workers,
                      members,
                      allPages,
                      subscription,
                      expiryNotifiedAt,
                      trialStartedNotifiedAt,
                      permissions: access.permissions,
                      roles,
                    });
                  } catch (e) {
                    const NO_ACCESS_ERROR_CODE = 8;
                    if (e instanceof ApiError && e.code === NO_ACCESS_ERROR_CODE) {
                      dispatch.setNoAccess({
                        allPages,
                        page,
                      });
                    } else {
                      log.exception(e);
                    }
                  }
                }
              } catch (e) {
                log.exception(e);
                // FIXME: add failed state, with reload button
                // not setting NoPage state as it will redirect user to onboarding, let it stuck, should fix the issue
              }
            };

            setCurrentPageAsync().catch(log.exception);

            return state;
          } else {
            return state;
          }
        });
      },
      reload: (pageId?: PageId): void => {
        dispatch.mutate(() => {
          reload(pageId);

          return {
            type: 'Loading',
          };
        });
      },
      /**
       * Reloads pages without setting Loading state
       */
      reloadAsync: async (): Promise<void> => {
        await reload(undefined);
      },

      getPageWorkers: async (pageId: MeroApi.pages.PageId) => {
        const [workers] = await Promise.all([meroApi.pages.getPageWorkers(pageId)]);
        dispatch.setWorkers({ workers });
      },

      getPageWorkersAndServices: async (pageId: MeroApi.pages.PageId) => {
        const [services, workers] = await Promise.all([
          meroApi.pages.getPageServices({ pageId: pageId }),
          meroApi.pages.getPageWorkers(pageId),
        ]);
        dispatch.setWorkersAndServices({ workers, services });
      },

      getPageMembers: async (pageId: MeroApi.pages.PageId) => {
        const members = await meroApi.pages.getPageMembers(pageId);
        dispatch.setPageMembers({ members });
      },

      updateGiftCardSettings: async (pageId: MeroApi.pages.PageId) => {
        const settings = await meroApi.pages.getPageGiftCardSettings({ pageId });

        dispatch.setGiftCardSettings(settings);
      },

      updateSubscriptionInfo: async (pageId: MeroApi.pages.PageId) => {
        const subscription = await meroApi.payments.getCalendarAccessSubscription(pageId);

        if (subscription) {
          dispatch.setSubscription(subscription);
        }
      },

      updatePageDetails: async (pageId: MeroApi.pages.PageId) => {
        const page = await meroApi.pages.getPageDetails(pageId);

        if (page) {
          dispatch.setPageDetails(page);
        }
      },

      saveSubscriptionExpiryNotificationSeen: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded') {
            const expiryNotifiedAt = new Date();

            AppStorage.setSubscriptionExpireNotifiedAt(state.page.details._id, expiryNotifiedAt).catch(log.exception);

            return {
              ...state,
              page: {
                ...state.page,
                expiryNotifiedAt: expiryNotifiedAt,
              },
            };
          } else {
            return state;
          }
        });
      },
      saveTrialStartedNotificationSeen: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded') {
            log.debug(`saveTrialStartedNotificationSeen for pageId=${state.page.details._id}`);
            const trialStartedNotifiedAt = new Date();

            AppStorage.setTrialStartedNotifiedAt(state.page.details._id, trialStartedNotifiedAt)
              .then(() => reload(state.page.details._id))
              .catch(log.exception);

            return {
              ...state,
              trialStartedNotifiedAt,
            };
          } else {
            return state;
          }
        });
      },
    };
  },
);

export type CurrentBusinessProps = {
  page: CurrentPageInfo;
};

export type CurrentBusinessNoAccessProps = {
  page: MeroApi.pages.PageDetails;
};

type PropsWithPage<P> = P & CurrentBusinessProps;

export function CurrentBusiness<P extends CurrentBusinessProps>(
  Component: React.ComponentType<P>,
  NoPagesComponent: React.ComponentType<P> = () => null,
  NoAccessComponent: React.ComponentType<P> = () => null,
): React.FunctionComponent<Omit<P, keyof CurrentBusinessProps>> {
  return function CurrentBusinessComponent(props) {
    const [state] = CurrentBusinessContext.useContext();

    switch (state.type) {
      case 'New': {
        return <SplashScreen />;
      }
      case 'Loading': {
        return <SplashScreen />;
      }
      case 'Loaded': {
        // FIXME: find a type safe way to exclude a property for generic type argument
        const page: CurrentPageInfo = state.page;

        // @ts-expect-error
        const allProps: PropsWithPage<P> = { ...props, page: page };

        return <Component {...allProps} />;
      }
      case 'NoPages': {
        // @ts-expect-error
        const allProps: P = { ...props };

        return <NoPagesComponent {...allProps} />;
      }
      case 'NoAccess': {
        // @ts-expect-error
        const allProps: P = { ...props };

        return <NoAccessComponent {...allProps} />;
      }
    }
  };
}

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init }] = CurrentBusinessContext.useContext();

  React.useEffect(() => {
    init();
  }, [init]);

  return <>{children}</>;
};

export const withCurrentBusinessContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithCurrentBusinessContextProvider(props: P) {
    return (
      <CurrentBusinessContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </CurrentBusinessContext.Provider>
    );
  };
};
