import { PageMemberPreview, SavedWorker, UserId, Worker, WorkerId } from '@mero/api-sdk';
import {
  Avatar,
  Body,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  HSpacer,
  Icon,
  Line,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import { indexOf, sortBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import MenuItem from '../../../../../components/MenuItem';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { AuthContext } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, HomeTabsParamsList, ProsDashboardStackParamList } from '../../../../../types';

type Props = CurrentBusinessProps;

const TeamWeb: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation('pros');

  const navigation =
    useNavigation<NavigationProp<AuthorizedStackParamList & HomeTabsParamsList & ProsDashboardStackParamList>>();

  const [authState] = AuthContext.useContext();

  const [selectedPageMember, setSelectedPageMember] = React.useState<
    | {
        worker: SavedWorker | undefined;
        member: PageMemberPreview;
      }
    | undefined
  >();

  const isOwnProfile = (userId: UserId) => {
    if (authState.type === 'Authorized') {
      return authState.user._id === userId;
    }

    return true;
  };

  type Members = {
    workers: {
      worker: SavedWorker;
      member: PageMemberPreview;
    }[];
    administrative: PageMemberPreview[];
  };
  const members: Members = React.useMemo(() => {
    const workersByUserId: Record<UserId, SavedWorker> = page.workers.reduce(
      (acc: Record<UserId, SavedWorker>, worker) => ({ ...acc, [worker.user._id]: worker }),
      {},
    );

    const workersIds = page.workers.map((worker) => worker._id);

    const workers = page.members
      .filter((member) => workersByUserId[member.user._id])
      .map((member) => ({
        worker: workersByUserId[member.user._id],
        member,
      }))
      .filter((member) => member.worker);

    const administrative = page.members.filter((member) => !workersByUserId[member.user._id]);

    return { workers: sortBy(workers, (item) => indexOf(workersIds, item.worker._id)), administrative };
  }, [page.details._id, page.members, page.workers]);

  React.useEffect(() => {
    if (selectedPageMember) {
      const administrativeMembers = members.administrative.map((member) => ({ member: member, worker: undefined }));
      const allPageMembers = [...members.workers, ...administrativeMembers];
      const foundMember = allPageMembers.find((w) => w.member.user._id === selectedPageMember?.member.user._id);
      if (foundMember) {
        setSelectedPageMember(foundMember);
      } else {
        setSelectedPageMember(members.workers[0]);
      }
    }
  }, [members]);

  React.useEffect(() => {
    if (members.workers.length > 0) {
      setSelectedPageMember(members.workers[0]);
    }
  }, []);

  const navigateProProfileCallback = React.useCallback(
    (params: { workerId: WorkerId | undefined; userId: UserId }) => {
      navigation.navigate('ProsEdit', {
        screen: 'ProProfile',
        params: params,
      });
    },
    [navigation],
  );

  const navigateProCalendarCallback = React.useCallback(
    (params: { workerId: WorkerId | undefined; userId: UserId }) => {
      navigation.navigate('ProsEdit', {
        screen: 'ProCalendar',
        params: params,
      });
    },
    [navigation],
  );

  const navigateProScheduleCallback = React.useCallback(
    (workerId: WorkerId | undefined) => {
      workerId &&
        navigation.navigate('ProsEdit', {
          screen: 'ProSchedule',
          params: { workerId: workerId },
        });
    },
    [navigation],
  );

  const navigateProServicesCallback = React.useCallback(
    (workerId: WorkerId | undefined) => {
      workerId && navigation.navigate('ProsEdit', { screen: 'ProServices', params: { workerId: workerId } });
    },
    [navigation],
  );

  const navigateProPermissionsCallback = React.useCallback(
    (userId: UserId) => {
      navigation.navigate('ProsEdit', { screen: 'ProPermissions', params: { userId } });
    },
    [navigation],
  );

  const PageMemberCard: React.FC<{ member: PageMemberPreview; worker: SavedWorker | undefined }> = ({
    member,
    worker,
  }) => {
    const isInvited = worker ? Worker.isInvited(worker, page.details) : false;

    return (
      <TouchableOpacity onPress={() => setSelectedPageMember({ member, worker })}>
        <Row
          style={[
            selectedPageMember?.member.user._id === member.user._id && {
              backgroundColor: colors.ATHENS_GRAY,
            },
            { paddingHorizontal: 16, paddingVertical: 12, justifyContent: 'space-between' },
          ]}
        >
          <Row>
            <Avatar
              size={32}
              firstname={member.user.profile.firstname ?? ''}
              lastname={member.user.profile.lastname ?? ''}
              source={member.user.profile.photo?.small}
            />
            <HSpacer left={12} />
            <Column style={{ maxWidth: 160 }}>
              <Body style={[meroStyles.text.semibold]}>
                {member.user.profile.firstname} {member.user.profile.lastname}
              </Body>
              <SmallBody style={{ fontSize: 12 }}>
                {member.roles.map((role) => role.name).join(', ')} {isInvited ? `(${t('invited')})` : ''}
              </SmallBody>
              <SmallBody style={[{ fontSize: 12, marginTop: 4 }, meroStyles.text.hint]}>{member.user.phone}</SmallBody>
            </Column>
          </Row>

          {selectedPageMember?.member.user._id === member.user._id && <Icon type="arrow-right" size={24}></Icon>}
        </Row>
      </TouchableOpacity>
    );
  };
  return (
    <>
      <FormCard
        dropShaddow
        paddings="none"
        style={{ flex: 1, marginHorizontal: 24, marginBottom: 24, zIndex: -1 }}
        rounded
      >
        <Row style={{ flex: 1 }}>
          {/* Left Menu */}
          <ScrollView style={{ marginVertical: 24, maxWidth: 313 }}>
            <FormCard paddings="none" rounded dropShaddow style={{ marginHorizontal: 24 }}>
              <H3s style={{ flex: 1, padding: 16 }}>{t('acceptsAppointments')}</H3s>
              {members.workers.map(({ member, worker }, index) => (
                <View key={member.user._id}>
                  {index !== 0 && <Line />}
                  <PageMemberCard member={member} worker={worker} />
                </View>
              ))}

              {members.administrative.length > 0 && (
                <H3s style={{ flex: 1, padding: 16 }}>{t('doesntAcceptAppointments')}</H3s>
              )}
              {members.administrative.map((member, index) => (
                <View key={member.user._id}>
                  {index !== 0 && <Line />}
                  <PageMemberCard member={member} worker={undefined} />
                </View>
              ))}
            </FormCard>
          </ScrollView>

          {/* Member Right Details */}
          {selectedPageMember && (
            <Column style={{ flex: 1, paddingVertical: 24, paddingRight: 24 }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Column>
                  <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>
                    {selectedPageMember.member.user.profile.firstname} {selectedPageMember.member.user.profile.lastname}
                  </H1>
                  <SmallBody style={[meroStyles.text.hint, { fontSize: 12, marginTop: 4 }]}>
                    {selectedPageMember.member.user.phone}
                  </SmallBody>
                </Column>

                <Avatar
                  size={48}
                  firstname={selectedPageMember.member.user.profile.firstname ?? ''}
                  lastname={selectedPageMember.member.user.profile.lastname ?? ''}
                  source={selectedPageMember.member.user.profile.photo?.small}
                />
              </Row>

              <Spacer size={16} />
              <Column style={{ backgroundColor: colors.WHITE, flex: 1 }}>
                <MenuItem
                  title={t('profileDetails')}
                  icon="pro-menu-profile-details"
                  onPress={() =>
                    navigateProProfileCallback({
                      userId: selectedPageMember.member.user._id,
                      workerId: selectedPageMember.worker?._id,
                    })
                  }
                />
                <Line />
                <MenuItem
                  title={t('calendarSettings')}
                  subtitle={`${t(
                    selectedPageMember.worker
                      ? 'calendarSettingsDescriptionPositive'
                      : 'calendarSettingsDescriptionNegative',
                  )}${
                    selectedPageMember.worker?.calendar.settings.isPrivate
                      ? ` - ${t('calendarSettingsDescription2')}`
                      : ''
                  }`}
                  icon="pro-menu-calendar-settings"
                  onPress={() =>
                    navigateProCalendarCallback({
                      userId: selectedPageMember.member.user._id,
                      workerId: selectedPageMember.worker?._id,
                    })
                  }
                />
                <Line />
                {selectedPageMember.worker && (
                  <>
                    <MenuItem
                      title={t('schedule')}
                      icon="pro-menu-pending-clients"
                      onPress={() => navigateProScheduleCallback(selectedPageMember.worker?._id)}
                    />
                    <Line />
                    <MenuItem
                      title={t('servicesCustom')}
                      subtitle={t('activeServices', {
                        count: selectedPageMember.worker.services.filter((s) => s.pageId === page.details._id).length,
                      })}
                      icon="pro-menu-services"
                      onPress={() => navigateProServicesCallback(selectedPageMember.worker?._id)}
                    />
                    <Line />
                  </>
                )}
                <MenuItem
                  disabled={
                    !(
                      page.permissions.account.canManagePermissions() &&
                      !isOwnProfile(selectedPageMember.member.user._id)
                    )
                  }
                  title={t('permissions')}
                  subtitle={selectedPageMember.member.roles[0]?.name}
                  icon="pro-menu-permissions"
                  onPress={() => navigateProPermissionsCallback(selectedPageMember.member.user._id)}
                />
              </Column>
            </Column>
          )}
        </Row>
      </FormCard>
    </>
  );
};

export default pipe(TeamWeb, CurrentBusiness);
