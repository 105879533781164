import { Portal } from '@gorhom/portal';
import { Icon, Body, colors, styles as meroStyles, Row } from '@mero/components';
import { DateTime } from 'luxon';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import SelectInterval from './SelectInterval';

export type Interval = {
  label: string;
  id: string;
  value: {
    start: DateTime;
    end: DateTime;
  };
};

type Props = {
  intervals?: Interval[];
  activeInterval: Interval;
  setActiveInterval: (interval: Interval) => void;
  fullWidth?: boolean;
  showFutureDates?: boolean;
  withFullCurrentMonth?: boolean;
  withArrows?: boolean;
  type?: 'month' | 'week';
  headerTitle?: string;
  title?: string;
  customTitle?: string;
};

const now = DateTime.utc().setLocale('ro');

export const getMonthIntervals = (
  payload: {
    custom?: Interval;
    withFirstDay?: boolean;
    withFullCurrentMonth?: boolean;
  } = {},
) => {
  const { custom, withFirstDay = false, withFullCurrentMonth = false } = payload;
  const LENGTH = 4;
  const isFirstDayOfMonth = withFirstDay ? now.day === 1 : false;

  return [
    ...Array.from({ length: LENGTH }, (_, i) => {
      const time = now.plus({ month: -(i + (isFirstDayOfMonth ? 1 : 0)) });
      const start = time.startOf('month');
      const end = i === 0 && !isFirstDayOfMonth ? time.endOf('day') : time.endOf('month');
      if (i === 0 && withFullCurrentMonth && end.day !== time.endOf('month').day) {
        const endOfMonth = time.endOf('month');
        return [
          {
            label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
            id: `${start.toISO()} - ${end.toISO()}`,
            value: { start, end },
          },
          {
            label: `${start.toFormat('dd MMM')} - ${endOfMonth.toFormat('dd MMM')}`,
            id: `${start.toISO()} - ${endOfMonth.toISO()}`,
            value: { start, end: endOfMonth },
          },
        ];
      }
      return {
        label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
        id: `${start.toISO()} - ${end.toISO()}`,
        value: { start, end },
      };
    }),
    (() => {
      const start = custom ? custom.value.start : now.startOf('day');
      const end = custom ? custom.value.end : now.endOf('day');

      return {
        label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
        id: 'custom',
        value: {
          start,
          end,
        },
      };
    })(),
  ]
    .flat()
    .filter((i): i is Interval => Boolean(i.id));
};

export const getWeekIntervals = () => {
  const LENGTH = 4;
  return [
    ...Array.from({ length: LENGTH }, (_, i) => {
      const start = now.startOf('week').plus({ weeks: i });
      const end = now.endOf('week').plus({ weeks: i });
      return {
        label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
        id: `${start.toISO()} - ${end.toISO()}`,
        value: { start, end },
      };
    }),
    (() => {
      const start = now.startOf('week').plus({ weeks: LENGTH });
      const end = now.endOf('week').plus({ weeks: LENGTH });

      return {
        label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
        id: 'custom',
        value: {
          start,
          end,
        },
      };
    })(),
  ];
};

export const isSameDay = (a: DateTime, b: DateTime) => {
  return a.startOf('day').equals(b.startOf('day'));
};

const getIntervals = (
  type: 'month' | 'week',
  payload: { month: Parameters<typeof getMonthIntervals>[0]; week: Parameters<typeof getWeekIntervals> },
) => {
  if (type === 'month') {
    return getMonthIntervals(payload.month);
  }

  return getWeekIntervals(...payload.week);
};

const ActiveIntervalView: React.FC<Props> = ({
  intervals: _intervals,
  activeInterval,
  setActiveInterval,
  fullWidth = false,
  showFutureDates = false,
  withFullCurrentMonth,
  withArrows = true,
  type = 'month',
  headerTitle,
  title,
  customTitle,
}) => {
  const now = React.useMemo(() => DateTime.utc().setLocale('ro'), []);
  const explicitCustom = React.useRef(false);

  const [intervals, setIntervals] = React.useState<Interval[]>(
    _intervals ?? getIntervals(type, { month: { withFullCurrentMonth }, week: [] }),
  );

  const [showSelector, setShowSelector] = React.useState(false);

  const changeIntervalMonth = React.useCallback(
    (increase: boolean) => {
      let daysDiff = Math.round(activeInterval.value.end.diff(activeInterval.value.start, 'days').days);
      if (daysDiff === 0) {
        daysDiff = 1;
      }
      const start = activeInterval.value.start.plus(
        explicitCustom.current ? { days: increase ? daysDiff : -daysDiff } : { months: increase ? 1 : -1 },
      );
      let end = activeInterval.value.end.plus(
        explicitCustom.current ? { days: increase ? daysDiff : -daysDiff } : { months: increase ? 1 : -1 },
      );

      if (!explicitCustom.current) {
        if (end.month !== now.month) {
          end = start.endOf('month');
        } else {
          end = now;
        }
      }

      if (!showFutureDates) {
        if (start.diffNow('days').days > 0) {
          return;
        }

        if (end.diffNow('days').days > 0) {
          end = now;
        }
      }

      const existingInterval = intervals.find((i) => isSameDay(i.value.start, start) && isSameDay(i.value.end, end));

      if (existingInterval) {
        setActiveInterval(existingInterval);
      } else {
        setActiveInterval({
          label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
          id: 'custom',
          value: {
            start,
            end,
          },
        });
      }
    },
    [activeInterval, showFutureDates],
  );

  const changeIntervalWeek = React.useCallback(
    (increase: boolean) => {
      const start = activeInterval.value.start.plus({ weeks: increase ? 1 : -1 });
      const end = activeInterval.value.end.plus({ weeks: increase ? 1 : -1 });

      const existingInterval = intervals.find((i) => isSameDay(i.value.start, start) && isSameDay(i.value.end, end));

      if (existingInterval) {
        setActiveInterval(existingInterval);
      } else {
        setActiveInterval({
          label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
          id: 'custom',
          value: {
            start,
            end,
          },
        });
      }
    },
    [activeInterval, showFutureDates],
  );

  const changeInterval = (increase: boolean) => () => {
    if (type === 'month') {
      changeIntervalMonth(increase);
    }

    changeIntervalWeek(increase);
  };

  return (
    <>
      {withArrows && (
        <TouchableOpacity
          style={{
            width: 43,
            height: 43,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#DEE2E6',
            borderWidth: 1,
            borderRadius: 5,
            backgroundColor: colors.WHITE,
          }}
          onPress={changeInterval(false)}
        >
          <Icon type={'arrow-left'} color={'#000000'} />
        </TouchableOpacity>
      )}
      <TouchableOpacity
        style={{
          height: 43,
          flexGrow: fullWidth ? 1 : 0,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: '#DEE2E6',
          borderWidth: 1,
          borderRadius: 5,
          marginHorizontal: withArrows ? 8 : 0,
          paddingHorizontal: 12,
          backgroundColor: colors.WHITE,
        }}
        onPress={() => setShowSelector(true)}
      >
        <Row style={{ width: '100%', alignItems: 'center' }}>
          <Body
            style={[
              { alignItems: 'center', textAlign: 'center', flex: 1, marginRight: fullWidth ? -20 : 0 },
              withArrows && meroStyles.text.semibold,
            ]}
          >
            {activeInterval.value.start.toFormat('dd MMM')} - {activeInterval.value.end.toFormat('dd MMM')}
          </Body>
          <View style={[{ paddingLeft: 10 }]}>
            <Icon type="dropdown" rotate={false} />
          </View>
        </Row>
      </TouchableOpacity>
      {withArrows && (
        <TouchableOpacity
          style={{
            width: 43,
            height: 43,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#DEE2E6',
            borderWidth: 1,
            borderRadius: 5,
            backgroundColor: colors.WHITE,
          }}
          onPress={changeInterval(true)}
        >
          <Icon type={'arrow-right'} color={'#000000'} />
        </TouchableOpacity>
      )}
      {showSelector && (
        <Portal>
          <SelectInterval
            intervals={intervals}
            activeInterval={activeInterval ?? intervals[0].value}
            onSave={(interval) => {
              setActiveInterval(interval);
              if (interval.id === 'custom') {
                explicitCustom.current = true;
                setIntervals(getMonthIntervals({ custom: interval, withFullCurrentMonth }));
              } else {
                explicitCustom.current = false;
              }
              setShowSelector(false);
            }}
            onCancel={() => setShowSelector(false)}
            headerTitle={headerTitle}
            title={title}
            customTitle={customTitle}
            type={type}
          />
        </Portal>
      )}
    </>
  );
};

export default ActiveIntervalView;
