import { Body, ConfirmBox, H1, ModalOverlay, Spacer, useShowError } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  readonly title: string;
  readonly description: string;
  readonly onConfirm: () => void;
  readonly onDismiss: () => void;
};

const DeleteScheduleModal: React.FC<Props> = ({ title, description, onConfirm, onDismiss }) => {
  const { t } = useTranslation('pros');

  const [isWaiting, setIsWaiting] = React.useState(false);
  const showError = useShowError();

  const onRightActionPress = React.useCallback(async () => {
    setIsWaiting(true);
    try {
      await onConfirm();
    } catch (error) {
      showError(error);
    } finally {
      setIsWaiting(false);
    }
  }, [onConfirm]);

  const leftAction = {
    text: t('cancelButton'),
    onPress: isWaiting ? undefined : onDismiss,
    flex: 10,
  };

  const rightAction = {
    text: t('confirmDeleteButton'),
    onPress: isWaiting ? undefined : onRightActionPress,
    flex: 15,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="error"
        icon="info"
        headerTitle={t('ireversibleAction')}
        onClose={onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
        style={{ maxWidth: 468 }}
      >
        <H1>{title}</H1>
        <Spacer size="8" />
        <Body>{description}</Body>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default DeleteScheduleModal;
