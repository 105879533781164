import { colors } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { DEFAULT_TIMEZONE } from '../../../../../../utils/time';
import { Interval } from '../../PageReportsScreen/ActiveIntervalView';

const CalendarIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 8260">
      <Path
        d="M20.1 20.1H3.743A.745.745 0 0 1 3 19.354V5.973a.744.744 0 0 1 .743-.743h3.718V3.743a.743.743 0 0 1 1.487 0V5.23h5.947V3.743a.743.743 0 1 1 1.487 0V5.23H20.1a.744.744 0 0 1 .743.743v13.381a.745.745 0 0 1-.743.746ZM4.487 8.2v10.411h14.867V8.2Zm3.345 5.2a1.859 1.859 0 1 1 1.859-1.851 1.861 1.861 0 0 1-1.859 1.859Z"
        data-name="Icons/24px/Black/Calendar"
      />
      <Path fill="none" d="M24 24H0V0h24z" data-name="Rectangle 2839" />
    </G>
  </Svg>
);

type Props = {
  activeInterval: Interval;
  onPress: () => void;
};

const CalendarButton: React.FC<Props> = ({ onPress, activeInterval }) => {
  const now = React.useMemo(() => DateTime.now().setZone(DEFAULT_TIMEZONE).startOf('day'), []);

  const shouldHide = React.useMemo(() => {
    return activeInterval.value.start.startOf('day') <= now && now <= activeInterval.value.end.endOf('day');
  }, [now, activeInterval]);

  return shouldHide ? null : (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <CalendarIcon />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 42,
    height: 42,
    borderWidth: 1,
    borderColor: colors.GEYSER,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CalendarButton;
