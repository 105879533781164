import { PageRoleOverview2 } from '@mero/api-sdk/dist/pages';
import { Body, Checkbox, colors, Column, H3s, Line, Row, Title } from '@mero/components';
import React from 'react';
import { Animated, Platform, useWindowDimensions } from 'react-native';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import Icon from '../Icon';
import styles from './styles';

interface Props {
  isSelected: boolean;
  roleOverview: PageRoleOverview2;
}

interface Permission {
  title: string;
  isActive: boolean;
}

const PermissionsCarouselItem: React.FC<Props> = ({ roleOverview, isSelected }) => {
  const { width } = useWindowDimensions();
  const { isPhone } = useMediaQueries();

  const screenWidth = isPhone ? width : 500;
  const PADDING_SPACING = 8;
  const SPACING = 22;
  const PERMISSION_LENGTH = screenWidth - PADDING_SPACING * 2 - SPACING * 2;
  const hasActivePermissions = (permissions: Permission[]) => permissions.some((permission) => permission.isActive);

  return (
    <Animated.View
      style={[
        styles.card,
        {
          width: Platform.OS === 'web' ? undefined : PERMISSION_LENGTH,
          marginHorizontal: PADDING_SPACING,
          borderColor: isSelected ? colors.DARK_BLUE : colors.WHITE,
        },
      ]}
    >
      <Row style={styles.cardHeader}>
        <Column>
          <Checkbox value={isSelected} color="blue" disabled={false} />
        </Column>
        <Column style={styles.cardTitle}>
          <H3s>{roleOverview.name}</H3s>
        </Column>
      </Row>
      <Column style={styles.roleWrapper}>
        {roleOverview.permissionsOverview.map((permissionOverview, indexPermission) => (
          <Column key={indexPermission}>
            <Title
              style={[
                styles.permissionTitle,
                !hasActivePermissions(permissionOverview.permissions) && styles.inactivePermission,
              ]}
            >
              {permissionOverview.title}
            </Title>
            <Column style={styles.permissionsWrapper}>
              {permissionOverview.permissions.map((permission, index) => (
                <Row alignItems="flex-start" flex={1} style={styles.permissionRow} key={index}>
                  <Icon
                    name={permission.isActive ? 'CheckmarkCircled' : 'CloseCircle'}
                    size={22}
                    color={permission.isActive ? colors.COMET : '#A8AABE'}
                  />
                  <Body style={[!permission.isActive && styles.inactivePermission, styles.permission]}>
                    {permission.title}
                  </Body>
                </Row>
              ))}
            </Column>
            {indexPermission !== roleOverview.permissionsOverview.length - 1 && (
              <Column style={styles.line}>
                <Line />
              </Column>
            )}
          </Column>
        ))}
      </Column>
    </Animated.View>
  );
};

export default PermissionsCarouselItem;
