import { colors, Title, Line } from '@mero/components';
import { flow, pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

import FloatMenu, { Ref as FloatMenuRef } from '../../../../../../components/FloatMenu';
import { withHoverState } from '../../../../../../components/Hover';
import Column from '@mero/components/lib/components/Layout/Column';

import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

const PlusIcon = (props: SvgProps) => (
  <Svg width={11.5} height={11.5} {...props}>
    <Path
      fill="none"
      stroke="#080de0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M5.75.75v10m5-5h-10"
    />
  </Svg>
);

type Props = {
  isHovered: boolean;
  skipHover?: boolean;
  isInvited: boolean;
  onAddNewInterval: () => void;
  onAddNewSchedule: () => void;
  onBlockTime: () => void;
};

const AddNew: React.FC<Props> = ({
  isHovered,
  isInvited,
  skipHover = false,
  onAddNewInterval,
  onAddNewSchedule,
  onBlockTime,
}) => {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const floatMenuRef = React.useRef<FloatMenuRef>(null);
  const [isOpened, setIsOpened] = React.useState(false);

  const openMenu = () => {
    setIsOpened(true);
  };

  const closeMenu = () => {
    setIsOpened(false);
  };

  const dismissMenu = () => {
    floatMenuRef.current?.onDismissMenu();
  };

  return (
    <FloatMenu
      ref={floatMenuRef}
      mode={isPhone ? 'bottom' : 'default'}
      onDismiss={closeMenu}
      onShow={openMenu}
      button={
        <Column style={[styles.action, isHovered || isOpened || skipHover ? styles.visible : styles.hidden]}>
          <PlusIcon />
        </Column>
      }
    >
      {!isInvited && (
        <>
          <TouchableOpacity style={[styles.menuItem]} onPress={flow(onAddNewInterval, dismissMenu)}>
            <Title style={[!isPhone && styles.desktop]}>{t('addNewInterval')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.menuItem} onPress={flow(onAddNewSchedule, dismissMenu)}>
            <Title style={[!isPhone && styles.desktop]}>{t('addNewSchedule')}</Title>
          </TouchableOpacity>
          <Line />
        </>
      )}
      <TouchableOpacity style={styles.menuItem} onPress={flow(onBlockTime, dismissMenu)}>
        <Title style={[!isPhone && styles.desktop]}>{t('blockTime')}</Title>
      </TouchableOpacity>
    </FloatMenu>
  );
};

const styles = StyleSheet.create({
  action: {
    backgroundColor: colors.SKY_BLUE,
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 6,
    zIndex: 1,
  },
  desktop: {
    color: colors.DARK_BLUE,
  },
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  menuItem: {
    padding: 16,
  },
});

export default pipe(AddNew, withHoverState);
