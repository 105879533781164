import { WorkInterval } from '@mero/api-sdk';
import { BusinessHours, PageRoleOverview2 } from '@mero/api-sdk';
import { SubscriptionId } from '@mero/api-sdk/dist/payments';
import { SubscriptionSeat } from '@mero/api-sdk/dist/payments/subscription-seat';
import { WorkerService } from '@mero/api-sdk/dist/services';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';
import * as React from 'react';

import { clone } from '../../utils/objects';

export const CALENDAR_INTERVAL_DEFAULT = 6;
export const MAX_APPOINTMENTS_PER_CLIENT_DEFAULT = 3;
export const MIN_HOURS_BEFORE_CLIENTS_CAN_CANCEL_APPOINTMENTS_DEFAULT = 1;
export const MIN_HOURS_BEFORE_TODAY_APPOINTMENTS_DEFAULT = 0;
export const MAX_WAITING_LIST_PER_CLIENT = 10;

const Details = t.type({
  firstname: t.string,
  lastname: t.string,
  phone: t.string,
  email: t.string,
  profilePhoto: t.string,
  invitePro: t.boolean,
  addNewSeat: t.boolean,
  addProInfo: t.union([
    t.intersection([
      SubscriptionSeat,
      t.type({
        expiresAt: tt.date,
        subscriptionId: SubscriptionId,
      }),
    ]),
    t.null,
  ]),
});

const Calendar = t.type({
  isActive: t.boolean,
  isPrivate: t.boolean,
  calendarInterval: t.number,
  maxAppointmentsPerClient: t.number,
  minHoursBeforeClientsCanCancelAppointments: t.number,
  minHoursBeforeTodayAppointments: t.number,
  maxWaitingListDaysPerClient: t.number,
});

const AddNewProContextState = t.intersection([
  t.type({
    details: Details,
    calendar: Calendar,
    services: t.array(WorkerService),
    businessHours: BusinessHours.JSON,
  }),
  t.partial({
    role: PageRoleOverview2.JSON,
  }),
]);

type AddNewProContextState = t.TypeOf<typeof AddNewProContextState>;

const DEFAULT_INTERVAL = {
  from: {
    hour: 9,
    minute: 0,
  },
  to: {
    hour: 17,
    minute: 0,
  },
  active: true,
  wholeDay: false,
} as WorkInterval;

const defaultState = (): AddNewProContextState => ({
  details: {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    profilePhoto: '',
    invitePro: true,
    addNewSeat: false,
    addProInfo: null,
  },
  calendar: {
    isActive: true,
    isPrivate: false,
    calendarInterval: CALENDAR_INTERVAL_DEFAULT, // https://meropunctro.atlassian.net/wiki/spaces/ME/pages/1165623297/PRO+-+Calendar+settings
    maxAppointmentsPerClient: MAX_APPOINTMENTS_PER_CLIENT_DEFAULT,
    minHoursBeforeClientsCanCancelAppointments: MIN_HOURS_BEFORE_CLIENTS_CAN_CANCEL_APPOINTMENTS_DEFAULT,
    minHoursBeforeTodayAppointments: MIN_HOURS_BEFORE_TODAY_APPOINTMENTS_DEFAULT,
    maxWaitingListDaysPerClient: MAX_WAITING_LIST_PER_CLIENT,
  },
  services: [],
  businessHours: [
    clone(DEFAULT_INTERVAL),
    clone(DEFAULT_INTERVAL),
    clone(DEFAULT_INTERVAL),
    clone(DEFAULT_INTERVAL),
    clone(DEFAULT_INTERVAL),
    clone(DEFAULT_INTERVAL),
    {
      ...clone(DEFAULT_INTERVAL),
      active: false,
    },
  ],
});

export const AddNewProContext = createModelContext(
  defaultState(),
  {
    reset() {
      return defaultState();
    },
    updateDetails(state, payload: Partial<AddNewProContextState['details']>) {
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
        },
      };
    },
    updateCalendar(state, payload: Partial<AddNewProContextState['calendar']>) {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          ...payload,
        },
      };
    },
    updateServices(state, payload: AddNewProContextState['services']) {
      return {
        ...state,
        services: payload,
      };
    },
    updateBusinessHours(state, payload: AddNewProContextState['businessHours']) {
      return {
        ...state,
        businessHours: payload,
      };
    },

    updateRole(state, payload: AddNewProContextState['role']) {
      return {
        ...state,
        role: payload,
      };
    },
  },
  (dispatch) => {
    return {
      reset: dispatch.reset,
      updateDetails: dispatch.updateDetails,
      updateCalendar: dispatch.updateCalendar,
      updateServices: dispatch.updateServices,
      updateBusinessHours: dispatch.updateBusinessHours,
      updateRole: dispatch.updateRole,
    };
  },
);

export const AddNewProContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <AddNewProContext.Provider>{children}</AddNewProContext.Provider>;
};

export const withAddNewProContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithAddNewProContextProvider(props: P) {
    return (
      <AddNewProContextProvider>
        <Content {...props} />
      </AddNewProContextProvider>
    );
  };
};
