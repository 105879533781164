import { dateStringUtils, DayTime, SavedWorker, UserId, Worker as WorkerType } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { DateWorkingSchedule } from '@mero/api-sdk/dist/pro/workingSchedule/dateWorkingSchedule';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { colors, H3s, Row, SmallBody, useShowError, styles as meroStyles } from '@mero/components';
import { capitalize } from '@mero/shared-components';
import { DateString, DateStringInterval, Hour, Minute } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import { indexOf, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';

import FormCard from '@mero/components/lib/components/FormCard';
import Column from '@mero/components/lib/components/Layout/Column';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { AppContext } from '../../../../../../contexts/AppContext';
import { meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, HomeTabsParamsList, ProsDashboardStackParamList } from '../../../../../../types';
import { logCatch } from '../../../../../../utils/log';
import { dayTimeSum, DEFAULT_TIMEZONE, intervalDiff, intervalToString, isSameDay } from '../../../../../../utils/time';
import AddNew from './AddNew';
import ScheduleButton from './ScheduleButton';
import { WorkerAndMember } from './ScheduleMobile';
import Worker from './Worker';

type ScheduleRecord = DateWorkingSchedule['records'][number];

type WorkerSchedule = {
  pro: WorkerAndMember;
  schedule: Record<DateWorkingSchedule['date'], ScheduleRecord[]>;
};

type WorkerScheduleWithTotal = WorkerSchedule & { total: DayTime };

export const DEFAULT_DAY_TIME: DayTime = { hour: 0 as Hour, minute: 0 as Minute };

type Props = { day: DateTime } & CurrentBusinessProps;

const ScheduleWeb: React.FC<Props> = ({ page, day }) => {
  const { t } = useTranslation('pros');
  const showError = useShowError();
  const isFocused = useIsFocused();

  const [appState] = AppContext.useContext();

  const changePastWorkingSchedule = React.useMemo(
    () => appState.type === 'Loaded' && appState.featureFlags.changePastWorkingSchedule?.enabled,
    [appState],
  );

  const navigation =
    useNavigation<NavigationProp<AuthorizedStackParamList & HomeTabsParamsList & ProsDashboardStackParamList>>();

  const lastRequest = React.useRef<symbol>();

  const [isLoading, setIsLoading] = React.useState(false);
  const [schedule, setSchedule] = React.useState<WorkerScheduleWithTotal[]>([]);

  const now = React.useMemo(() => DateTime.now().setZone(DEFAULT_TIMEZONE).startOf('day'), []);

  const weekDays = React.useMemo(() => Array.from({ length: 7 }, (_, i) => day.plus({ day: i })), [day]);

  const pros: WorkerAndMember[] = React.useMemo(() => {
    const workersByUserId: Record<UserId, SavedWorker> = page.workers.reduce(
      (acc: Record<UserId, SavedWorker>, worker) => ({ ...acc, [worker.user._id]: worker }),
      {},
    );

    const workersIds = page.workers.map((worker) => worker._id);

    const workers = page.members
      .filter((member) => workersByUserId[member.user._id])
      .map((member) => ({
        worker: workersByUserId[member.user._id],
        member,
      }))
      .filter((member) => member.worker);

    return sortBy(workers, (item) => indexOf(workersIds, item.worker._id));
  }, [page.details._id, page.members, page.workers]);

  const loadSchedule = async () => {
    setIsLoading(true);
    try {
      const request = Symbol('');
      lastRequest.current = request;
      const interval = {
        from: day.toFormat('yyyy-MM-dd'),
        to: day.plus({ days: 6 }).toFormat('yyyy-MM-dd'),
      };

      if (!DateStringInterval.JSON.is(interval)) {
        throw new Error(t('invalidInterval'));
      }

      const workersSchedule = await meroApi.pro.workingSchedule
        .getWorkersSchedules({
          pageId: page.details._id,
          interval,
          workerIds: undefined,
        })
        .catch(logCatch('getWorkersSchedules'));

      const data = workersSchedule.reduce((acc, workerSchedule) => {
        const pro = pros.find((p) => p.worker._id === workerSchedule.workerId);
        if (!pro) {
          return acc;
        }

        return [
          ...acc,
          {
            pro,
            total: workerSchedule.days.reduce(
              (acc, day) =>
                dayTimeSum(
                  acc,
                  day.records.reduce(
                    (acc, record) =>
                      record.type === 'Schedule' && record.schedule.active
                        ? dayTimeSum(
                            acc,
                            record.schedule.intervals.reduce(
                              (acc, interval) => dayTimeSum(acc, intervalDiff(interval)),
                              DEFAULT_DAY_TIME,
                            ),
                          )
                        : acc,
                    DEFAULT_DAY_TIME,
                  ),
                ),
              DEFAULT_DAY_TIME,
            ),
            schedule: workerSchedule.days.reduce(
              (acc, day) => ({ ...acc, [day.date]: day.records }),
              {} as WorkerSchedule['schedule'],
            ),
          },
        ];
      }, [] as WorkerScheduleWithTotal[]);

      setSchedule(data);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      loadSchedule();
    }
  }, [day, isFocused]);

  const onAddNewInterval = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'new_interval' as const } });
  };

  const onEditInterval = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'edit_interval' as const } });
  };

  const onAddNewSchedule = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'new_schedule' as const } });
  };

  const onEditSchedule = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'edit_schedule' as const } });
  };

  const onAddBlockTime = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Booking', {
      screen: 'BlockedTimeCreateScreen',
      params: { date: DateString.toDateTime(payload.day, DEFAULT_TIMEZONE).toISO(), workerId: payload.workerId },
    });
  };

  const onEditBlockTime = (payload: { day: DateString; appointmentId: AppointmentId; occurrenceIndex: number }) => {
    navigation.navigate('Booking', {
      screen: 'BlockedTimeEditScreen',
      params: {
        calendarId: undefined,
        calendarEntryId: payload.appointmentId,
        occurrenceIndex: `${payload.occurrenceIndex}`,
        start: DateString.toDateTime(payload.day, DEFAULT_TIMEZONE).toISO(),
      },
    });
  };

  return (
    <>
      <FormCard
        dropShaddow
        rounded
        style={{ padding: 0, marginHorizontal: 24, marginBottom: 24, maxHeight: '100%', flex: 1 }}
      >
        <FormCard
          dropShaddow
          rounded
          style={[{ padding: 0, margin: 24 }, Platform.OS === 'web' ? styles.webOnly : { flex: 1 }]}
        >
          {/* Headers */}
          <Column style={[styles.header]}>
            <Row flex={1}>
              <Column style={[styles.columnName]}>
                <H3s>{t('schedule')}</H3s>
              </Column>
              {weekDays.map((day) => {
                const isToday = isSameDay(day, now);
                return (
                  <Column key={day.toISO()} style={[styles.columnDay, styles.columnDayHeader]}>
                    <SmallBody style={[{ fontSize: 13 }, isToday && styles.today]}>
                      {capitalize(day.toFormat('ccc'))}
                    </SmallBody>
                    <SmallBody style={[meroStyles.text.semibold, { fontSize: 15 }, isToday && styles.today]}>
                      {day.toFormat('dd')}
                    </SmallBody>
                  </Column>
                );
              })}
            </Row>
          </Column>

          {/* Table content */}
          <ScrollView showsVerticalScrollIndicator={false}>
            {schedule.map(({ pro, total, schedule }, index) => {
              const isInvited = WorkerType.isInvited(pro.worker, page.details);
              return (
                <Row key={pro.worker._id}>
                  <Column style={[styles.columnName, styles.cell, styles.firstCell, index === 0 && styles.firstRow]}>
                    <Worker
                      firstname={pro.worker.user.firstname}
                      lastname={pro.worker.user.lastname}
                      role={pro.member.roles[0].name}
                      isInvited={isInvited}
                      subtitle={t(
                        total.minute !== 0 || total.hour !== 0
                          ? total.minute !== 0
                            ? 'dayTimeWithMin'
                            : 'dayTime'
                          : 'noSchedule',
                        {
                          hour: total.hour,
                          minute: total.minute,
                        },
                      )}
                      profileImage={pro.worker.profilePhoto?.thumbnail}
                    />
                  </Column>
                  {weekDays.map((day) => {
                    const dayString = DateString.fromDate(day.toJSDate(), DEFAULT_TIMEZONE);
                    const records = DateString.JSON.is(dayString) && schedule[dayString] ? schedule[dayString] : [];
                    const isPast = day < now;

                    const hasSchedule = records.some(
                      (r) => (r.type === 'Schedule' && r.schedule.active) || r.type === 'BlockedTime',
                    );

                    return (
                      <Column key={dayString} style={[styles.columnDay, styles.cell, index === 0 && styles.firstRow]}>
                        {!hasSchedule && <SmallBody style={styles.noSchedule}>{t('noSchedule')}</SmallBody>}
                        {records.map((record, recordIndex) => {
                          switch (record.type) {
                            case 'Schedule':
                              return record.schedule.active
                                ? record.schedule.intervals.map((interval, index) => (
                                    <React.Fragment key={`${interval.from.hour}_${index}`}>
                                      {record.schedule.active && index > 0 && <Spacer size={4} />}
                                      <ScheduleButton
                                        page={page.details}
                                        day={dayString}
                                        pro={pro}
                                        isPast={isPast}
                                        schedule={record}
                                        interval={interval}
                                        onEditInterval={() => {
                                          onEditInterval({ workerId: pro.worker._id, day: dayString });
                                        }}
                                        onEditSchedule={() =>
                                          onEditSchedule({
                                            workerId: pro.worker._id,
                                            day: dayString,
                                          })
                                        }
                                        onDeleteSchedule={loadSchedule}
                                      />
                                    </React.Fragment>
                                  ))
                                : null;
                            case 'BlockedTime':
                              return (
                                <React.Fragment key={record.blockedTime._id}>
                                  {recordIndex > 0 && <Spacer size={4} />}
                                  <TouchableOpacity
                                    key={record.blockedTime._id}
                                    style={[styles.blockedTime]}
                                    onPress={() =>
                                      onEditBlockTime({
                                        day: dayString,
                                        appointmentId: record.blockedTime._id,
                                        occurrenceIndex: record.blockedTime.occurrenceIndex ?? 0,
                                      })
                                    }
                                  >
                                    <SmallBody style={[styles.blockedTimeText]}>
                                      {intervalToString(record.blockedTime.interval)}
                                    </SmallBody>
                                  </TouchableOpacity>
                                </React.Fragment>
                              );
                          }
                        })}
                        {!isPast || changePastWorkingSchedule ? (
                          <>
                            <Spacer size={4} />
                            <AddNew
                              isInvited={WorkerType.isInvited(pro.worker, page.details)}
                              isHovered={true}
                              onAddNewInterval={() => onAddNewInterval({ workerId: pro.worker._id, day: dayString })}
                              onAddNewSchedule={() => onAddNewSchedule({ workerId: pro.worker._id, day: dayString })}
                              onBlockTime={() => onAddBlockTime({ workerId: pro.worker._id, day: dayString })}
                            />
                          </>
                        ) : null}
                      </Column>
                    );
                  })}
                </Row>
              );
            })}
          </ScrollView>
        </FormCard>
      </FormCard>
    </>
  );
};

const styles = StyleSheet.create({
  columnName: {
    width: '23%',
    padding: 16,
    // justifyContent: 'center',
  },
  columnDay: {
    padding: 4,
    width: '11%',
    alignItems: 'center',
  },
  columnDayHeader: {
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  today: {
    color: colors.DARK_BLUE,
  },
  firstCell: {
    borderLeftWidth: 0,
  },
  firstRow: {
    borderTopWidth: 0,
  },
  cell: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: colors.GEYSER,
  },

  blockedTime: {
    backgroundColor: '#F8DFD1',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 6,
  },
  blockedTimeText: {
    color: colors.BLACK,
    fontSize: 12,
    marginHorizontal: 8,
    marginVertical: 4,
    ...meroStyles.text.semibold,
  },
  space: {
    marginTop: 4,
  },
  noSchedule: {
    fontSize: 12,
    color: colors.COMET,
  },
  header: {
    borderBottomWidth: 1,
    borderColor: colors.GEYSER,
  },
  webOnly: {
    //@ts-expect-error not a react-native style
    maxHeight: 'calc(100% - 48px)',
  },
});

export default pipe(ScheduleWeb, CurrentBusiness);
