import { colors, styles as meroStyles } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  schedule: {
    backgroundColor: colors.SKY_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: '100%',
    borderRadius: 6,
  },
  disabledAction: {
    backgroundColor: colors.ATHENS_GRAY,
  },
  disabledText: {
    color: colors.COMET,
  },
  scheduleText: {
    color: colors.DARK_BLUE,
    fontSize: 14,
    marginHorizontal: 8,
    marginVertical: 4,
    ...meroStyles.text.semibold,
  },
  blockedTime: {
    backgroundColor: '#F8DFD1',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: '100%',
    borderRadius: 6,
  },
  blockedTimeText: {
    color: colors.BLACK,
    fontSize: 14,
    marginHorizontal: 8,
    marginVertical: 4,
    ...meroStyles.text.semibold,
  },
  space: {
    marginTop: 8,
  },
});
