import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import AddProPermissionsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddNewProPermissionsScreen';
import AddNewProScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddNewProScreen';
import AddProCalendarSettingsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddProCalendarSettingsScreen';
import AddProEditServiceScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddProEditServiceScreen';
import AddProEditServiceSettingsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddProEditServiceSettingsScreen';
import AddProScheduleScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddProScheduleScreen';
import AddProServicesScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddProServicesScreen';
import AddProServicesSelectScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/AddProServicesSelectScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withAddNewProContextProvider } from '../contexts/AddNewProContext';
import { ProsSettingsAddNewStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<ProsSettingsAddNewStackParamList>();

function ProsSettingsAddNewStackNavigator(): React.ReactElement {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <Stack.Navigator
      initialRouteName="ProAddNew"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProAddNew"
        component={AddNewProScreen}
        options={{ title: t('addPro'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProAddNewPermissions"
        component={AddProPermissionsScreen}
        options={{ title: t('proPermissions'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProAddNewCalendar"
        component={AddProCalendarSettingsScreen}
        options={{ title: t('calendarSettings'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProAddNewServices"
        component={AddProServicesScreen}
        options={{ title: t('proServices'), cardStyle: modalStyle }}
      />

      <Stack.Screen
        name="ProAddNewServicesSelect"
        component={AddProServicesSelectScreen}
        options={{ title: t('selectServices'), cardStyle: modalStyle }}
      />

      <Stack.Screen
        name="ProAddNewEditService"
        component={AddProEditServiceScreen}
        options={{ title: t('proServices'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProAddNewEditServiceSettings"
        component={AddProEditServiceSettingsScreen}
        options={{ title: t('profileCalendar'), cardStyle: modalStyle }}
      />

      {/*<Stack.Screen*/}
      {/*  name="ProAddNewSchedule"*/}
      {/*  component={AddProScheduleScreen}*/}
      {/*  options={{ title: t('proSchedule'), cardStyle: modalStyle }}*/}
      {/*/>*/}
    </Stack.Navigator>
  );
}

export default withAddNewProContextProvider(ProsSettingsAddNewStackNavigator);
