import { Body, Checkbox, Column, ConfirmBox, H1, HSpacer, Line, ModalOverlay, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { DayLabels } from './WeekView';

type Props = {
  readonly day: number;
  readonly onConfirm: (selectedDays: Record<number, boolean>) => void;
  readonly onDismiss: () => void;
};

const CopyDayModal: React.FC<Props> = ({ day, onConfirm, onDismiss }) => {
  const { t } = useTranslation('pros');

  const [selectedDays, setSelectedDays] = React.useState<Record<number, boolean>>({ [day]: true });

  const onSavePress = () => {
    onConfirm(selectedDays);
  };

  const leftAction = {
    text: t('cancelButton'),
    onPress: onDismiss,
    flex: 10,
  };

  const rightAction = {
    text: t('save'),
    onPress: onSavePress,
    flex: 15,
  };

  const toggleSelectedDay = (index: number) => () => {
    setSelectedDays({ ...selectedDays, [index]: !selectedDays[index] });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Column style={{ maxWidth: 468, width: '100%' }}>
        <ConfirmBox
          type="info"
          headerTitle={t('copyDayTitle')}
          onClose={onDismiss}
          leftAction={leftAction}
          rightAction={rightAction}
          canClose
        >
          <H1>{t('copyDayTitle')}</H1>
          <Spacer size="8" />
          <Body>{t('copyDayDescription', { day: DayLabels[day].toLowerCase() })}</Body>
          <Spacer size={8} />
          <Column>
            {DayLabels.map((label, index) => (
              <React.Fragment key={label}>
                <TouchableOpacity
                  disabled={index === day}
                  style={{ flexDirection: 'row', paddingVertical: 16 }}
                  onPress={toggleSelectedDay(index)}
                >
                  <Checkbox
                    value={selectedDays[index]}
                    onValueChange={toggleSelectedDay(index)}
                    disabled={index === day}
                  />
                  <HSpacer left={8} />
                  <Title>{label}</Title>
                </TouchableOpacity>
                {index < DayLabels.length - 1 && <Line />}
              </React.Fragment>
            ))}
          </Column>
          <Spacer size={8} />
        </ConfirmBox>
      </Column>
    </ModalOverlay>
  );
};

export default CopyDayModal;
