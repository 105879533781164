import { PageRoleId, PageRoleOverview2 } from '@mero/api-sdk/dist/pages';
import {
  Avatar,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  SafeAreaView,
  Select,
  Spacer,
} from '@mero/components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, StyleSheet } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import PermissionsCarousel from '../../../../../components/PermissionsCarousel/PermissionsCarousel';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import { SelectItem } from '@mero/components/lib/components/Select';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNewPermissions'>;

const AddProPermissionsScreen: React.FC<Props> = () => {
  const [{ role, details }, { updateRole, updateCalendar }] = AddNewProContext.useContext();
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('pros');
  const [pageState] = CurrentBusinessContext.useContext();

  const scrollRef = useRef<ScrollView | null>(null);
  const [newRole, setNewRole] = useState<PageRoleId | undefined>(role?.id);
  const [roles, setRoles] = useState<SelectItem<PageRoleId>[]>([]);
  const [rolesPermissions, setRolesPermissions] = useState<PageRoleOverview2[]>([]);

  useEffect(() => {
    if (pageState.type === 'Loaded') {
      const roles = pageState.page.roles;
      setRoles(roles.map((role) => ({ label: role.name, value: role.id })));
      setRolesPermissions(pageState.page.roles);
    }
  }, [pageState.type]);

  const saveChanges = async () => {
    const roleDetails = rolesPermissions.find((role) => role.id === newRole);

    if (roleDetails) {
      updateRole(roleDetails);
      updateCalendar({
        isActive: roleDetails.isWorkerRole,
      });
    }

    goBack();
  };

  return (
    <ModalScreenContainer style={styles.container}>
      <Header
        LeftComponent={() => (
          <Pressable onPress={goBack} style={styles.goBackButton}>
            <Icon type="back" />
          </Pressable>
        )}
        text={t('proPermissions')}
        RightComponent={() => (
          <Column style={styles.headerAvatarWrapper}>
            <Avatar size={32} source={details.profilePhoto} firstname={details.firstname} lastname={details.lastname} />
          </Column>
        )}
      />
      <ScrollView ref={scrollRef}>
        <Column style={styles.dropdownContainer}>
          <H1 style={styles.heading}>{t('proPermissions')}</H1>
          <Spacer size={24} />
          <FormCard rounded>
            <InputWithLabel label={t('accessType')}>
              {roles.length > 0 && <Select items={roles} value={newRole} onChange={(role) => setNewRole(role)} />}
            </InputWithLabel>
          </FormCard>
        </Column>
        <Spacer size="16" />
        <PermissionsCarousel roles={roles} rolesPermissions={rolesPermissions} role={newRole} setRole={setNewRole} />
        <Spacer size={92} />
      </ScrollView>
      <FormCard dropShaddow paddings="button" style={[!isPhone && styles.modalBorderBottom, styles.footer]}>
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('done')} onPress={saveChanges} />
          ) : (
            <Button expand={false} containerStyle={styles.button} text={t('done')} onPress={saveChanges} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: colors.ALABASTER },
  goBackButton: { paddingLeft: 16 },
  headerAvatarWrapper: { paddingRight: 24 },
  dropdownContainer: { paddingHorizontal: 16, paddingTop: 16, flex: 1 },
  heading: { paddingHorizontal: 8 },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  button: { alignSelf: 'center' },
});

export default AddProPermissionsScreen;
