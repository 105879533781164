import { WorkerId } from '@mero/api-sdk';
import {
  Body,
  colors,
  Column,
  Label,
  Line,
  ModalOverlay,
  Row,
  SafeAreaView,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
} from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { CurrentBusiness, CurrentBusinessProps } from '../../../../../../contexts/CurrentBusiness';
import { ScheduleContext } from '../../../../../../contexts/ScheduleContext';

const TitleIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6084">
      <Path
        fill="#fff"
        d="M10.976 14.677h1.85v1.85h-1.85Zm0-7.4h1.85v5.551h-1.85Zm.916-4.627a9.252 9.252 0 1 0 9.258 9.252 9.247 9.247 0 0 0-9.258-9.252Zm.009 16.653a7.4 7.4 0 1 1 7.4-7.4 7.4 7.4 0 0 1-7.399 7.4Z"
      />
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 1487" />
    </G>
  </Svg>
);

type Props = {
  readonly workerId: WorkerId;
  readonly onDonotOverrideExceptions: () => void;
  readonly onOverrideExceptions: () => void;
  readonly onDismiss: () => void;
  readonly type: 'delete' | 'save';
} & CurrentBusinessProps;

const ConfirmOverlapExceptionsModal: React.FC<Props> = ({
  onOverrideExceptions,
  onDonotOverrideExceptions,
  onDismiss,
  type,
}) => {
  const { t } = useTranslation('pros');

  return (
    <ModalOverlay>
      <TouchableWithoutFeedback onPress={onDismiss}>
        <View style={{ flex: 1, alignSelf: 'stretch', justifyContent: 'flex-end', backgroundColor: 'transparent' }} />
      </TouchableWithoutFeedback>
      <SafeAreaView
        edges={['bottom']}
        style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, overflow: 'hidden', backgroundColor: colors.WHITE }}
      >
        <Row
          alignItems="center"
          style={{
            backgroundColor: type === 'save' ? colors.YELLOW_ORANGE : colors.RADICAL_RED,
            paddingHorizontal: 24,
            paddingVertical: 8,
          }}
        >
          <TitleIcon />
          <Label style={[{ paddingLeft: 8, color: colors.WHITE }, meroStyles.text.semibold]}>
            {type === 'save' ? t('importantAction') : t('irreversibleAction')}
          </Label>
        </Row>
        <Column style={[{ backgroundColor: colors.WHITE }, styles.padding]}>
          <Title>{type === 'save' ? t('confirmSaveWithOverrides') : t('confirmDeleteWithOverrides')}</Title>
          <Spacer size={4} />
          <SmallBody>
            <Trans ns={'pros'} t={t} i18nKey="confirmOverrideText">
              <SmallBody style={meroStyles.text.semibold}>0</SmallBody>1
            </Trans>
          </SmallBody>
        </Column>
        <Line />
        <TouchableOpacity style={styles.padding}>
          <Body
            style={[meroStyles.text.semibold, type === 'delete' && styles.errorButton]}
            onPress={onDonotOverrideExceptions}
          >
            {type === 'save' ? t('saveWithoutOverrides') : t('deleteWithoutOverrides')}
          </Body>
        </TouchableOpacity>
        <Line />
        <TouchableOpacity style={styles.padding}>
          <Body
            style={[meroStyles.text.semibold, type === 'delete' ? styles.errorButton : styles.warnButton]}
            onPress={onOverrideExceptions}
          >
            {type === 'save' ? t('saveWithOverrides') : t('deleteWithOverrides')}
          </Body>
        </TouchableOpacity>
      </SafeAreaView>
    </ModalOverlay>
  );
};

const styles = StyleSheet.create({
  warnButton: {
    color: colors.YELLOW_ORANGE,
  },
  errorButton: {
    color: colors.RADICAL_RED,
  },
  padding: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
});

export default pipe(ConfirmOverlapExceptionsModal, CurrentBusiness);
