import { OnlinePaymentsPayoutPreview, PageId, ScaledNumber, UserOrderPaymentPreview } from '@mero/api-sdk';
import {
  colors,
  Icon,
  Title,
  Header,
  Spacer,
  FormCard,
  Body,
  styles as meroStyles,
  Row,
  Line,
  Column,
  SmallBody,
  Button,
  useShowError,
  useToast,
  Switch,
  sizes,
  H1,
  HSpacer,
  H2s,
  SafeAreaView,
  ModalOverlay,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView, FlatList, View, Linking, Pressable } from 'react-native';
import Svg, { Path, G, Circle, SvgProps } from 'react-native-svg';

import InfiniteScrollView from '../../../../../components/InfiniteScrollView';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import {
  AccountBalanceContext,
  withAccountBalanceContextProvider,
} from '../../../../../contexts/AccountBalanceContext';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { AuthorizedStackParamList, PageOnlinePaymentsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { scaledToString } from '../../../../../utils/scaled';
import { nameGenerator } from '../../../../../utils/string';
import ActiveIntervalView, { getMonthIntervals, Interval } from '../PageReportsScreen/ActiveIntervalView';
import { getPayoutFrequency } from './PageOnlinePaymentsSettingsScreen';
import { styles } from './styles';

type Props = {
  navigation: StackNavigationProp<
    AuthorizedStackParamList & PageOnlinePaymentsStackParamList,
    'PageOnlinePaymentsBalance'
  >;
};

const NewIcon = (props: SvgProps) => (
  <Svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <Path d="M16 0A16 16 0 110 16 16 16 0 0116 0z" fill="#e9ecef" />
    <G data-name="Group 6846">
      <Path data-name="Rectangle 2638" fill="none" transform="translate(7 7)" d="M0 0H18V18H0z" />
    </G>
    <G
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      transform="translate(7 7)"
    >
      <Circle cx={7} cy={7} r={7} transform="translate(2 2)" />
      <Path d="M9 3.867v5.367l3.033 1.633" />
    </G>
  </Svg>
);

const FailureIcon = (props: SvgProps) => (
  <Svg data-name="Group 8649" width={32} height={32} viewBox="0 0 32 32" {...props}>
    <Circle cx={16} cy={16} r={16} fill="#fce8ec" />
    <G data-name="Group 6846">
      <Path data-name="Rectangle 2638" fill="none" transform="translate(7 7)" d="M0 0H18V18H0z" />
    </G>
    <Path
      d="M14 4L4 14m10 0L4 4"
      fill="none"
      stroke="#f5365c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      transform="translate(7 7)"
    />
  </Svg>
);

const SuccessIcon = (props: SvgProps) => (
  <Svg data-name="Group 8648" width={32} height={32} viewBox="0 0 32 32" {...props}>
    <Path d="M16 0A16 16 0 110 16 16 16 0 0116 0z" fill="#e9faf3" />
    <G data-name="Group 6846">
      <Path data-name="Rectangle 2638" fill="none" transform="translate(7 7)" d="M0 0H18V18H0z" />
    </G>
    <Path
      d="M3 8.6L7.2 13 15 5"
      fill="none"
      stroke="#2dce89"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      transform="translate(7 7)"
    />
  </Svg>
);

const ReturnedIcon = (props: SvgProps) => (
  <Svg data-name="Group 5901" width={32} height={32} viewBox="0 0 32 32" {...props}>
    <Circle cx={16} cy={16} r={16} fill="#e9ecef" />
    <G data-name="Group 6846">
      <Path data-name="Rectangle 2638" fill="none" transform="translate(7 7)" d="M0 0H18V18H0z" />
    </G>
    <G
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
    >
      <Path
        d="M3.905 12.723a6.067 6.067 0 111.737 3.6M2 9.937l1.9 2.786 2.523-2.32"
        transform="translate(7 7) translate(-1 -3.032)"
      />
    </G>
  </Svg>
);

const PageOnlinePaymentsBalanceScreen: React.FC<Props> = ({ navigation }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('accountBalance');
  const showError = useShowError();
  const toast = useToast();
  const { isPhone } = useMediaQueries();

  const [accountBalanceState] = AccountBalanceContext.useContext();
  const [onlinePaymentsState] = OnlinePaymentsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const [selectedTab, setSelectedTab] = React.useState<'transactions' | 'finishedTransfers'>('transactions');
  const [activeInterval, setActiveInterval] = React.useState<Interval>(getMonthIntervals()[0]);
  const [payoutsInterval, setPayoutsInterval] = React.useState<Interval>(getMonthIntervals()[0]);
  const [payments, setPayments] = React.useState<UserOrderPaymentPreview[]>([]);
  const [payouts, setPayouts] = React.useState<OnlinePaymentsPayoutPreview[]>([]);

  const [paymentsPage, setPaymentsPage] = React.useState<string>();
  const [payoutsPage, setPayoutsPage] = React.useState<string>();
  const [showTotalsTooltip, setShowTotalsTooltip] = React.useState(false);
  const [showPaymentStatusTooltip, setShowPaymentStatusTooltip] = React.useState(false);
  const [showPayoutStatusTooltip, setShowPayoutStatusTooltip] = React.useState(false);

  const navigateOnlinePayments = React.useCallback(() => {
    navigation.navigate('PageOnlinePaymentsSettings');
  }, [navigation]);

  const renderStatusIcon = (status: string) => {
    switch (status) {
      case 'new':
      case 'started':
      case 'pending':
      case 'in_transit':
        return <NewIcon />;
      case 'succeeded':
      case 'paid':
        return <SuccessIcon />;
      case 'canceled':
      case 'failed':
        return <FailureIcon />;
      case 'partiallyRefunded':
      case 'refunded':
        return <ReturnedIcon />;
      default:
        return <NewIcon />;
    }
  };

  const renderStatusColor = (status: string) => {
    switch (status) {
      case 'new':
      case 'started':
      case 'pending':
      case 'in_transit':
      case 'partiallyRefunded':
      case 'refunded':
        return colors.COMET;
      case 'succeeded':
      case 'paid':
        return colors.SHAMROCK;
      case 'failed':
      case 'canceled':
        return colors.RADICAL_RED;
      default:
        return colors.COMET;
    }
  };

  const renderPayment = (payment: UserOrderPaymentPreview, isLast: boolean) => {
    const status = payment.status.type;
    const appointment = payment.summary?.item.type === 'Appointment' ? payment.summary.item : undefined;
    return (
      <View key={payment._id}>
        <TouchableOpacity
          style={{ padding: 16, flexDirection: 'row' }}
          onPress={() =>
            appointment &&
            navigation.navigate('Booking', {
              screen: 'BookingDetailsScreen',
              params: {
                calendarId: undefined,
                calendarEntryId: appointment.appointmentId,
                occurrenceIndex: appointment.occurrenceIndex?.toString() ?? '0',
              },
            })
          }
          disabled={!appointment}
        >
          {renderStatusIcon(status)}
          <HSpacer left={16} />
          <Column style={{ flex: 1 }}>
            <SmallBody style={[meroStyles.text.semibold, { fontSize: 12, color: renderStatusColor(status) }]}>
              {t(status)}
            </SmallBody>
            {status === 'partiallyRefunded' ? (
              <Row alignItems="flex-end">
                <H2s>
                  {payment.refunds
                    ? `${scaledToString(payment.refunds.amount)} ${t(payment.refunds.unit)}`
                    : '0.00 lei'}
                </H2s>
                <SmallBody style={[meroStyles.text.semibold, { paddingBottom: 2 }]}>
                  {' '}
                  / {scaledToString(payment.amount.amount)} {t(payment.amount.unit)}
                </SmallBody>
              </Row>
            ) : (
              <H2s>
                {scaledToString(payment.amount.amount)} {t(payment.amount.unit)}
              </H2s>
            )}

            {payment.summary && (
              <>
                <Spacer size={2} />
                <SmallBody>{payment.summary?.item.title}</SmallBody>
              </>
            )}

            <Spacer size={6} />
            <SmallBody style={{ color: colors.COMET }}>
              {nameGenerator(payment.byUser, t('unknownUser'))} ({' '}
              {DateTime.fromJSDate(payment.paidAt).setLocale('ro').toFormat('dd MMM yyyy, HH:mm').toLowerCase()})
            </SmallBody>
          </Column>
          {appointment ? <Icon type="next" color={colors.DARK_BLUE} /> : null}
        </TouchableOpacity>
        {!isLast && <Line />}
      </View>
    );
  };

  const renderPayout = (payout: OnlinePaymentsPayoutPreview, isLast: boolean) => {
    const status = payout.status;
    return (
      <View key={payout.id}>
        <Row style={{ padding: 16 }}>
          {renderStatusIcon(status)}
          <HSpacer left={16} />
          <Column>
            <SmallBody style={[meroStyles.text.semibold, { fontSize: 12, color: renderStatusColor(status) }]}>
              {t(status)}
            </SmallBody>
            <H2s>
              {scaledToString(payout.amount.amount)} {t(payout.amount.unit)}
            </H2s>
            <Spacer size={2} />
            {payout.destination && (
              <SmallBody>{t('destinationAccount', { last4: payout.destination.last4 })}</SmallBody>
            )}
            <Spacer size={6} />
            <SmallBody style={{ color: colors.COMET }}>
              {t('transferredAt')}{' '}
              {DateTime.fromJSDate(payout.createdAt).setLocale('ro').toFormat('dd MMM yyyy, HH:mm').toLowerCase()}
            </SmallBody>
          </Column>
        </Row>
        {!isLast && <Line />}
      </View>
    );
  };

  const listPagePayments = async (pageId: PageId, isNewSearch = false) => {
    try {
      const result = await meroApi.pro.onlinePayments.listPagePayments({
        pageId: pageId,
        createdAt: {
          gte: activeInterval.value.start.toJSDate(),
          lte: activeInterval.value.end.toJSDate(),
        },
        page: isNewSearch ? undefined : paymentsPage,
      });

      return result;
    } catch {}
  };

  const listPayouts = async (pageId: PageId) => {
    try {
      const result = await meroApi.pro.onlinePayments.listPayouts({
        pageId: pageId,
        page: payoutsPage,
        createdAt: {
          gte: payoutsInterval.value.start.toJSDate(),
          lte: payoutsInterval.value.end.toJSDate(),
        },
      });
      return result;
    } catch {}
  };

  React.useEffect(() => {
    const loadData = async () => {
      if (pageState.type !== 'Loaded') {
        return;
      }
      const result = await listPagePayments(pageState.page.details._id);
      if (result) {
        setPayments(result.data);
        setPaymentsPage(result.next);
      }
    };
    loadData();
  }, [pageState.type === 'Loaded' && pageState.page.details._id, activeInterval.value]);

  React.useEffect(() => {
    const loadData = async () => {
      if (pageState.type !== 'Loaded') {
        return;
      }
      const result = await listPayouts(pageState.page.details._id);
      if (result) {
        setPayouts(result.data);
        setPayoutsPage(result.next);
      }
    };
    loadData();
  }, [pageState.type === 'Loaded' && pageState.page.details._id, payoutsInterval.value]);

  const loadMorePayments = async () => {
    if (paymentsPage !== undefined && pageState.type === 'Loaded') {
      const result = await listPagePayments(pageState.page.details._id);
      if (result) {
        setPayments((prev) => [...prev, ...result.data]);
        setPaymentsPage(result.next);
      }
    }
  };

  React.useEffect(() => {
    if (onlinePaymentsState.type === 'Failed') {
      showError(undefined, t('failedToLoadOnlinePayments'));
    }
  }, [onlinePaymentsState.type]);

  const loadMorePayouts = async () => {
    if (payoutsPage !== undefined && pageState.type === 'Loaded') {
      const result = await listPayouts(pageState.page.details._id);
      if (result) {
        setPayouts((prev) => [...prev, ...result.data]);
        setPayoutsPage(result.next);
      }
    }
  };

  const exportPagePayments = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { downloadUrl } = await meroApi.pro.onlinePayments.exportPagePayments({
        pageId: pageState.page.details._id,
        createdAt: {
          gte: activeInterval.value.start.toJSDate(),
          lte: activeInterval.value.end.toJSDate(),
        },
        format: 'csv',
      });

      Linking.openURL(downloadUrl).catch((error) => {
        showError(error.message);
      });
    } catch (error) {
      log.error('Failed to download payments', error);
      showError(undefined, t('failedToDownloadPayments'));
    }
  };

  const createPayout = async () => {
    if (pageState.type !== 'Loaded' || accountBalanceState.type !== 'Loaded') {
      return;
    }

    try {
      await meroApi.pro.onlinePayments.createPayout({
        pageId: pageState.page.details._id,
        amount: accountBalanceState.accountBalance.availableTotal,
      });
      toast.show({
        type: 'success',
        text: t('payoutSuccess'),
      });
    } catch {
      showError(t('errorUnknown'));
    }
  };

  const GeneralStatsComponent = () => (
    <Column style={{ paddingHorizontal: 16 }}>
      <Spacer size={16} />
      <FormCard dropShaddow paddings="none" rounded>
        <Column style={{ padding: 16 }}>
          <Row style={{ flex: 1, justifyContent: 'space-between' }}>
            <Row alignItems="center">
              <Body style={meroStyles.text.bold}>{t('total')}</Body>
              <HSpacer left={8} />
              <TouchableOpacity onPress={() => setShowTotalsTooltip(true)}>
                <Icon type="info" />
              </TouchableOpacity>
            </Row>
            <Body style={meroStyles.text.bold}>
              {scaledToString(accountBalanceState.accountBalance.total.amount)}{' '}
              {t(accountBalanceState.accountBalance.total.unit)}
            </Body>
          </Row>

          <Spacer size={16} />
          <Row style={{ flex: 1, justifyContent: 'space-between' }}>
            <Body>{t('pendingTotal')}</Body>
            <Body>
              {scaledToString(accountBalanceState.accountBalance.pendingTotal.amount)}{' '}
              {t(accountBalanceState.accountBalance.pendingTotal.unit)}
            </Body>
          </Row>

          <Spacer size={16} />
          <Row style={{ flex: 1, justifyContent: 'space-between' }}>
            <Body>{t('availableTotal')}</Body>
            <Body>
              {scaledToString(accountBalanceState.accountBalance.availableTotal.amount)}{' '}
              {t(accountBalanceState.accountBalance.availableTotal.unit)}
            </Body>
          </Row>
        </Column>

        <Line />
        <Column style={{ padding: 16 }}>
          {onlinePaymentsState.status?.type === 'Enabled' &&
            onlinePaymentsState.status.payout.schedule?.type === 'Manual' &&
            onlinePaymentsState.status.payout.destination && (
              <>
                <SmallBody style={meroStyles.text.semibold}>{t('manualPayout')}</SmallBody>
                <Spacer size={4} />
                <SmallBody style={{ color: colors.COMET }}>
                  {t('manualPayoutDesc', {
                    last4: onlinePaymentsState.status.payout.destination.last4,
                  })}
                </SmallBody>
                <Spacer size={16} />
                <Button
                  onPress={createPayout}
                  text={t('createPayout', {
                    amount: scaledToString(accountBalanceState.accountBalance.availableTotal.amount),
                    unit: accountBalanceState.accountBalance.availableTotal.unit,
                    last4: onlinePaymentsState.status.payout.destination.last4,
                  })}
                  color={colors.SKY_BLUE}
                  backgroundColor={colors.DARK_BLUE}
                  disabled={ScaledNumber.isZero(accountBalanceState.accountBalance.availableTotal.amount)}
                  size="medium"
                />
                <Spacer size={16} />
              </>
            )}
          {onlinePaymentsState.status?.type === 'Enabled' &&
            onlinePaymentsState.status.payout.schedule?.type !== 'Manual' &&
            onlinePaymentsState.status.payout.destination && (
              <>
                <SmallBody style={meroStyles.text.semibold}>{t('automaticPayout')}</SmallBody>
                <Spacer size={4} />
                <SmallBody style={{ color: colors.COMET }}>
                  {t('automaticPayoutDesc', {
                    last4: onlinePaymentsState.status.payout.destination.last4,
                    frequency: getPayoutFrequency(onlinePaymentsState.status.payout.schedule, t),
                  })}
                </SmallBody>
                <Spacer size={16} />
              </>
            )}

          <Button
            onPress={navigateOnlinePayments}
            text={t('onlinePaymentsSettings')}
            backgroundColor={colors.SKY_BLUE}
            color={colors.DARK_BLUE}
            size="medium"
          />
        </Column>
      </FormCard>
    </Column>
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        CenterComponent={() => <Title style={{ fontSize: 14 }}>{t('settings:onlinePaymentsBalance')}</Title>}
      />
      {selectedTab === 'transactions' && (
        <InfiniteScrollView style={{ flex: 1, flexGrow: 1 }} onEndReached={loadMorePayments}>
          <GeneralStatsComponent />

          <Spacer size={48} />
          <View
            style={{
              flex: 1,
              backgroundColor: colors.WHITE,
              minHeight:
                onlinePaymentsState.status.type === 'Enabled' && onlinePaymentsState.status.payout.schedule ? 490 : 409,
              borderEndEndRadius: 8,
              borderEndStartRadius: 8,
            }}
          >
            <Column style={{ paddingHorizontal: 16 }}>
              <Spacer size={16} />
              <Switch
                height={sizes[32]}
                textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                buttons={[
                  { value: 'transactions', label: t('transactions') },
                  {
                    value: 'finishedTransfers',
                    label: t('finishedTransfers'),
                  },
                ]}
                defaultValue="transactions"
                onChange={setSelectedTab}
              />
              <Spacer size={24} />
            </Column>

            <Column style={{ paddingHorizontal: 16 }}>
              <Row justifyContent="space-between" alignItems="center">
                <Row alignItems="center">
                  <H1>{t('transactions')}</H1>
                  <HSpacer left={6} />
                  <TouchableOpacity onPress={() => setShowPaymentStatusTooltip(true)}>
                    <Icon type="info" />
                  </TouchableOpacity>
                </Row>

                {payments.length > 0 && (
                  <TouchableOpacity onPress={exportPagePayments}>
                    <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>
                      {t('download')}
                    </SmallBody>
                  </TouchableOpacity>
                )}
              </Row>

              <Spacer size={16} />
              <Row>
                <ActiveIntervalView
                  withFullCurrentMonth
                  activeInterval={activeInterval}
                  setActiveInterval={(value) => {
                    setActiveInterval(value);
                    setPaymentsPage(undefined);
                  }}
                  showFutureDates={true}
                  fullWidth
                />
              </Row>
            </Column>
            <Spacer size={16} />

            {payments.map((payment, index) => renderPayment(payment, index === payments.length - 1))}

            {payments.length === 0 && (
              <Column alignItems="center">
                <Spacer size={32} />
                <Body style={{ textAlign: 'center', maxWidth: 250 }}>{t('noPayments')}</Body>
              </Column>
            )}
            <Spacer size={50} />
          </View>
        </InfiniteScrollView>
      )}

      {selectedTab === 'finishedTransfers' && (
        <InfiniteScrollView style={{ flex: 1, flexGrow: 1 }} onEndReached={loadMorePayouts}>
          <GeneralStatsComponent />

          <Spacer size={48} />
          <View
            style={{
              flex: 1,
              backgroundColor: colors.WHITE,
              minHeight:
                onlinePaymentsState.status.type === 'Enabled' && onlinePaymentsState.status.payout.schedule ? 490 : 409,
              borderEndEndRadius: 8,
              borderEndStartRadius: 8,
            }}
          >
            <Column style={{ paddingHorizontal: 16 }}>
              <Spacer size={16} />
              <Switch
                height={sizes[32]}
                textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                buttons={[
                  { value: 'transactions', label: t('transactions') },
                  {
                    value: 'finishedTransfers',
                    label: t('finishedTransfers'),
                  },
                ]}
                defaultValue={selectedTab}
                onChange={setSelectedTab}
              />
              <Spacer size={24} />
            </Column>

            <Column>
              <Column style={{ paddingHorizontal: 16 }}>
                <Row alignItems="center">
                  <H1>{t('finishedTransfers')}</H1>
                  <HSpacer left={6} />
                  <TouchableOpacity onPress={() => setShowPayoutStatusTooltip(true)}>
                    <Icon type="info" />
                  </TouchableOpacity>
                </Row>

                <Spacer size={16} />
                <Row>
                  <ActiveIntervalView
                    withFullCurrentMonth
                    activeInterval={payoutsInterval}
                    setActiveInterval={(value) => {
                      setPayoutsInterval(value);
                      setPayoutsPage(undefined);
                    }}
                    showFutureDates={true}
                    fullWidth
                  />
                </Row>
                <Spacer size={16} />
              </Column>

              {payouts.map((payout, index) => renderPayout(payout, index === payouts.length - 1))}

              {payouts.length === 0 && (
                <Column alignItems="center">
                  <Spacer size={48} />
                  <Body style={{ textAlign: 'center', maxWidth: 250 }}>{t('noPayouts')}</Body>
                </Column>
              )}
            </Column>
            <Spacer size={50} />
          </View>
        </InfiniteScrollView>
      )}

      {showTotalsTooltip && (
        <ModalOverlay>
          <Pressable style={styles.optionsStretchContainer} onPress={() => setShowTotalsTooltip(false)} />
          <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
            <SafeAreaView edges={['bottom']} style={{ paddingTop: 27, paddingHorizontal: 24 }}>
              <Row>
                <Column style={{ flex: 1 }}>
                  <Body style={[meroStyles.text.bold, { fontSize: 17 }]}>{t('total')}</Body>
                  <Spacer size={8} />
                  <SmallBody>{t('totalDesc')}</SmallBody>
                </Column>
                <Body style={[meroStyles.text.semibold, { fontSize: 17, marginLeft: 'auto' }]}>
                  {scaledToString(accountBalanceState.accountBalance.total.amount)}{' '}
                  {t(accountBalanceState.accountBalance.total.unit)}
                </Body>
              </Row>

              <Spacer size={16} />
              <Line />
              <Spacer size={16} />

              <Row>
                <Column style={{ flex: 1 }}>
                  <Body style={[meroStyles.text.bold, { fontSize: 17 }]}>{t('pendingTotal')}</Body>
                  <Spacer size={8} />
                  <SmallBody>{t('pendingTotalDesc')}</SmallBody>
                </Column>
                <Body style={[meroStyles.text.semibold, { fontSize: 17, marginLeft: 'auto' }]}>
                  {scaledToString(accountBalanceState.accountBalance.pendingTotal.amount)}{' '}
                  {t(accountBalanceState.accountBalance.pendingTotal.unit)}
                </Body>
              </Row>

              <Spacer size={16} />
              <Line />
              <Spacer size={16} />

              <Row>
                <Column style={{ flex: 1 }}>
                  <Body style={[meroStyles.text.bold, { fontSize: 17 }]}>{t('availableTotal')}</Body>
                  <Spacer size={8} />
                  <SmallBody>{t('availableTotalDesc')}</SmallBody>
                </Column>
                <Body style={[meroStyles.text.semibold, { fontSize: 17, marginLeft: 'auto' }]}>
                  {scaledToString(accountBalanceState.accountBalance.availableTotal.amount)}{' '}
                  {t(accountBalanceState.accountBalance.availableTotal.unit)}
                </Body>
              </Row>
              <Spacer size={50} />
            </SafeAreaView>
          </View>
        </ModalOverlay>
      )}

      {showPaymentStatusTooltip && (
        <ModalOverlay>
          <Pressable style={styles.optionsStretchContainer} onPress={() => setShowPaymentStatusTooltip(false)} />
          <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
            <SafeAreaView edges={['bottom']}>
              <H2s style={{ paddingVertical: 16, paddingHorizontal: 24 }}>{t('transactionStatuses')}</H2s>
              <Line />
              <Column style={{ paddingHorizontal: 24 }}>
                <Spacer size={16} />

                <Row>
                  <SuccessIcon />
                  <HSpacer left={16} />
                  <Column style={{ flex: 1 }}>
                    <Body style={[meroStyles.text.semibold, { fontSize: 12, color: colors.SHAMROCK }]}>
                      {t('succeeded')}
                    </Body>
                    <Spacer size={4} />
                    <SmallBody>{t('succeededDesc')}</SmallBody>
                  </Column>
                </Row>

                <Spacer size={16} />
                <Line />
                <Spacer size={16} />

                <Row>
                  <FailureIcon />
                  <HSpacer left={16} />
                  <Column style={{ flex: 1 }}>
                    <Body style={[meroStyles.text.semibold, { fontSize: 12, color: colors.RADICAL_RED }]}>
                      {t('canceled')}
                    </Body>
                    <Spacer size={4} />
                    <SmallBody>{t('canceledDesc')}</SmallBody>
                  </Column>
                </Row>

                <Spacer size={16} />
                <Line />
                <Spacer size={16} />

                <Row>
                  <NewIcon />
                  <HSpacer left={16} />
                  <Column style={{ flex: 1 }}>
                    <Body style={[meroStyles.text.semibold, { fontSize: 12, color: colors.COMET }]}>{t('new')}</Body>
                    <Spacer size={4} />
                    <SmallBody>{t('newDesc')}</SmallBody>
                  </Column>
                </Row>

                <Spacer size={16} />
                <Line />
                <Spacer size={16} />

                <Row>
                  <ReturnedIcon />
                  <HSpacer left={16} />
                  <Column style={{ flex: 1 }}>
                    <Body style={[meroStyles.text.semibold, { fontSize: 12, color: colors.COMET }]}>
                      {t('returned')}
                    </Body>
                    <Spacer size={4} />
                    <SmallBody>{t('returnedDesc')}</SmallBody>
                  </Column>
                </Row>
              </Column>
              <Spacer size={50} />
            </SafeAreaView>
          </View>
        </ModalOverlay>
      )}

      {showPayoutStatusTooltip && (
        <ModalOverlay>
          <Pressable style={styles.optionsStretchContainer} onPress={() => setShowPayoutStatusTooltip(false)} />
          <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
            <SafeAreaView edges={['bottom']}>
              <H2s style={{ paddingVertical: 16, paddingHorizontal: 24 }}>{t('payoutStatuses')}</H2s>
              <Line />
              <Column style={{ paddingHorizontal: 24 }}>
                <Spacer size={16} />

                <Row>
                  <SuccessIcon />
                  <HSpacer left={16} />
                  <Column style={{ flex: 1 }}>
                    <Body style={[meroStyles.text.semibold, { fontSize: 12, color: colors.SHAMROCK }]}>
                      {t('paid')}
                    </Body>
                    <Spacer size={4} />
                    <SmallBody>{t('paidDesc')}</SmallBody>
                  </Column>
                </Row>

                <Spacer size={16} />
                <Line />
                <Spacer size={16} />

                <Row>
                  <NewIcon />
                  <HSpacer left={16} />
                  <Column style={{ flex: 1 }}>
                    <Body style={[meroStyles.text.semibold, { fontSize: 12, color: colors.COMET }]}>
                      {t('pending')}
                    </Body>
                    <Spacer size={4} />
                    <SmallBody>{t('pendingDesc')}</SmallBody>
                  </Column>
                </Row>
              </Column>
              <Spacer size={50} />
            </SafeAreaView>
          </View>
        </ModalOverlay>
      )}
    </ModalScreenContainer>
  );
};

export default pipe(PageOnlinePaymentsBalanceScreen, withAccountBalanceContextProvider);
