import { DateString, dateStringUtils, DayTime, WorkScheduleInterval } from '@mero/api-sdk';
import { colors, Column, HSpacer, Row, Select, SmallBody, Spacer, Title } from '@mero/components';
import { capitalize } from '@mero/shared-components';
import { Hour, Minute } from '@mero/shared-sdk';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

import Button from '@mero/components/lib/components/Button';

import {
  adjustIntervals,
  dayTimeSum,
  DEFAULT_TIMEZONE,
  getIntervals,
  getOverlaps,
  intervalDiff,
} from '../../../../../../utils/time';
import { DEFAULT_DAY_TIME } from './ScheduleWeb';

const DeleteIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="Group 8855" {...props}>
    <Path fill="none" d="M0 0h32v32H0z" />
    <Path
      fill="#454f63"
      d="m8.5 22.24 6.16-6.158-6.163-6.163a1 1 0 0 1 0-1.415l.007-.007a1 1 0 0 1 1.415 0l6.163 6.163 6.162-6.163a1.005 1.005 0 0 1 1.421 1.422l-6.162 6.162 6.163 6.163a1 1 0 0 1 0 1.414l-.008.008a1 1 0 0 1-1.414 0l-6.163-6.163-6.159 6.159a1.005 1.005 0 1 1-1.421-1.421Z"
    />
  </Svg>
);

type Props = {
  date: DateString;
  initialInterval: Partial<WorkScheduleInterval>[];
  updateInterval: (intervals: WorkScheduleInterval[]) => void;
  disabled?: boolean;
};

const startIntervals = getIntervals({ hour: 0, minute: 0 } as DayTime, { hour: 23, minute: 55 } as DayTime);
const endIntervals = getIntervals({ hour: 0, minute: 15 } as DayTime, { hour: 24, minute: 0 } as DayTime);

const DayView: React.FC<Props> = ({ date, initialInterval, updateInterval, disabled = false }) => {
  const { t } = useTranslation('pros');

  const [intervals, setIntervals] = React.useState<Partial<WorkScheduleInterval>[]>(initialInterval);

  const day = React.useMemo(() => DateString.toDateTime(date, DEFAULT_TIMEZONE), [date]);

  const setTime = (index: number, type: 'from' | 'to') => (value: string) => {
    const [hour, minute] = value.split(':').map(Number) as [Hour, Minute];
    const newIntervals = intervals.map((interval, i) => {
      if (i === index) {
        return adjustIntervals({
          from: type === 'from' ? { hour, minute } : interval.from,
          to: type === 'to' ? { hour, minute } : interval.to,
          priority: type,
        });
      }

      return interval;
    });

    setIntervals(newIntervals);

    return newIntervals;
  };

  const removeInterval = (index: number) => () => {
    const newIntervals = intervals.filter((_, i) => i !== index);
    if (newIntervals.length === 0) {
      return [];
    }
    setIntervals(newIntervals);

    return newIntervals;
  };

  const addNewInterval = () => {
    setIntervals([...intervals, {}]);
  };

  React.useEffect(() => {
    setIntervals(initialInterval);
  }, [date]);

  const total = React.useMemo(() => {
    return intervals
      .filter((v) => WorkScheduleInterval.JSON.is(v))
      .reduce((acc, interval) => dayTimeSum(acc, intervalDiff(interval)), DEFAULT_DAY_TIME);
  }, [intervals]);

  const update = (intervals: Partial<WorkScheduleInterval>[]) => {
    updateInterval(intervals.filter(WorkScheduleInterval.JSON.is));
  };

  const overlaps = React.useMemo(() => getOverlaps(intervals), [intervals]);

  return (
    <>
      <Title>
        {capitalize(day.toFormat('cccc dd. MMM', { locale: 'ro' }))} (
        {t(total.minute ? 'dayTimeWithMin' : 'dayTime', {
          hour: total.hour,
          minute: total.minute,
        })}
        )
      </Title>
      {intervals.map((interval, index) => {
        return (
          <Column key={index}>
            <Row key={index} style={{ paddingTop: 8, alignItems: 'center' }}>
              <Select
                items={startIntervals}
                style={{ flex: 2 }}
                value={interval.from ? `${interval.from.hour}:${interval.from.minute}` : undefined}
                placeholder={t('intervalStart')}
                onChange={flow(setTime(index, 'from'), update)}
              />
              <HSpacer left={8} />
              <Select
                items={endIntervals}
                style={{ flex: 2 }}
                value={interval.to ? `${interval.to.hour}:${interval.to.minute}` : undefined}
                placeholder={t('intervalEnd')}
                onChange={flow(setTime(index, 'to'), update)}
                editable={!disabled}
              />
              {intervals.length > 1 && !disabled && (
                <>
                  <HSpacer left={8} />
                  <TouchableOpacity onPress={flow(removeInterval(index), update)}>
                    <DeleteIcon />
                  </TouchableOpacity>
                </>
              )}
            </Row>
            {overlaps[index] && <SmallBody style={{ color: colors.RADICAL_RED }}>{t('overlappingInterval')}</SmallBody>}
          </Column>
        );
      })}
      <Spacer size={16} />
      {!disabled && (
        <Button
          size="medium"
          text={t('addNewIntervalButton')}
          color={colors.DARK_BLUE}
          backgroundColor={colors.SKY_BLUE}
          onPress={addNewInterval}
        />
      )}
    </>
  );
};

export default DayView;
