import { Avatar, colors, Column, Label, Row, SmallBody, Title, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { nameGenerator } from '../../../../../../utils/string';

type Props = {
  firstname?: string;
  lastname?: string;
  role: string;
  isInvited: boolean;
  subtitle: string;
  profileImage?: string;
};

const Worker: React.FC<Props> = ({ firstname, lastname, role, isInvited, subtitle, profileImage }) => {
  const { t } = useTranslation('pros');

  return (
    <Row flex={1}>
      <Column>
        <Avatar size={32} firstname={firstname ?? ''} lastname={lastname ?? ''} source={profileImage} />
      </Column>
      <Column style={{ marginLeft: 12, flex: 1 }}>
        {isInvited && <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{t('invited')}</Label>}
        <Title>{nameGenerator({ firstname, lastname }, t('noName'))}</Title>
        <SmallBody style={{ fontSize: 12 }}>{role}</SmallBody>
        <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{subtitle}</SmallBody>
      </Column>
    </Row>
  );
};

export default Worker;
