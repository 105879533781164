import { PageRoleId, PageRoleOverview2 } from '@mero/api-sdk/dist/pages';
import { Body, Checkbox, colors, Column, Row, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { Animated, Dimensions } from 'react-native';
import type { Swiper as SwiperType } from 'swiper';
import { Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import PermissionsCarouselItem from './PermissionsCarouselItem';

type Props = {
  role?: PageRoleId;
  setRole: (role: PageRoleId) => void;
  roles: { label: string; value: PageRoleId }[];
  rolesPermissions: PageRoleOverview2[];
};

const PermissionCarousel: React.FC<Props> = ({ role, rolesPermissions, roles, setRole }) => {
  const { width } = Dimensions.get('window');
  const { isPhone } = useMediaQueries();

  const activateRoleTimeout = React.useRef(0);
  const [swiper, setSwiper] = React.useState<SwiperType | undefined>();

  const screenWidth = isPhone ? width : 500;

  const PADDING_SPACING = 8;
  const SPACING = 22;
  const PERMISSION_LENGTH = screenWidth - PADDING_SPACING * 2 - SPACING * 2;

  React.useEffect(() => {
    if (role) {
      const position = roles.findIndex((r) => r.value === role);
      if (position !== -1) {
        swiper?.slideTo(position, 100);
      }
    }
  }, [role, roles]);

  const roleIndex = React.useMemo(() => roles.findIndex((r) => r.value === role), [role]);

  return (
    <div style={{ width: screenWidth }}>
      <Swiper
        initialSlide={roleIndex === -1 ? 0 : roleIndex}
        slidesPerView={'auto'}
        centeredSlides={true}
        spaceBetween={PADDING_SPACING}
        modules={[Controller]}
        controller={{ control: swiper }}
        onSwiper={setSwiper}
        onSlideChange={(swiper) => {
          window.clearTimeout(activateRoleTimeout.current);
          activateRoleTimeout.current = window.setTimeout(() => {
            setRole(rolesPermissions[swiper.activeIndex].id);
          }, 1000);
        }}
      >
        {rolesPermissions.map((item) => {
          return (
            <SwiperSlide key={item.id} style={{ width: PERMISSION_LENGTH, padding: `16px 0` }}>
              <PermissionsCarouselItem isSelected={item.id === role} roleOverview={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default PermissionCarousel;
