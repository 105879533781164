import { Portal } from '@gorhom/portal';
import { DateString, dateStringUtils, WorkScheduleInterval, Worker, WorkerSchedule } from '@mero/api-sdk';
import * as MeroApi from '@mero/api-sdk';
import {
  colors,
  H3s,
  Label,
  SmallBody,
  styles as meroStyles,
  Spacer,
  Body,
  Line,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity } from 'react-native';

import FloatMenu, { Ref as FloatMenuRef } from '../../../../../../components/FloatMenu';
import Column from '@mero/components/lib/components/Layout/Column';

import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

import { AppContext } from '../../../../../../contexts/AppContext';
import { meroApi } from '../../../../../../contexts/AuthContext';
import log from '../../../../../../utils/log';
import { generateRepeatText, getDayName } from '../../../../../../utils/schedule';
import { DEFAULT_TIMEZONE, intervalToString } from '../../../../../../utils/time';
import InvitedProWarning from '../InvitedProWarning';
import DeleteScheduleModal from './DeleteScheduleModal';
import { ScheduleRecord, WorkerScheduleWithTotal } from './ScheduleMobile';

type Schedule = Extract<ScheduleRecord, { type: 'Schedule' }>;

type Interval = Extract<Schedule['schedule'], { active: true }>['intervals'][number];

type Props = {
  page: MeroApi.pages.PageDetails;
  day: DateString;
  pro: WorkerScheduleWithTotal['pro'];
  isPast: boolean;
  schedule: Schedule;
  interval: Interval;
  onEditInterval: () => void;
  onEditSchedule: () => void;
  onDeleteSchedule: () => void;
  style?: {
    button?: StyleProp<TextStyle>;
  };
};

const ScheduleButton: React.FC<Props> = ({
  day,
  page,
  pro,
  isPast,
  interval,
  onEditInterval,
  onEditSchedule,
  onDeleteSchedule,
  style = {},
}) => {
  const { t } = useTranslation('pros');

  const { isPhone } = useMediaQueries();

  const [appState] = AppContext.useContext();

  const changePastWorkingSchedule = React.useMemo(
    () => appState.type === 'Loaded' && appState.featureFlags.changePastWorkingSchedule?.enabled,
    [appState],
  );

  const showError = useShowError();
  const toast = useToast();

  const [isOpen, setIsOpen] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editSchedule, setEditSchedule] = React.useState<WorkerSchedule.Any>();

  const floatMenuRef = React.useRef<FloatMenuRef>(null);

  const date = React.useMemo(() => DateString.toDateTime(day, DEFAULT_TIMEZONE).setLocale('ro'), [day]);

  const getSchedule = React.useCallback(async () => {
    try {
      const response = await meroApi.pro.workingSchedule.findWorkingScheduleByDate({
        pageId: page._id,
        workerId: pro.worker._id,
        date: day,
      });

      setEditSchedule(response);
    } catch (error) {
      log.error('Failed to get working schedule', error);
    }
  }, [day, pro.worker._id, page._id]);

  React.useEffect(() => {
    if (isOpen) {
      getSchedule();
    }
  }, [isOpen]);

  const weekIndex = React.useMemo(() => {
    if (!editSchedule || editSchedule.type !== 'Weekly') {
      return 0;
    }

    return WorkerSchedule.Weekly.getWeekScheduleIndexForDate({
      date: day,
      schedule: editSchedule,
    });
  }, [editSchedule]);

  const closeMenu = () => {
    floatMenuRef.current?.onDismissMenu();
  };

  const onDelete = async () => {
    try {
      if (editSchedule) {
        if (editSchedule.type === 'SpecificDate' && editSchedule.daySchedule.active) {
          const remainingIntervals = editSchedule.daySchedule.intervals.filter(
            (int) =>
              int.from.hour !== interval.from.hour ||
              int.from.minute !== interval.from.minute ||
              int.to.hour !== interval.to.hour ||
              int.to.minute !== interval.to.minute,
          );
          if (remainingIntervals.length > 0) {
            await meroApi.pro.workingSchedule.editWorkingSchedule({
              pageId: page._id,
              workerIds: [pro.worker._id],
              workingSchedule: {
                ...editSchedule,
                daySchedule: {
                  ...editSchedule.daySchedule,
                  intervals: remainingIntervals as NonEmptyArray<WorkScheduleInterval>,
                },
              },
              overrideSpecificDaySchedules: true,
              startDateSetByUser: true,
              workerScheduleId: editSchedule._id,
            });
          } else {
            await meroApi.pro.workingSchedule.editWorkingSchedule({
              pageId: page._id,
              workerIds: [pro.worker._id],
              workingSchedule: {
                ...editSchedule,
                daySchedule: {
                  active: false,
                },
              },
              overrideSpecificDaySchedules: true,
              startDateSetByUser: true,
              workerScheduleId: editSchedule._id,
            });
          }
        } else if (editSchedule.type === 'Weekly') {
          const dayName = getDayName(date);
          const weekIndex = WorkerSchedule.Weekly.getWeekScheduleIndexForDate({
            schedule: editSchedule,
            date: day,
          });
          const remainingIntervals = editSchedule.weekSchedules[weekIndex][dayName].active
            ? editSchedule.weekSchedules[weekIndex][dayName].intervals.filter(
                (int) =>
                  int.from.hour !== interval.from.hour ||
                  int.from.minute !== interval.from.minute ||
                  int.to.hour !== interval.to.hour ||
                  int.to.minute !== interval.to.minute,
              )
            : [];

          if (remainingIntervals.length > 0) {
            await meroApi.pro.workingSchedule.editWorkingSchedule({
              pageId: page._id,
              workerIds: [pro.worker._id],
              workingSchedule: {
                type: 'SpecificDate',
                date: day,
                daySchedule: {
                  active: true,
                  intervals: remainingIntervals as NonEmptyArray<WorkScheduleInterval>,
                },
              },
              overrideSpecificDaySchedules: true,
              startDateSetByUser: true,
              workerScheduleId: undefined,
            });
          } else {
            await meroApi.pro.workingSchedule.editWorkingSchedule({
              pageId: page._id,
              workerIds: [pro.worker._id],
              workingSchedule: {
                type: 'SpecificDate',
                date: day,
                daySchedule: {
                  active: false,
                },
              },
              overrideSpecificDaySchedules: true,
              startDateSetByUser: true,
              workerScheduleId: undefined,
            });
          }
        }

        onDeleteSchedule();
        setShowDeleteModal(false);
        toast.show({
          type: 'success',
          text: t('scheduleDeleted'),
        });
      }
    } catch (error) {
      showError(error);
    }
  };

  const isInvited = React.useMemo(() => Worker.isInvited(pro.worker, page), [pro]);

  return (
    <>
      <FloatMenu
        mode={isPhone ? 'bottom' : 'default'}
        ref={floatMenuRef}
        button={
          <Column style={[styles.schedule, isPast && styles.schedulePast, isOpen && styles.scheduleOpen]}>
            <SmallBody
              style={[
                styles.scheduleText,
                isPast && styles.scheduleTextPast,
                isOpen && styles.scheduleTextOpen,
                isPhone && styles.scheduleTextMobile,
                style?.button,
              ]}
            >
              {intervalToString(interval)}
            </SmallBody>
          </Column>
        }
        onShow={() => setIsOpen(true)}
        onDismiss={() => setIsOpen(false)}
      >
        <Column style={isPhone ? { width: '100%' } : { width: 300 }}>
          <Column style={{ backgroundColor: colors.DARK_BLUE, paddingHorizontal: 16, paddingVertical: 12 }}>
            <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>{t('workingSchedule')}</Label>
          </Column>
          <Column style={{ paddingHorizontal: 16, paddingTop: 12, paddingBottom: 16 }}>
            <H3s>{intervalToString(interval)}</H3s>
            <Spacer size={2} />
            <Body>{date.toFormat('cccc dd MMM yyyy')}</Body>
            {editSchedule && editSchedule.type === 'Weekly' ? (
              <>
                <Spacer size={6} />
                <SmallBody>{generateRepeatText(editSchedule, weekIndex, t)}</SmallBody>
              </>
            ) : null}
          </Column>
          <Line />
          {isInvited ? (
            <>
              <Column style={{ padding: 16 }}>
                <InvitedProWarning />
              </Column>
            </>
          ) : !isPast || changePastWorkingSchedule ? (
            <>
              <TouchableOpacity style={{ padding: 16 }} onPress={flow(onEditInterval, closeMenu)}>
                <Title>{t('changeDay')}</Title>
              </TouchableOpacity>
              {editSchedule && editSchedule.type === 'Weekly' ? (
                <>
                  <Line />
                  <TouchableOpacity style={{ padding: 16 }} onPress={flow(onEditSchedule, closeMenu)}>
                    <Title>{t('changeSchedule')}</Title>
                  </TouchableOpacity>
                </>
              ) : null}
              <Line />
              <TouchableOpacity style={{ padding: 16 }} onPress={flow(() => setShowDeleteModal(true), closeMenu)}>
                <Title style={{ color: colors.RADICAL_RED }}>{t('deleteScheduleText')}</Title>
              </TouchableOpacity>
            </>
          ) : null}
        </Column>
      </FloatMenu>
      {showDeleteModal && editSchedule ? (
        <Portal>
          <DeleteScheduleModal
            title={t('deleteScheduleIntervalTitle')}
            description={t('deleteScheduleIntervalDescription', {
              interval: intervalToString(interval),
              date: date.toFormat('dd MMM yyyy'),
            })}
            onConfirm={onDelete}
            onDismiss={() => setShowDeleteModal(false)}
          />
        </Portal>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  schedule: {
    backgroundColor: colors.SKY_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 6,
  },
  scheduleOpen: {
    backgroundColor: colors.DARK_BLUE,
  },
  schedulePast: {
    backgroundColor: '#eaecef',
  },
  scheduleText: {
    color: colors.DARK_BLUE,
    fontSize: 12,
    marginHorizontal: 8,
    marginVertical: 4,
    ...meroStyles.text.semibold,
  },
  scheduleTextMobile: {
    fontSize: 14,
  },
  scheduleTextPast: {
    color: colors.COMET,
  },
  scheduleTextOpen: {
    color: colors.WHITE,
  },
  container: {},
});

export default ScheduleButton;
