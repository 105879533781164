import { WorkerId, WorkScheduleInterval } from '@mero/api-sdk';
import { Avatar, colors, Column, Row, SmallBody } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { CalendarContext, LocalDateObject } from '../../../../contexts/CalendarContext';
import { CurrentBusiness } from '../../../../contexts/CurrentBusiness';
import { distributeCards, positionCards } from '../../../../utils/card-board';
import {
  checkIfIsAllowedInterval,
  dateObjectToDateString,
  getWorkIntervals,
  isInInterval,
  preZero,
} from '../../../../utils/time';
import CalendarEventView from '../CalendarEvent/CalendarEventView';
import FloatMenu from '../FloatMenu';
import { styles as commonStyles } from '../styles';
import { formatHour, getRelativeTopInDay, getWorkingHours } from '../utils';
import { CalendarBodyProps } from './calendarBodyProps';
import { CalendarWorkerPreview } from './calendarWorkerPreview';

const REFRESH_TIME = 60 * 1000; // 1 min

interface WithCellHeight {
  cellHeight: number;
  width?: number;
}

const HourGuideColumn = React.memo(
  function HourGuideColumn({ cellHeight, hour, ampm, width }: WithCellHeight & { hour: number; ampm: boolean }) {
    return (
      <View style={{ height: cellHeight, width }}>
        <Text style={[commonStyles.guideText, { fontFamily: 'open-sans-semibold' }]}>{formatHour(hour, ampm)}</Text>
      </View>
    );
  },
  () => true,
);

interface HourCellProps extends WithCellHeight {
  onPress: (d: DateTime) => void;
  date: DateTime;
  hour: number;
  divider?: number;
  workerId: WorkerId;
  activeHours: WorkScheduleInterval[];
  onAddBooking?: () => void;
  onAddBlockedTime?: () => void;
  onAddCheckout?: () => void;
}

const DIVIDE_HOUR = 4;

export const HoverHourCell: React.FC<{ hour: number; minute: number; id: string }> = ({ hour, minute, id }) => {
  const [show, setShow] = React.useState(false);

  const [{ floatingMenuOpenId }] = CalendarContext.useContext();

  const isFloatActive = floatingMenuOpenId === id;

  React.useEffect(() => {
    if (!floatingMenuOpenId) {
      setShow(false);
    }
  }, [floatingMenuOpenId]);

  return (
    <div
      onMouseEnter={() => !floatingMenuOpenId && setShow(true)}
      onMouseLeave={() => setShow(false)}
      style={{ width: '100%', height: '100%' }}
    >
      <div
        style={{
          borderWidth: 1,
          borderColor: 'black',
          borderRadius: 4,
          backgroundColor: '#F2F2FE',
          display: 'flex',
          alignItems: 'center',
          marginLeft: 2,
          marginRight: 2,
          height: '100%',
          fontSize: 13,
          fontFamily: 'open-sans-semibold',
          color: colors.DARK_BLUE,
          paddingLeft: 8,
          paddingRight: 8,
          visibility: show || isFloatActive ? 'visible' : 'hidden',
        }}
      >{`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`}</div>
    </div>
  );
};

function HourCell({
  activeHours,
  cellHeight,
  onPress,
  date,
  hour,
  divider = DIVIDE_HOUR,
  onAddBlockedTime,
  onAddBooking,
  onAddCheckout,
  workerId,
}: HourCellProps) {
  return (
    <>
      {Array(DIVIDE_HOUR)
        .fill(1)
        .map((_, index) => {
          const minute = (60 / divider) * index;
          const isAllowedInterval = checkIfIsAllowedInterval(activeHours, hour, minute);
          const cellId = `${workerId}:${preZero(hour)}:${preZero(minute)}`;
          return (
            <FloatMenu
              key={cellId}
              id={cellId}
              onAddBlockedTime={onAddBlockedTime}
              onAddCheckout={onAddCheckout}
              onAddBooking={onAddBooking}
              onPress={() => onPress(date.set({ hour, minute }))}
            >
              <View
                style={[
                  index < divider - 1 ? commonStyles.dateCellSecondary : commonStyles.dateCellPrimary,
                  { height: cellHeight / divider },
                  { backgroundColor: isAllowedInterval ? '#FFFFFF' : '#EFEFEF' },
                ]}
              >
                <HoverHourCell id={cellId} hour={hour} minute={minute} />
              </View>
            </FloatMenu>
          );
        })}
    </>
  );
}

const CalendarBodySplitView: React.FC<CalendarBodyProps> = ({
  selectedDate,
  currentDate,
  selectedWorkers,
  selectedTimezone,
  hourHeight,
  onPressCell,
  normalizedEvents,
  onPressEvent,
  ampm,
  showTime,
  hideNowIndicator,
  activeHours,
  dayHours,
  onAddBlockedTime,
  onAddBooking,
  onAddCheckout,
}) => {
  const dateTime = React.useMemo(
    () => DateTime.fromObject(selectedDate, { zone: selectedTimezone }),
    [selectedDate, selectedTimezone],
  );
  /**
   * selectedDate string in YYYY-MM-DD format
   */
  const selectedDateStr = React.useMemo(() => LocalDateObject.format(selectedDate), [selectedDate]);
  const isSelectedToday = React.useMemo(
    () => LocalDateObject.equals(currentDate, selectedDate),
    [currentDate, selectedDate],
  );
  const scrollView = React.useRef<HTMLDivElement>();
  const calendarBodyRef = React.useRef<HTMLTableSectionElement>(null);
  const [now, setNow] = React.useState(DateTime.now().setZone(selectedTimezone));
  const [boardWidth, setBoardWidth] = React.useState(0);
  const boardColumns = Math.max(Math.floor(boardWidth / Math.max(selectedWorkers.length, 1) / 230), 1);

  // Looks like render is not trigered when a reference is set, so using this flag to trigger initial scroll effect
  const [scrollRefTrigger, setScrollRefTrigger] = React.useState(0);
  const setScrollViewRef = React.useCallback(
    (ref: HTMLDivElement): void => {
      scrollView.current = ref;
      setScrollRefTrigger((s) => s + 1); // this will trigger a re-render required to do the scroll effect (which is not triggered by setting scrollView reference)
    },
    [scrollView],
  );

  const [startHour, endHour] = React.useMemo(() => [dayHours.at(0) ?? 0, (dayHours.at(-1) ?? 23) + 1], [dayHours]);

  const fullCalendarSize = hourHeight * 24;
  const calendarSize = hourHeight * (endHour - startHour);

  React.useEffect(() => {
    const current = scrollView.current;
    if (current) {
      // We add delay here to work correct on React Native
      // see: https://stackoverflow.com/questions/33208477/react-native-android-scrollview-scrollto-not-working
      setTimeout(() => {
        if (isSelectedToday) {
          current.scrollTo({
            top: Math.floor((fullCalendarSize * Math.max(0, now.get('hour') - startHour - 1)) / 24),
          });
        } else {
          const firstCalendarActiveHours =
            selectedWorkers.length > 0 ? activeHours[selectedWorkers[0].calendar._id] : undefined;
          const dayHours = firstCalendarActiveHours
            ? getWorkIntervals(firstCalendarActiveHours, dateObjectToDateString(selectedDate))
            : undefined;
          const scrollHour = Math.max(0, (dayHours?.[0] ? dayHours[0].from.hour : now.get('hour')) - 1);

          current.scrollTo({
            top: Math.floor((fullCalendarSize * (scrollHour - startHour)) / 24),
          });
        }
      }, 10);
    }
  }, [scrollView.current, scrollRefTrigger, startHour]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.fromJSDate(new Date(), { zone: selectedTimezone }));
    }, REFRESH_TIME);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const _onPressCell = React.useCallback(
    (worker: CalendarWorkerPreview) => (date: DateTime) => {
      onPressCell && onPressCell(date.toJSDate(), worker);
    },
    [onPressCell],
  );

  React.useEffect(() => {
    if (calendarBodyRef.current) {
      // subtract hours column
      setBoardWidth(calendarBodyRef.current.clientWidth - 51);
    }
  }, []);

  const currentTimePosition = React.useMemo(() => {
    const position = fullCalendarSize * (getRelativeTopInDay(now.minus({ hour: startHour })) / 100) - 10;
    if (position > calendarSize) {
      return -1;
    }

    return position;
  }, [now, startHour, fullCalendarSize, calendarSize]);

  return selectedWorkers.length > 0 ? (
    <div className="calendar-wrapper">
      <div className="calendar" ref={setScrollViewRef}>
        <div className="calendar-pro">
          <div style={{ minWidth: 50, zIndex: 201 }} />
          {selectedWorkers.map((worker, index) => {
            const selectedCalendar = worker.calendar._id;

            return (
              <div
                key={selectedCalendar}
                style={{
                  minWidth: 131,
                  flex: 1,
                  backgroundColor: '#FFF',
                  ...(index < selectedWorkers.length - 1 ? { borderRight: '1px solid #A7A7A7' } : {}),
                }}
              >
                <Row style={{ paddingHorizontal: 8, paddingBottom: 8 }}>
                  <Column>
                    <Avatar
                      size={32}
                      firstname={worker.user.firstname ?? ''}
                      lastname={worker.user.lastname ?? ''}
                      source={worker.profilePhoto?.medium}
                    />
                  </Column>
                  <Column style={{ paddingLeft: 8, width: '100%', flex: 1 }}>
                    <SmallBody
                      style={{ fontWeight: '600', textAlign: 'left', fontSize: 13 }}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {worker.user.firstname || worker.user.lastname}
                    </SmallBody>
                    <SmallBody
                      style={{ fontSize: 11, color: colors.COMET, flex: 1 }}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {getWorkingHours(activeHours[worker.calendar._id], dateObjectToDateString(selectedDate))}
                    </SmallBody>
                  </Column>
                </Row>
              </div>
            );
          })}
        </div>

        <div className="calendar-hours">
          {dayHours.map((hour) => (
            <HourGuideColumn key={hour} cellHeight={hourHeight} hour={hour} ampm={ampm} width={50} />
          ))}
        </div>

        <div ref={calendarBodyRef} className="calendar-appointments">
          {dayHours.map((hour, index) => (
            <div style={{ display: 'flex', flexWrap: 'nowrap' }} key={hour}>
              {selectedWorkers.map((worker, calendarIndex) => {
                const selectedCalendar = worker.calendar._id;
                const dayEvents = normalizedEvents[selectedCalendar][selectedDateStr] || [];
                const calendarActiveHours = activeHours[selectedCalendar];
                const dayActiveHours = getWorkIntervals(calendarActiveHours, dateObjectToDateString(selectedDate));

                return (
                  <div
                    key={selectedCalendar}
                    style={{
                      flex: 1,
                      position: 'relative',
                      minWidth: 131,
                      ...(calendarIndex < selectedWorkers.length - 1 ? { borderRight: '1px solid #A7A7A7' } : {}),
                    }}
                  >
                    <HourCell
                      activeHours={dayActiveHours}
                      cellHeight={hourHeight}
                      date={dateTime}
                      onPress={_onPressCell(worker)}
                      hour={hour}
                      onAddBooking={onAddBooking}
                      onAddBlockedTime={onAddBlockedTime}
                      onAddCheckout={onAddCheckout}
                      workerId={worker._id}
                    />
                    {index === 0 ? (
                      <>
                        {positionCards(
                          distributeCards(
                            dayEvents.map(
                              (event) =>
                                ({
                                  start: event.start.toMillis(),
                                  end: event.end.toMillis(),
                                  event: event,
                                } as const),
                            ),
                            {
                              minCardSize: 15 * 60 * 1000, // 15 minutes
                            },
                          ),
                          {
                            columns: boardColumns,
                            start: dateTime.set({ hour: startHour, minute: 0 }).toMillis(),
                            end: dateTime.set({ hour: endHour, minute: 0 }).toMillis(),
                            minCardSize: 15 * 60 * 1000, // 15 minutes
                            shrinkCards: true,
                          },
                        ).map((row, index) => {
                          const event = row.card.event;

                          return (
                            <TouchableOpacity
                              key={`custom-event-${event.start}${event.title}${event.extra.id}`}
                              delayPressIn={20}
                              onPress={() => {
                                if (onPressEvent) {
                                  onPressEvent(event);
                                }
                              }}
                              style={{
                                position: 'absolute',
                                left: `${row.x * 100}%`,
                                top: Math.floor(calendarSize * row.y),
                                width: `${row.width * 95}%`,
                                height: Math.floor(calendarSize * row.height),
                                overflow: 'hidden',
                                zIndex: 100 + index,
                                paddingBottom: 2,
                              }}
                            >
                              <CalendarEventView event={event} showTime={showTime} now={now} />
                            </TouchableOpacity>
                          );
                        })}

                        {isSelectedToday && !hideNowIndicator && currentTimePosition >= 0 && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              position: 'absolute',
                              zIndex: 199,
                              width: '100%',
                              alignItems: 'center',
                              top: currentTimePosition,
                            }}
                          >
                            <View style={{ borderRadius: 2, width: 4, height: 4, backgroundColor: '#080DE0' }} />
                            <View style={{ flex: 1, backgroundColor: '#080DE0', height: 1 }} />
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ))}
          <div
            style={{
              position: 'absolute',
              top: currentTimePosition,
            }}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default pipe(CalendarBodySplitView, React.memo, CurrentBusiness);
